import * as io from "io-ts";
import { Reducer } from "redux";
import { RootState } from ".";
import { Selector } from "reselect";
import { createAction } from "../utils/createAction";

export type PaymentMethod = "card" | "ach" | "";

export const AccountTypeCodec = io.union([
    io.literal("personalChecking"),
    io.literal("personalSavings"),
    io.literal("businessChecking"),
    io.literal("businessSavings"),
    io.literal("noChoiceMade"),
]);
export type AccountType = io.TypeOf<typeof AccountTypeCodec>;
export interface SupplierOnboardingRecord {
    id: string;
    isActive: boolean;
    bankAccount: {
        nameOnAccount: string;
        nameOfBank: string;
        accountType: AccountType;
        accountNumber: string;
        routingNumber: string;
    };
    companyUserInfo: {
        companyInfo: {
            companyName: string;
            tin: string;
            tinType: TaxpayerIdentificationNumberType;
            country: string;
            streetAddress: string;
            city: string;
            stateOrProvince: string;
            zip: string;
        };
        contactPerson: {
            firstName: string;
            lastName: string;
            emailAddress: string;
            phoneNumber: string;
        };
    };
    paymentMethod: PaymentMethod;
}

export type FormPosition = "COMPANY_USER_INFO" | "PAYMENT_METHOD" | "PAYMENT_INFO" | "COMPLETE";

export type TaxpayerIdentificationNumberType = "ein" | "ssn";
export interface SupplierOnboardingState {
    entities: SupplierOnboardingRecord;
    formPosition: FormPosition;
    status: "UNINITIALIZED" | "INITIALIZED" | "POSTING" | "POSTED_TO_API";
}

export const initialSupplierOnboardingState: SupplierOnboardingState = {
    formPosition: "COMPANY_USER_INFO",
    entities: {
        id: "",
        isActive: false,

        bankAccount: {
            nameOnAccount: "",
            nameOfBank: "",
            accountType: "noChoiceMade",
            accountNumber: "",
            routingNumber: "",
        },

        companyUserInfo: {
            companyInfo: {
                companyName: "",
                tin: "",
                tinType: "ein",
                country: "United States",
                streetAddress: "",
                city: "",
                stateOrProvince: "",
                zip: "",
            },
            contactPerson: {
                firstName: "",
                lastName: "",
                emailAddress: "",
                phoneNumber: "",
            },
        },
        paymentMethod: "card",
    },
    status: "UNINITIALIZED",
};

export const enterCompanyUserInfo = createAction(
    "SUPPLIER_ONBOARDING:ENTER_COMPANY_INFO",
    (address: SupplierOnboardingState["entities"]["companyUserInfo"]) => address,
);

export const enterPaymentMethod = createAction(
    "SUPPLIER_ONBOARDING:ENTER_PAYMENT_METHOD",
    (paymentMethod: SupplierOnboardingState["entities"]["paymentMethod"]) => paymentMethod,
);

export const enterPaymentInfo = createAction(
    "SUPPLIER_ONBOARDING:ENTER_PAYMENT_INFO",
    (bankAccount: SupplierOnboardingState["entities"]["bankAccount"]) => bankAccount,
);

export const jumpToCompanyUserInfo = createAction("SUPPLIER_ONBOARDING:FORM_POSITION_COMPANY_USER_INFO");

export const jumpToPaymentMethod = createAction("SUPPLIER_ONBOARDING:FORM_POSITION_PAYMENT_METHOD");

export const jumpToPaymentInfo = createAction("SUPPLIER_ONBOARDING:FORM_POSITION_PAYMENT_INFO");

export const supplierOnboardingReducer: Reducer<SupplierOnboardingState> = (
    state = initialSupplierOnboardingState,
    action,
) => {
    if (enterCompanyUserInfo.match(action)) {
        return {
            ...state,
            entities: {
                ...state.entities, // TODO: ask if this double spread is best/necessary
                companyUserInfo: action.payload,
            },
            formPosition: "PAYMENT_METHOD",
        };
    }
    if (enterPaymentMethod.match(action)) {
        return {
            ...state,
            entities: {
                ...state.entities,
                paymentMethod: action.payload,
            },
            formPosition: action.payload === "ach" ? "PAYMENT_INFO" : "COMPLETE",
        };
    }
    if (enterPaymentInfo.match(action)) {
        return {
            ...state,
            entities: {
                ...state.entities,
                bankAccount: action.payload,
            },
            formPosition: "COMPLETE",
            status: "INITIALIZED",
        };
    }
    if (jumpToCompanyUserInfo.match(action)) {
        return {
            ...state,
            formPosition: "COMPANY_USER_INFO",
        };
    }
    if (jumpToPaymentMethod.match(action)) {
        return {
            ...state,
            formPosition: "PAYMENT_METHOD",
        };
    }
    if (jumpToPaymentInfo.match(action)) {
        return {
            ...state,
            formPosition: "PAYMENT_INFO",
        };
    }
    return state;
};

export const selectSupplierOnboardingStatus: Selector<RootState, SupplierOnboardingState["status"]> = (
    state,
) => {
    return state.supplierOnboarding.status;
};

export const selectFormPosition: Selector<RootState, SupplierOnboardingState["formPosition"]> = (state) => {
    return state.supplierOnboarding.formPosition;
};

export const selectCurrentFormData: Selector<RootState, SupplierOnboardingState["entities"]> = (state) => {
    return state.supplierOnboarding.entities;
};
