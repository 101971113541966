import { selectRecentListings } from "../store/listings";
import { useAppSelector } from "../hooks";
import { formatFromCents } from "../utils";
import { BatchStatusName, Batch } from "@corechain-technologies/types";
import { classNames } from "../utils/classNames";
import { format } from "date-fns";
import { useI18n } from "@hi18n/react";
import { book } from "../locale";
import { getStatusCssClassnames, lowercase } from "../utils/statusStyles";
import { useNavigate } from "react-router-dom";
import { showDetailPane } from "./DetailPane";
import ColumnHeader from "./ColumnHeader";

const RecentBatches = () => {
    const { t } = useI18n(book);
    const navigate = useNavigate();
    const batchListings = useAppSelector(selectRecentListings).filter((listing) => listing.type === "BATCH");
    if (batchListings.length === 0) {
        return <></>;
    }

    return (
        <>
            <h2 className="max-w-6xl mx-auto mt-8 px-4 text-lg leading-6 font-medium text-gray-800 sm:px-6 lg:px-8">
                {t("recentActivity")}
            </h2>

            {/* Activity list (smallest breakpoint only) */}
            <div className="shadow sm:hidden print:hidden">
                <ul className="mt-2 divide-y divide-gray-200 overflow-hidden shadow sm:hidden">
                    {batchListings.map((batchListing) => (
                        <li key={batchListing.id}>
                            <div
                                className="group px-4 py-4 bg-white hover:bg-gray-50 cursor-pointer"
                                onClick={() =>
                                    navigate(showDetailPane("#detail/batch/", batchListing.original.batchId))
                                }
                            >
                                <span className="flex items-center space-x-4">
                                    <span className="flex-1 flex space-x-2 truncate">
                                        <span className="flex flex-col text-gray-500 text-sm truncate">
                                            <span className="truncate group-hover:text-gray-900">
                                                {batchListing.id}
                                            </span>
                                            <span>
                                                <span className="text-gray-800 font-medium">
                                                    {formatFromCents(batchListing.amount.toString())}
                                                </span>{" "}
                                                {t("currency")}
                                            </span>
                                            <time dateTime={format(batchListing.updated_date, "MM/dd/yyyy")}>
                                                {format(batchListing.updated_date, "MM/dd/yyyy")}
                                            </time>
                                        </span>
                                    </span>
                                </span>
                            </div>
                        </li>
                    ))}
                </ul>
            </div>

            {/* Activity table (small breakpoint and up) */}
            <div className="hidden sm:block print:block">
                <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
                    <div className="flex flex-col mt-2">
                        <div className="shadow-sm print:shadow-none ring-1 print:ring-transparent ring-black ring-opacity-5">
                            <table className="min-w-full divide-y divide-gray-200 border-separate border-spacing-0">
                                <thead className="bg-gray-50 card">
                                    <tr>
                                        <ColumnHeader
                                            sortValue="originationDate"
                                            textAlign="left"
                                            className="pl-4 seven12:pl-6 lg:pl-8"
                                        />
                                        <ColumnHeader
                                            sortValue="uploadedFileName"
                                            textAlign="left"
                                            className="pl-0 xl:pl-1"
                                        />
                                        <ColumnHeader sortValue="amount" textAlign="right" className="pl-0" />
                                        <ColumnHeader
                                            sortValue="status"
                                            textAlign="center"
                                            className="hidden nine15:table-cell print:table-cell"
                                        />
                                        <th
                                            className="hidden nine15:table-cell print:table-cell sticky print:static whitespace-nowrap top-0 border-b border-gray-300 bg-gray-50 bg-opacity-75 backdrop-blur backdrop-filter py-3.5 pr-4 text-right text-sm font-semibold text-gray-900 sm:pr-6 lg:pr-8"
                                            scope="col"
                                        >
                                            <div className="group inline-flex justify-right">
                                                {t("activityTable/columnHeaders/fileId")}
                                            </div>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className="bg-white divide-y divide-gray-200">
                                    {batchListings.map((batchListing) => {
                                        let currentStatus = BatchStatusName.ERROR;
                                        if ("payments" in batchListing.original) {
                                            currentStatus =
                                                batchListing.original.events[
                                                    batchListing.original.events.length - 1
                                                ].status;
                                        }

                                        return (
                                            <tr
                                                key={batchListing.id}
                                                className="bg-white cursor-pointer group hover:bg-gray-50 card"
                                                onClick={() =>
                                                    navigate(
                                                        showDetailPane(
                                                            "#detail/batch/",
                                                            batchListing.original.batchId,
                                                        ),
                                                    )
                                                }
                                            >
                                                <td className="pl-4 sm:pl-6 lg:pl-8 py-4 pr-1 text-left whitespace-nowrap text-sm text-gray-500 border-b border-gray-200 border-spacing-0">
                                                    <time
                                                        dateTime={format(
                                                            batchListing.created_date,
                                                            "MM/dd/yyyy",
                                                        )}
                                                    >
                                                        {format(batchListing.created_date, "MM/dd/yyyy")}
                                                    </time>
                                                </td>
                                                <td className="xl:pl-1 py-4 whitespace-nowrap text-sm text-gray-800 border-b border-gray-200 border-spacing-0">
                                                    <div className="flex">
                                                        <div className="group inline-flex space-x-2 truncate text-sm">
                                                            <p className="text-gray-500 truncate group-hover:text-gray-800 max-w-xs eleven21:max-w-lg">
                                                                {batchListing.uploadedFileName}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className="pr-6 xl:pl-6 py-4 text-right whitespace-nowrap text-sm text-gray-500 border-b border-gray-200 border-spacing-0">
                                                    <span className="text-gray-800 font-medium">
                                                        {formatFromCents(batchListing.amount.toString())}
                                                    </span>{" "}
                                                    {t("currency")}
                                                </td>
                                                <td className="hidden nine15:table-cell print:table-cell text-center pr-6 xl:pl-1 py-4 whitespace-nowrap text-sm text-gray-500 border-b border-gray-200 border-spacing-0">
                                                    <span
                                                        className={classNames(
                                                            getStatusCssClassnames(currentStatus),
                                                            "inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium",
                                                        )}
                                                    >
                                                        <span className="capitalize">
                                                            {"payments" in batchListing.original
                                                                ? lowercase(currentStatus)
                                                                : ""}
                                                        </span>
                                                    </span>
                                                </td>
                                                <td className="hidden nine15:table-cell print:table-cell pl-0 pr-4 sm:pr-6 lg:pr-8 py-4 text-right whitespace-nowrap text-sm text-gray-500 border-b border-gray-200 border-spacing-0">
                                                    <div className="group inline-flex justify-right text-sm">
                                                        <p className="text-gray-500 font-mono">
                                                            {batchListing.original.batchId.slice(0, 7)}
                                                        </p>
                                                    </div>
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default RecentBatches;
