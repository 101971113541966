import { HandleError } from "../components/HandleError";
import { HttpStatusCode } from "@corechain-technologies/types";
import { ErrorCode, Vocabulary } from "../locale";

export type ErrorPageProps = {
    code: ErrorCode;
};

export const ErrorPage: React.FC<ErrorPageProps> = (props) => {
    return <HandleError code={props.code} />;
};
