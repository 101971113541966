import { To, useLocation, useNavigate } from "react-router-dom";
import BatchDetail from "./BatchDetail";
import PayerPaymentHistory from "./PayerPaymentHistory";
import PaymentDetailOverlay from "./IndividualPaymentDetailOverlay";
import ReceivablesDetail from "./ReceivablesDetail";
import VendorPaymentHistory from "./VendorPaymentHistory";
import { motion } from "framer-motion";
import { useState } from "react";

type NavigationArgs = To;

export function dismissDetailPane(): NavigationArgs {
    return {
        search: window.location.search,
        hash: "",
    };
}

export const DETAIL_BATCH = "#detail/batch/";
export const DETAIL_PAYMENT = "#detail/payment/";
export const DETAIL_VENDOR = "#detail/vendor/";
export const DETAIL_RECEIVABLE = "#detail/receivable/";
export const DETAIL_PAYER = "#detail/payer/";

export type DetailDisplayType =
    | typeof DETAIL_BATCH
    | typeof DETAIL_PAYMENT
    | typeof DETAIL_VENDOR
    | typeof DETAIL_RECEIVABLE
    | typeof DETAIL_PAYER;

export function showDetailPane(view: DetailDisplayType, hashId: string, search?: string): To {
    const preserveSearch = search ?? window.location.search;
    return {
        search: preserveSearch,
        hash: `${view}${encodeURI(hashId)}`,
    };
}

export const DetailPane: React.FC = () => {
    const [alreadyOpen, setAlreadyOpen] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const hashId = location.hash.split("/").pop();

    return (
        <>
            {location.hash.includes(`detail`) && (
                <div className="relative z-10">
                    <motion.div
                        initial={!alreadyOpen && { opacity: 0 }}
                        animate={!alreadyOpen && { opacity: 1 }}
                        className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
                    />
                    <div className="fixed inset-0 overflow-hidden">
                        <div
                            className="absolute inset-0 overflow-hidden"
                            onClick={(e) => {
                                {
                                    navigate(dismissDetailPane());
                                }
                            }}
                        >
                            <div
                                className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16"
                                onClick={(e) => {
                                    e.stopPropagation();
                                }}
                            >
                                <motion.div
                                    initial={!alreadyOpen && { x: 675 }}
                                    animate={!alreadyOpen && { x: 0 }}
                                    transition={{ ease: "linear", duration: 0.2 }}
                                    className="pointer-events-auto w-screen max-w-2xl"
                                >
                                    <div className="flex h-full flex-col overflow-y-scroll bg-white pt-6 shadow-xl">
                                        {location.hash === `${DETAIL_BATCH}${hashId}` && <BatchDetail />}
                                        {location.hash === `${DETAIL_PAYMENT}${hashId}` && (
                                            <PaymentDetailOverlay />
                                        )}
                                        {location.hash === `${DETAIL_VENDOR}${hashId}` && (
                                            <VendorPaymentHistory />
                                        )}
                                        {location.hash === `${DETAIL_RECEIVABLE}${hashId}` && (
                                            <ReceivablesDetail />
                                        )}
                                        {location.hash === `${DETAIL_PAYER}${hashId}` && (
                                            <PayerPaymentHistory />
                                        )}
                                    </div>
                                </motion.div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};
