import { RootState } from ".";
import { ReadonlyRecord } from "fp-ts/ReadonlyRecord";
import { ReadonlyDeep } from "type-fest";
import { Uuid } from "@corechain-technologies/uuid";
import { Reducer } from "redux";
import { v4 as uuidv4 } from "uuid";
import { AppThunk } from ".";
import { Selector, createSelector } from "reselect";
import { createAction } from "../utils/createAction";

export type NotificationType = "ERROR" | "INFORMATIONAL" | "SUCCESS" | "WARNING"; // I'm just guessing here...

export interface NotificationCx {
    message: string;
    id: Uuid;
    notificationType: NotificationType;
    timestamp: Date;
    timeout?: number;
}

interface NotificationsById extends ReadonlyRecord<Uuid, NotificationCx> {}

export type NotificationsState = ReadonlyDeep<{
    entities: {
        activeIds: Uuid[];
        byId: NotificationsById;
    };
}>;

export const initialNotificationsState: NotificationsState = {
    entities: {
        activeIds: [],
        byId: {},
    },
};

export const setNotification = createAction(
    "NOTIFICATIONS:SET",
    (payload: Omit<NotificationCx, "timestamp">) => ({ ...payload, timestamp: new Date() }),
);
export const removeNotification = createAction("NOTIFICATIONS:REMOVE", (id: Uuid) => id);

export const notificationsReducer: Reducer<NotificationsState> = (
    state = initialNotificationsState,
    action,
) => {
    if (setNotification.match(action)) {
        return {
            entities: {
                activeIds: [...state.entities.activeIds, action.payload.id],
                byId: { ...state.entities.byId, [action.payload.id]: action.payload },
            },
        };
    }
    if (removeNotification.match(action)) {
        return {
            entities: {
                activeIds: state.entities.activeIds.filter((id) => id !== action.payload), // payload is an id
                byId: { ...state.entities.byId }, // unchanged because we want to hold on to past notifications here
            },
        };
    }
    return state;
};

export function setNotificationWithoutTimeout(payload: Omit<NotificationCx, "id" | "timestamp">): AppThunk {
    const id = uuidv4().replaceAll("-", "") as Uuid;
    const payloadWithId = { ...payload, id };
    return (dispatch) => {
        dispatch(setNotification(payloadWithId));
    };
}

export function setNotificationWithTimeout(
    payload: Omit<NotificationCx, "id" | "timestamp">,
    timeout: number = 5600,
): AppThunk {
    const id = uuidv4().replaceAll("-", "") as Uuid;
    const payloadWithId = { ...payload, id };
    return (dispatch) => {
        dispatch(setNotification(payloadWithId));
        setTimeout(() => dispatch(removeNotification(id)), timeout);
    };
}

export const getActiveNotifications: Selector<
    Pick<RootState, "notifications">,
    NotificationCx[]
> = createSelector(
    (state: Pick<RootState, "notifications">) => state.notifications,
    (notifications) => {
        const activeIds = notifications.entities.activeIds; // get the ids of the active notifications
        const activeNotifications: NotificationCx[] = activeIds.map((id) => notifications.entities.byId[id]); // use the id to pull the full Notification object from byId{}
        return activeNotifications;
    },
);

export const getAllNotifications: Selector<
    Pick<RootState, "notifications">,
    NotificationCx[]
> = createSelector(
    (state: Pick<RootState, "notifications">) => state.notifications,
    (notifications) => {
        const allNotifications: NotificationCx[] = Object.values(notifications.entities.byId);
        return allNotifications;
    },
);
