import { useAppSelector } from "../hooks";
import { selectAllBatches } from "../store/batches";
import { getPaymentsByVendorData, TempVendorData, TempVendorFields } from "../utils/formatChartData";
import { Outlet, useNavigate } from "react-router-dom";
import { useI18n } from "@hi18n/react";
import { book } from "../locale";
import { showDetailPane } from "./DetailPane";
import { PrintHeader } from "./PrintHeader";

export const vendorStatusStyles = {
    active: "bg-green-100 text-green-800",
    pending: "bg-yellow-100 text-yellow-800",
    inactive: "bg-gray-200 text-gray-800",
};

const DisplayVendors: React.FC = () => {
    const navigate = useNavigate();
    const { t } = useI18n(book);
    const batchListings = useAppSelector(selectAllBatches);
    console.log(batchListings);
    const paymentsByVendorData: TempVendorFields[] = getPaymentsByVendorData(batchListings);

    let vendorData: TempVendorData = {};
    vendorData = paymentsByVendorData.reduce((acc, cur) => {
        if (cur.companyName === "") {
            return acc;
        }
        if (acc[cur.contactName]) {
            return acc;
        }
        acc[cur.contactName] = cur;
        return acc;
    }, {} as TempVendorData);
    const vendorList = Object.keys(vendorData).map((d: string) => vendorData[d]);

    return (
        <>
            <Outlet />
            <div>
                <div className="mt-8 print:mt-0 max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
                    <PrintHeader title={t("vendorList/title")} />
                    <div className="mt-2 print:mt-0 flex flex-col">
                        <div className="inline-block min-w-full py-2 align-middle">
                            <div className="shadow-sm ring-1 ring-black ring-opacity-5">
                                <table className="min-w-full border-separate border-spacing-0">
                                    <thead className="bg-gray-50 card">
                                        <tr>
                                            <th
                                                scope="col"
                                                className="sticky print:static top-0 border-b border-gray-300 bg-gray-50 bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:pl-6 lg:pl-8"
                                            >
                                                {t("vendorList/companyName")}
                                            </th>
                                            <th
                                                scope="col"
                                                className="sticky print:static top-0 hidden border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:table-cell print:table-cell"
                                            >
                                                {t("vendorList/contactName")}
                                            </th>
                                            <th
                                                scope="col"
                                                className="sticky print:static top-0 hidden border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter md:table-cell print:table-cell"
                                            >
                                                {t("vendorList/lastPaid")}
                                            </th>
                                            <th
                                                scope="col"
                                                className="sticky print:static top-0 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter"
                                            >
                                                {t("vendorList/status")}
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody className="bg-white">
                                        {/* <!-- Odd row --> */}
                                        {vendorList.map((vendor, i) => (
                                            <tr
                                                key={
                                                    vendor.contactName +
                                                    +vendor.contactEmail +
                                                    vendor.companyName +
                                                    i
                                                }
                                                className={`${
                                                    i % 2 === 0 ? "bg-white" : "bg-gray-50"
                                                } hover:bg-gray-100 cursor-pointer group card`}
                                                onClick={() =>
                                                    navigate(
                                                        showDetailPane("#detail/vendor/", vendor.contactName),
                                                    )
                                                }
                                            >
                                                <td className="max-w-0 py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:w-auto sm:max-w-none sm:pl-6 lg:pl-8">
                                                    <div className="font-medium text-gray-700 group-hover:text-gray-900">
                                                        {vendor.companyName}
                                                    </div>
                                                    <div className="font-normal mt-1 truncate text-gray-500">
                                                        {vendor.contactEmail}
                                                    </div>
                                                    <dl className="sm:hidden print:hidden font-normal truncate text-gray-500">
                                                        <dt className="sr-only">
                                                            {t("vendorList/contactName")}
                                                        </dt>
                                                        <dd className="mt-1">{vendor.contactName}</dd>
                                                        <dt className="sr-only">{t("vendorList/role")}</dt>
                                                        <dd className="mt-1">{vendor.contactRole}</dd>
                                                    </dl>
                                                </td>
                                                <td className="hidden sm:table-cell print:table-cell whitespace-nowrap px-3 py-4 text-sm">
                                                    <div className="font-medium text-gray-700 group-hover:text-gray-900">
                                                        {vendor.contactName}
                                                    </div>
                                                    <div className="font-normal text-gray-500 capitalize">
                                                        {vendor.contactRole}
                                                    </div>
                                                </td>
                                                <td className="max-w-0 px-3 py-4 text-sm font-medium text-gray-900 sm:w-auto sm:max-w-none">
                                                    <div className="text-gray-500 group-hover:text-gray-700">
                                                        {vendor.datePaid}
                                                    </div>
                                                    <dl className="md:hidden print:hidden first-letter:font-normal">
                                                        <dt className="sr-only">{t("vendorList/status")}</dt>
                                                        <dd className="mt-1">
                                                            <span
                                                                className={`${
                                                                    vendorStatusStyles[
                                                                        vendor.status as
                                                                            | "active"
                                                                            | "pending"
                                                                            | "inactive"
                                                                    ]
                                                                } inline-flex w-min-content text-center rounded-full bg-green-100 px-2 text-xs font-semibold text-green-800 capitalize`}
                                                            >
                                                                {vendor.status}
                                                            </span>
                                                        </dd>
                                                    </dl>
                                                </td>
                                                <td className="hidden md:table-cell print:table-cell whitespace-nowrap px-3 py-4 text-sm">
                                                    <div>
                                                        <span
                                                            className={`${
                                                                vendorStatusStyles[
                                                                    vendor.status as
                                                                        | "active"
                                                                        | "pending"
                                                                        | "inactive"
                                                                ]
                                                            } inline-flex w-min-content text-center rounded-full bg-green-100 px-2 text-xs font-semibold text-green-800 capitalize`}
                                                        >
                                                            {vendor.status}
                                                        </span>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default DisplayVendors;
