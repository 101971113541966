import { Navigate, useLocation } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../hooks";
import { AuthStatus, initAzureLogin, selectAuthStatus } from "../store/user";
import { useEffect } from "react";
import { PayerId } from "@corechain-technologies/types";

const style = { width: "15rem", color: "#D3D8DE", margin: "2.5rem auto" };

export type RequireAuthProps = {
    payerId: PayerId;
    children: JSX.Element | JSX.Element[];
};
export const RequireAuth = ({ children }: RequireAuthProps) => {
    const location = useLocation();
    const agStatus = useAppSelector(selectAuthStatus);
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (agStatus === AuthStatus.UNINITIALIZED) {
            dispatch(initAzureLogin(true));
        }
    }, [agStatus, dispatch]);

    if (agStatus === AuthStatus.LOGGED_IN) {
        return <>{children}</>;
    }

    if (agStatus === AuthStatus.NOT_LOGGED_IN || agStatus === AuthStatus.LOGGED_OUT) {
        return <Navigate to="/" state={{ from: location }} replace />;
    }

    return <h1 style={style}>Authenticating...</h1>;
};
