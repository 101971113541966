import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppSelector } from "../hooks";
import { selectAllFeatureValues } from "../store/features";

interface FeatureProps {
    flag: string;
    value?: string | number | boolean | string[];
    not?: string | number | boolean;
    redirect?: string;
    children: JSX.Element;
}

const Feature: React.FC<FeatureProps> = ({ flag, value, not, redirect, children }) => {
    const features = useAppSelector(selectAllFeatureValues);
    const navigate = useNavigate();
    let render = false;
    if (Array.isArray(value) && value.includes(features[flag])) {
        render = true;
    }
    if (value && features[flag] === value) {
        render = true;
    }
    if (not && features[flag] !== not) {
        render = true;
    }
    if (render) {
        return children;
    }
    if (redirect) {
        navigate(redirect, { relative: "path" });
    }
    return <></>;
};

export default Feature;
