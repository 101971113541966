import { useAppDispatch, useAppSelector } from "../hooks";
import { isLoadingBatches, selectSortedListings, toggleSort, toggleSortDir } from "../store/listings";
import Tabs from "./Tabs";
import { Outlet, useMatch, useNavigate } from "react-router-dom";
import { selectHideBatchesTable } from "../store/payer";
import { useEffect } from "react";
import { togglePaymentsSort, togglePaymentsSortDir } from "../store/payments";
import { isLoadingBatchListings } from "../store/batches";

export const PAGE_BREAK = 15;

const ActivityList = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const isBatchesTable = useMatch("/:payer/app/payments/payment-sets");
    const isIndividualPaymentsTable = useMatch("/:payer/app/payments/payments-individual");
    const batchListings = useAppSelector(selectSortedListings).filter(
        (listing, i) => listing.type === "BATCH",
    );
    const hideBatchesTable = useAppSelector(selectHideBatchesTable);
    const isLoading = useAppSelector(isLoadingBatchListings);

    useEffect(() => {
        // if someone's browser url is still on payment-sets but they are a hideBatchesTable user, this will redirect to individual payments table.
        if (isBatchesTable && hideBatchesTable) {
            navigate("app/payments/payments-individual");
        }
    }, []);

    // BatchesTable gets its default sort from the ["originationDate", "desc"] initialState in the store. For any other table, use route matching in the useEffect below to change sort to the default for that table.
    useEffect(() => {
        if (isBatchesTable) {
            dispatch(toggleSort("originationDate"));
            dispatch(toggleSortDir("desc"));
        }
        if (isIndividualPaymentsTable) {
            dispatch(togglePaymentsSort("statusDate"));
            dispatch(togglePaymentsSortDir("desc"));
        }
    }, [isBatchesTable, isIndividualPaymentsTable]);

    if (batchListings.length === 0 && !isLoading) {
        return <></>;
    }

    return (
        <>
            {!hideBatchesTable && <Tabs />}
            <Outlet />
        </>
    );
};

export default ActivityList;
