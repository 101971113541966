import { classNames } from "../utils/classNames";
import { useI18n } from "@hi18n/react";
import { book } from "../locale";
import { statusCssClassnames } from "../utils/statusStyles";
import { format } from "date-fns";
import { XCircleIcon } from "@heroicons/react/24/outline";
import { switchDuplicate } from "../store/listings";
import { useAppDispatch } from "../hooks";
import { motion } from "framer-motion";

interface DuplicateErrorProps {
    batches?: boolean;
}

const DummyListingForDuplicateError: React.FC<DuplicateErrorProps> = ({ batches }) => {
    const dispatch = useAppDispatch();
    const { t } = useI18n(book);
    const date = format(new Date(), "MM/dd/yyyy");

    return (
        <motion.tr className="bg-red-300 card relative" initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
            <td className="pl-4 py-4 text-left whitespace-nowrap text-sm text-gray-500 border-b border-gray-200 border-spacing-0">
                {date}
            </td>
            {batches && (
                <td className="max-w-0 w-full py-4 whitespace-nowrap text-sm text-gray-800 border-b border-gray-200 border-spacing-0">
                    <div className="flex">
                        <div className="group inline-flex space-x-2 truncate text-sm">
                            {t("dummyListing/duplicate")}
                        </div>
                    </div>
                </td>
            )}
            {batches && (
                <td className="px-6 py-4 text-right whitespace-nowrap text-sm text-gray-500 border-b border-gray-200 border-spacing-0">
                    <span className="text-gray-800 font-medium">{t("placeholder/notApplicable")}</span>
                </td>
            )}
            {!batches && (
                <>
                    <td className="max-w-0 w-full pr-6 py-4 whitespace-nowrap text-sm text-gray-800 border-b border-gray-200 border-spacing-0">
                        <div className="w-full group inline-flex space-x-2 truncate text-sm">
                            <p className="text-gray-800 truncate">{t("dummyListing/duplicate")}</p>
                        </div>
                    </td>
                    <td className="max-w-0 w-full pr-6 py-4 whitespace-nowrap text-sm text-gray-800 border-b border-gray-200 border-spacing-0">
                        <div className="flex flex-row justify-end truncate text-sm">
                            <span className="text-gray-800 font-medium">
                                {t("placeholder/notApplicable")}
                            </span>
                        </div>
                    </td>
                </>
            )}
            <td className="hidden pl-4 py-4 whitespace-nowrap text-sm text-gray-500 md:flex border-b border-gray-200 border-spacing-0">
                <span
                    className={classNames(
                        statusCssClassnames.failed,
                        "inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium capitalize",
                    )}
                >
                    <span className="capitalize">Error</span>
                </span>
                {batches && (
                    <XCircleIcon
                        width={24}
                        className="absolute top-0 right-0 text-gray-950 cursor-pointer"
                        onClick={() => dispatch(switchDuplicate(false))}
                    />
                )}
            </td>
            {!batches && (
                <>
                    {" "}
                    <td className="pl-6 pr-4  py-4 text-right whitespace-nowrap text-sm text-gray-500 border-b border-gray-200 border-spacing-0">
                        <div className="group inline-flex justify-right text-sm">
                            <p className="text-gray-500 font-mono">...</p>
                        </div>
                        <XCircleIcon
                            width={24}
                            className="absolute top-0 right-0 text-gray-950 cursor-pointer"
                            onClick={() => dispatch(switchDuplicate(false))}
                        />
                    </td>
                </>
            )}
        </motion.tr>
    );
};

export default DummyListingForDuplicateError;
