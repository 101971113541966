import * as io from "io-ts";
// import * as A from 'fp-ts/Array';
// import { pipe } from 'fp-ts/function';

// Note that eight states’ names do not have standard abbreviations: Alaska, Hawaii, Idaho, Iowa, Maine, Ohio, Texas, and Utah.

export const usaStatesAbbreviations = io.union([
    io.literal("AL"),
    io.literal("AK"),
    io.literal("AZ"),
    io.literal("AR"),
    io.literal("CA"),
    io.literal("CZ"),
    io.literal("CO"),
    io.literal("CT"),
    io.literal("DE"),
    io.literal("DC"),
    io.literal("FL"),
    io.literal("GA"),
    io.literal("GU"),
    io.literal("HI"),
    io.literal("ID"),
    io.literal("IL"),
    io.literal("IN"),
    io.literal("IA"),
    io.literal("KS"),
    io.literal("KY"),
    io.literal("LA"),
    io.literal("ME"),
    io.literal("MD"),
    io.literal("MA"),
    io.literal("MI"),
    io.literal("MN"),
    io.literal("MS"),
    io.literal("MO"),
    io.literal("MT"),
    io.literal("NE"),
    io.literal("NV"),
    io.literal("NH"),
    io.literal("NJ"),
    io.literal("NM"),
    io.literal("NY"),
    io.literal("NC"),
    io.literal("ND"),
    io.literal("OH"),
    io.literal("OK"),
    io.literal("OR"),
    io.literal("PA"),
    io.literal("PR"),
    io.literal("RI"),
    io.literal("SC"),
    io.literal("SD"),
    io.literal("TN"),
    io.literal("TX"),
    io.literal("UT"),
    io.literal("VT"),
    io.literal("VI"),
    io.literal("VA"),
    io.literal("WA"),
    io.literal("WV"),
    io.literal("WI"),
    io.literal("WY"),
]);

export const usaStates = [
    "Alabama",
    "Alaska",
    "American Samoa",
    "Arizona",
    "Arkansas",
    "California",
    "Colorado",
    "Connecticut",
    "Delaware",
    "District of Columbia",
    "Federated States of Micronesia",
    "Florida",
    "Georgia",
    "Guam",
    "Hawaii",
    "Idaho",
    "Illinois",
    "Indiana",
    "Iowa",
    "Kansas",
    "Kentucky",
    "Louisiana",
    "Maine",
    "Marshall Islands",
    "Maryland",
    "Massachusetts",
    "Michigan",
    "Minnesota",
    "Mississippi",
    "Missouri",
    "Montana",
    "Nebraska",
    "Nevada",
    "New Hampshire",
    "New Jersey",
    "New Mexico",
    "New York",
    "North Carolina",
    "North Dakota",
    "Northern Mariana Islands",
    "Ohio",
    "Oklahoma",
    "Oregon",
    "Palau",
    "Pennsylvania",
    "Puerto Rico",
    "Rhode Island",
    "South Carolina",
    "South Dakota",
    "Tennessee",
    "Texas",
    "Utah",
    "Vermont",
    "Virgin Island",
    "Virginia",
    "Washington",
    "West Virginia",
    "Wisconsin",
    "Wyoming",
] as const;

function makeArrayLiteral<P extends readonly any[]>(
    param: P,
): { -readonly [K in keyof P]: io.LiteralC<P[K]> } {
    return param.map((state) => io.literal(state)) as any;
}

export const usaStatesCodec = io.union(makeArrayLiteral(usaStates));

// const mapLiteral:<P extends readonly any[]>(param: P) => {-readonly [K in keyof P]: io.LiteralC<P[K]>} = A.map as any;

// const usaStatesCodecAlt = pipe(usaStates, mapLiteral, io.union)
