import { EyeIcon } from "@heroicons/react/24/solid";

import { motion } from "framer-motion";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../hooks";
import { selectCurrentFormData, jumpToPaymentMethod, jumpToPaymentInfo } from "../store/supplierOnboarding";
import { camelCaseToFirstUpperCaseWithSpaces, maskNumbers } from "../utils";

type MaskedFields = "tin" | "accountNumber" | "routingNumber";

const CompleteFormCard = () => {
    const navigate = useNavigate();
    const formData = useAppSelector(selectCurrentFormData);
    const companyInfo = formData.companyUserInfo.companyInfo;
    const contactPerson = formData.companyUserInfo.contactPerson;
    const bankAccount = formData.bankAccount;
    const method = formData.paymentMethod;
    const dispatch = useAppDispatch();
    const [showMasked, setShowMasked] = useState<MaskedFields[]>([]);

    const d = contactPerson.phoneNumber;
    const phoneNumber = `(${d[0]}${d[1]}${d[2]}) ${d[3]}${d[4]}${d[5]}-${d[6]}${d[7]}${d[8]}${d[9]}`;

    const n = companyInfo.tin;
    const tin =
        companyInfo.tinType === "ein"
            ? `${n[0]}${n[1]}-${n[2]}${n[3]}${n[4]}${n[5]}${n[6]}${n[7]}${n[8]}`
            : `${n[0]}${n[1]}${n[2]}-${n[3]}${n[4]}-${n[5]}${n[6]}${n[7]}${n[8]}`;

    return (
        <motion.form initial={{ opacity: 0 }} animate={{ opacity: 1 }} className="space-y-6 lg:mt-10">
            <div className="shadow sm:overflow-hidden sm:rounded-md space-y-6 bg-white py-6 px-4 sm:p-6">
                <div>
                    <h3 className="text-lg font-medium leading-6 text-gray-900">
                        Please review your information before submitting
                    </h3>
                    <p className="mt-1 max-w-2xl text-sm text-gray-500">
                        Or navigate back to the relevant form to change your data.
                    </p>
                </div>
                <div className="mt-5 md:col-span-2 md:mt-0">
                    <div className="grid grid-cols-6 gap-6">
                        <div className="col-span-6">
                            <label htmlFor="last-name" className="block text-sm font-medium text-gray-700">
                                Company name
                            </label>
                            <div className="p-2 bg-gray-50 mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm">
                                {companyInfo.companyName}
                            </div>
                        </div>

                        <div className="col-span-6 sm:col-span-3">
                            <label
                                htmlFor="ein"
                                className="block text-sm font-medium text-gray-700 divide-x-2 divide-gray-300"
                            >
                                <span className="pr-1.5">
                                    {companyInfo.tinType === "ein" ? "EIN" : "SSN"}
                                </span>
                            </label>
                            <div className="flex justify-between p-2 bg-gray-50 mt-1 w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm">
                                <div>{showMasked.includes("tin") ? tin : maskNumbers(tin, 2)}</div>
                                <EyeIcon
                                    onClick={() => setShowMasked([...showMasked, "tin"])}
                                    className="cursor-pointer"
                                    width={17}
                                />
                            </div>
                        </div>

                        <div className="col-span-6 sm:col-span-3">
                            <label htmlFor="country" className="block text-sm font-medium text-gray-700">
                                Country
                            </label>
                            <div
                                id="country"
                                className="p-2 bg-gray-50 mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                            >
                                {companyInfo.country}
                            </div>
                        </div>

                        <div className="col-span-6">
                            <label
                                htmlFor="street-address"
                                className="block text-sm font-medium text-gray-700"
                            >
                                Street address
                            </label>
                            <div className="p-2 bg-gray-50 mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm">
                                {companyInfo.streetAddress}
                            </div>
                        </div>
                        <div className="col-span-6 sm:col-span-6 lg:col-span-2">
                            <label htmlFor="city" className="block text-sm font-medium text-gray-700">
                                City
                            </label>
                            <div className="p-2 bg-gray-50 mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm">
                                {companyInfo.city}
                            </div>
                        </div>

                        <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                            <label htmlFor="region" className="block text-sm font-medium text-gray-700">
                                State / Province
                            </label>
                            <div className="p-2 bg-gray-50 mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm">
                                {companyInfo.stateOrProvince}
                            </div>
                        </div>

                        <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                            <label htmlFor="postal-code" className="block text-sm font-medium text-gray-700">
                                ZIP / Postal code
                            </label>
                            <div className="p-2 bg-gray-50 mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm">
                                {companyInfo.zip}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mt-5 md:col-span-2 md:mt-0">
                    <div className="grid grid-cols-6 gap-6">
                        <div className="col-span-6 lg:col-span-3">
                            <label htmlFor="city" className="block text-sm font-medium text-gray-700">
                                First Name
                            </label>
                            <div className="p-2 bg-gray-50 mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm">
                                {contactPerson.firstName}
                            </div>
                        </div>
                        <div className="col-span-6 lg:col-span-3">
                            <label htmlFor="city" className="block text-sm font-medium text-gray-700">
                                Last Name
                            </label>
                            <div className="p-2 bg-gray-50 mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm">
                                {contactPerson.lastName}
                            </div>
                        </div>
                        <div className="col-span-6 sm:col-span-4">
                            <label
                                htmlFor="email-address"
                                className="block text-sm font-medium text-gray-700"
                            >
                                Email address
                            </label>
                            <div className="p-2 bg-gray-50 mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm">
                                {contactPerson.emailAddress}
                            </div>
                        </div>
                        <div className="col-span-6 sm:col-span-2">
                            <label htmlFor="phone-number" className="block text-sm font-medium text-gray-700">
                                Phone Number
                            </label>
                            <div className="p-2 bg-gray-50 mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm">
                                {phoneNumber}
                            </div>
                        </div>
                    </div>
                </div>
                <fieldset className="mt-6">
                    <div className="mt-4 space-y-4">
                        <h3 className="text-md font-medium leading-6 text-gray-700">
                            Payment Method:{" "}
                            <span className="ml-0.5 text-gray-500">{method === "ach" ? "ACH" : "Card"}</span>
                        </h3>
                    </div>
                </fieldset>
                {method === "ach" && (
                    <div className="mt-5 md:col-span-2 md:mt-0">
                        <div className="grid grid-cols-6 gap-6">
                            <div className="col-span-6">
                                <label
                                    htmlFor="first-name"
                                    className="block text-sm font-medium text-gray-700"
                                >
                                    Name on account
                                </label>
                                <div className="p-2 bg-gray-50 mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm">
                                    {bankAccount.nameOnAccount}
                                </div>
                            </div>

                            <div className="col-span-6">
                                <label
                                    htmlFor="email-address"
                                    className="block text-sm font-medium text-gray-700"
                                >
                                    Name of bank
                                </label>
                                <div className="p-2 bg-gray-50 mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm">
                                    {bankAccount.nameOfBank}
                                </div>
                            </div>
                            <div className="col-span-6 lg:col-span-2">
                                <label
                                    htmlFor="accountType"
                                    className="block text-sm font-medium text-gray-700"
                                >
                                    Account Type
                                </label>
                                <div className="p-2 bg-gray-50 mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm">
                                    {camelCaseToFirstUpperCaseWithSpaces(bankAccount.accountType)}
                                </div>
                            </div>
                            <div className="col-span-6 lg:col-span-2">
                                <label htmlFor="city" className="block text-sm font-medium text-gray-700">
                                    Account number
                                </label>
                                <div className="flex justify-between p-2 bg-gray-50 mt-1 w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm">
                                    <div>
                                        {showMasked.includes("accountNumber")
                                            ? bankAccount.accountNumber
                                            : maskNumbers(bankAccount.accountNumber, 2)}
                                    </div>
                                    <EyeIcon
                                        onClick={() => setShowMasked([...showMasked, "accountNumber"])}
                                        className="cursor-pointer"
                                        width={17}
                                    />
                                </div>
                            </div>

                            <div className="col-span-6 lg:col-span-2">
                                <label htmlFor="region" className="block text-sm font-medium text-gray-700">
                                    Routing number
                                </label>
                                <div className="flex justify-between p-2 bg-gray-50 mt-1 w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm">
                                    <div>
                                        {showMasked.includes("routingNumber")
                                            ? bankAccount.routingNumber
                                            : maskNumbers(bankAccount.routingNumber, 2)}
                                    </div>
                                    <EyeIcon
                                        onClick={() => setShowMasked([...showMasked, "routingNumber"])}
                                        className="cursor-pointer"
                                        width={17}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                <div className="text-right rounded-xl">
                    <button
                        onClick={
                            method === "ach"
                                ? () => dispatch(jumpToPaymentInfo())
                                : () => dispatch(jumpToPaymentMethod())
                        }
                        type="button"
                        className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    >
                        Go Back
                    </button>
                    <button
                        type="button"
                        onClick={() => navigate("/")}
                        className="inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 ml-3 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2"
                    >
                        Submit
                    </button>
                </div>
            </div>
        </motion.form>
    );
};

export default CompleteFormCard;
