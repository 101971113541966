import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon, UserCircleIcon, ArrowRightOnRectangleIcon } from "@heroicons/react/24/outline";
import { Fragment, MouseEventHandler, ReactNode } from "react";
import { useAppDispatch, useAppSelector } from "../hooks";
import { initAzureLogout, selectDefaultCxUser } from "../store/user";
import { classNames } from "../utils/classNames";
import { selectActivePayer } from "../store";
import { Link, To, resolvePath, useMatches } from "react-router-dom";

type AvatarMenuItem = {
    label: string;
    icon: ReactNode;
    onClick?: MouseEventHandler;
    to?: To;
};
// Remove 'from' path segments from 'to' path. If 'to' is not a child of 'from', return 'to' as is.
const relative = (from: string, to: string) => {
    const fromSegments = from.split(/\/+/);
    const toSegments = to.split(/\/+/);

    let i = 0;
    while (i < fromSegments.length && i < toSegments.length && fromSegments[i] === toSegments[i]) {
        i++;
    }

    return toSegments.slice(i).join("/");
};

const ProfileDropdown = () => {
    const dispatch = useAppDispatch();
    // const features = useAppSelector(selectAllFeatureValues);
    // const showDev = features.DEPLOY_TARGET === "DEVELOPMENT" ? true : false;

    const user = useAppSelector(selectDefaultCxUser);
    const currentPayer = useAppSelector(selectActivePayer);
    const matches = useMatches();
    const root = matches.find((route) => route.id === "root");
    const thisPath = matches[matches.length - 1];
    const rel = relative(root?.pathname ?? "", thisPath?.pathname ?? "");

    const maybeLink = (to: AvatarMenuItem["to"], children: ReactNode) => {
        if (to) {
            const toPath = typeof to === "string" ? resolvePath(rel, to) : resolvePath(rel, to.pathname);
            return (
                <Link to={toPath} reloadDocument replace>
                    {children}
                </Link>
            );
        } else {
            return children;
        }
    };

    const handleLogout = () => {
        dispatch(initAzureLogout());
    };

    const avatarMenuItems: Array<AvatarMenuItem> = [
        // {
        //     label: "Edit profile",
        //     icon: <PencilSquareIcon />,
        //     onClick: () => {},
        // },
        // {
        //     label: "Settings",
        //     icon: <Cog8ToothIcon />,
        //     onClick: () => {},
        // },
        // {
        //     label: "Switch Accounts",
        //     icon: <ArrowsRightLeftIcon />,
        //     onClick: () => {},
        // },
        ...(user?.payers
            .filter((payer) => payer.id !== currentPayer?.id)
            .map((payer) => {
                return {
                    label: payer.name,
                    icon: <UserCircleIcon />,
                    to: `/${payer.name}`,
                };
            }) ?? []),
    ];
    avatarMenuItems.push({
        label: "Sign out",
        icon: <ArrowRightOnRectangleIcon />,
        onClick: () => handleLogout(),
    });

    return (
        <Menu as="div" className="ml-3 relative">
            <div>
                <Menu.Button className="max-w-xs bg-white text-gray-600 hover:text-gray-900 rounded-full flex items-center text-smlg:rounded-md lg:p-2 lg:hover:bg-gray-50">
                    <UserCircleIcon className="h-6 w-6" />
                    <span className="hidden ml-3 text-sm font-medium lg:block">
                        <span className="sr-only">Open user menu for </span>
                    </span>
                    <ChevronDownIcon
                        className="hidden flex-shrink-0 ml-1 h-5 w-5 lg:block"
                        aria-hidden="true"
                    />
                </Menu.Button>
            </div>
            <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
            >
                <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 z-10">
                    {avatarMenuItems.map((item, i) => (
                        <Menu.Item key={item.label + i}>
                            {({ active }) => (
                                <div
                                    className={classNames(
                                        active
                                            ? "bg-gray-100 text-gray-800"
                                            : "text-gray-600 hover:bg-gray-100 hover:text-gray-900",
                                        "cursor-pointer px-4 py-2 text-sm",
                                    )}
                                    {...(item.onClick
                                        ? {
                                              onClick: item.onClick,
                                          }
                                        : {})}
                                >
                                    {maybeLink(
                                        item.to,
                                        <div className="flex justify-left">
                                            <div className="mr-3 h-5 w-5">{item.icon}</div>
                                            {item.label}
                                        </div>,
                                    )}
                                </div>
                            )}
                        </Menu.Item>
                    ))}
                </Menu.Items>
            </Transition>
        </Menu>
    );
};

export default ProfileDropdown;
