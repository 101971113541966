import { classNames } from "../utils/classNames";

interface PrintHeaderProps {
    title: string;
    className?: string;
}

export const PrintHeader: React.FC<PrintHeaderProps> = ({ title, className = "" }) => {
    return (
        <div
            className={classNames(
                "hidden print:inline-flex justify-left pt-8 max-w-6xl mx-auto px-4 sm:px-6 lg:px-8",
                className,
            )}
        >
            <div className="sm:flex sm:items-center">
                <div className="sm:flex-auto">
                    <h2 className=" text-lg leading-6 font-medium text-gray-800 ">{title}</h2>
                </div>
            </div>
        </div>
    );
};
