import DisplayPayers from "../components/PayerList";
import Feature from "../components/Feature";

const PayerProfiles: React.FC = () => {
    return (
        <>
            <Feature flag="DEPLOY_TARGET" value="DEVELOPMENT">
                <DisplayPayers />
            </Feature>
        </>
    );
};

export default PayerProfiles;
