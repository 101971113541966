const PaginationControls = () => {
    return (
        <nav
            className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 print:hidden"
            aria-label="Pagination"
        >
            <div className="flex-1 flex justify-between">
                <div className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:text-gray-500">
                    Previous
                </div>
                <div className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:text-gray-500">
                    Next
                </div>
            </div>
        </nav>
    );
};

export default PaginationControls;
