import { ReadonlyDeep } from "type-fest";
import { Reducer } from "redux";
import { AppThunk, RootState } from ".";
import { Selector } from "reselect";
import { createAction } from "../utils/createAction";
import { trpc } from "../utils/trpc";
import { TRPCClientError } from "@trpc/client";
import { PayerId } from "@corechain-technologies/types";

export interface Statistics {
    totalCentsAmount: number;
    totalTransactionCount: number;
}

export type StatisticsState = ReadonlyDeep<{
    entities: Statistics;
    status: "UNINITIALIZED" | "INITIALIZED" | "FETCHING" | "FAILED";
}>;

const initialStatisticsState: StatisticsState = {
    entities: { totalCentsAmount: 0, totalTransactionCount: 0 },
    status: "UNINITIALIZED",
};

export const initFetchingStatistics = createAction("STATISTICS:FETCHING");
export const writeStatisticsToState = createAction("STATISTICS:FETCHED", (stats: Statistics) => stats);
export const statisticsFetchError = createAction("STASTICS:ERROR");

export const statisticsReducer: Reducer<StatisticsState> = (state = initialStatisticsState, action) => {
    if (writeStatisticsToState.match(action)) {
        return {
            status: "INITIALIZED",
            entities: {
                totalCentsAmount: action.payload.totalCentsAmount,
                totalTransactionCount: action.payload.totalTransactionCount,
            },
        };
    }
    if (initFetchingStatistics.match(action)) {
        return { ...state, status: "FETCHING" };
    }
    if (statisticsFetchError.match(action)) {
        return { ...state, status: "FAILED" };
    }
    return state;
};

export function initStatistics(payerId: PayerId): AppThunk {
    return (dispatch, getState) => {
        const { statistics } = getState();
        if (statistics.status !== "UNINITIALIZED") {
            return;
        }
        dispatch(fetchStatistics(payerId));
    };
}

export function fetchStatistics(payerId: PayerId): AppThunk {
    return async (dispatch) => {
        dispatch(initFetchingStatistics());

        try {
            const statisticsData = await trpc.statistics.get.query(payerId);
            dispatch(writeStatisticsToState(statisticsData));
        } catch (e) {
            if (e instanceof TRPCClientError) {
                dispatch(statisticsFetchError());
            } else {
                throw e;
            }
        }
    };
}

export const selectStatistics: Selector<RootState, Statistics> = (state) => {
    return state.statistics.entities;
};

export const selectTotalCentsAmount: Selector<RootState, Statistics["totalCentsAmount"]> = (state) => {
    return state.statistics.entities.totalCentsAmount;
};

export const selectTotalTransactionCount: Selector<RootState, Statistics["totalTransactionCount"]> = (
    state,
) => {
    return state.statistics.entities.totalTransactionCount;
};
