import { useNavigate } from "react-router-dom";
import { showDetailPane } from "./DetailPane";
import { formatFromCents } from "../utils";
import { format } from "date-fns";
import { useI18n } from "@hi18n/react";
import { book } from "../locale";
import { BatchListing } from "../store/listings";

interface BatchListingMobileProps {
    batchListing: BatchListing;
}

const BatchListingMobile: React.FC<BatchListingMobileProps> = ({ batchListing }) => {
    const navigate = useNavigate();
    const { t } = useI18n(book);

    return (
        <li>
            <div
                className="px-4 py-4 bg-white hover:bg-gray-50 cursor-pointer group"
                onClick={() => navigate(showDetailPane("#detail/batch/", batchListing.original.batchId))}
            >
                <span className="flex space-x-4">
                    <span className="flex-1 flex space-x-2">
                        <span className="group-hover:text-gray-800 flex flex-col gap-1 text-gray-500 text-sm">
                            <span className="truncate max-w-sm">{batchListing.uploadedFileName}</span>
                            <span>
                                <span className="text-gray-800 font-medium">
                                    <span className="text-gray-500">$</span>
                                    {formatFromCents(batchListing.amount.toString())}
                                </span>{" "}
                                {t("currency")}
                            </span>
                        </span>
                    </span>
                    <div className="flex-1 flex flex-col items-end gap-1 space-x-2">
                        <span className="flex flex-col items-end text-gray-500 text-sm">
                            <time
                                className="text-sm text-gray-500"
                                dateTime={format(batchListing.created_date, "MM/dd/yyyy")}
                            >
                                {format(batchListing.created_date, "MM/dd/yyyy")}
                            </time>
                            <div className="text-sm text-right text-gray-500">
                                {batchListing.id.slice(0, 7)}
                            </div>
                        </span>
                    </div>
                </span>
            </div>
        </li>
    );
};

export default BatchListingMobile;
