import { selectSortedPayments } from "../store/listings";
import { useAppSelector } from "../hooks";
import { formatFromCents } from "../utils";
import { classNames } from "../utils/classNames";
import { format } from "date-fns";
import { useI18n } from "@hi18n/react";
import { book } from "../locale";
import { getStatusCssClassnames, lowercase } from "../utils/statusStyles";
import { useNavigate } from "react-router-dom";
import { showDetailPane } from "./DetailPane";
import ColumnHeader from "./ColumnHeader";
import { calculatePaymentId } from "@corechain-technologies/types";
import Feature from "./Feature";
import { CreditCardIcon, CurrencyDollarIcon, EnvelopeIcon, WindowIcon } from "@heroicons/react/20/solid";

const RecentPayments = () => {
    const { t } = useI18n(book);
    const navigate = useNavigate();
    // const payments = useAppSelector(selectRecentListings).filter((listing) => listing.type === "BATCH");
    const payments = useAppSelector(selectSortedPayments).slice(0, 7);

    if (payments.length === 0) {
        return <></>;
    }

    return (
        <>
            <h2 className="max-w-6xl mx-auto mt-8 px-4 text-lg leading-6 font-medium text-gray-800 sm:px-6 lg:px-8">
                {t("recentActivity")}
            </h2>

            {/* Activity list (smallest breakpoint only) */}
            <div className="shadow sm:hidden print:hidden">
                <ul className="mt-3 divide-y divide-gray-200 overflow-hidden shadow sm:hidden">
                    {payments.map((payment) => (
                        <li key={payment.sourceLineNumber + payment.correlationId}>
                            <div
                                className="px-4 py-4 bg-white hover:bg-gray-50 cursor-pointer"
                                onClick={() =>
                                    navigate(showDetailPane("#detail/payment/", calculatePaymentId(payment)))
                                }
                            >
                                <span className="flex space-x-4 items-center">
                                    <span className="flex-1 flex space-x-2 truncate">
                                        <span className="flex flex-col text-gray-500 text-sm truncate">
                                            <span className="truncate">{payment.recipientName}</span>
                                            <span>
                                                <span className="text-gray-800 font-medium">
                                                    <>{formatFromCents(payment.amount)}</>
                                                </span>{" "}
                                                {t("currency")}
                                            </span>
                                            <time
                                                dateTime={format(
                                                    payment.events[payment.events.length - 1].timestamp,
                                                    "MM/dd/yyyy",
                                                )}
                                            >
                                                <>
                                                    {format(
                                                        payment.events[payment.events.length - 1].timestamp,
                                                        "MM/dd/yyyy",
                                                    )}
                                                </>
                                            </time>
                                        </span>
                                    </span>
                                    {/* TODO - Enable wcolumn for payment type when integration for multiple payment types is completed */}
                                    {payment.method === "Card" && (
                                        <CreditCardIcon
                                            className="flex-shrink-0 h-5 w-5 text-gray-400"
                                            aria-hidden="true"
                                        />
                                    )}
                                    {payment.method === "ACH" && (
                                        <CurrencyDollarIcon
                                            className="flex-shrink-0 h-5 w-5 text-gray-400"
                                            aria-hidden="true"
                                        />
                                    )}{" "}
                                    <span className="text-sm text-gray-500">{payment.method}</span>
                                </span>
                            </div>
                        </li>
                    ))}
                </ul>
            </div>

            {/* Activity table (small breakpoint and up) */}
            <div className="hidden sm:block print:block">
                <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
                    <div className="flex flex-col mt-2">
                        <div className="inline-block align-middle min-w-full pb-2">
                            <div className="shadow-sm print:shadow-none ring-1 ring-black print:ring-transparent ring-opacity-5">
                                <table className="min-w-full divide-y divide-gray-200 border-separate border-spacing-0">
                                    <thead className="bg-gray-50 card">
                                        <tr>
                                            <ColumnHeader
                                                sortValue="payee"
                                                className="pl-4 sm:pl-6 lg:pl-8"
                                            />
                                            <ColumnHeader
                                                sortValue="amount"
                                                textAlign="right"
                                                className="pl-0 xl:pl-6"
                                            />
                                            {/* TODO - Enable column for payment type when integration for multiple payment types is completed */}

                                            <th
                                                className="sticky print:static top-0 border-b border-gray-300 bg-gray-50 bg-opacity-75 backdrop-blur backdrop-filter px-6 py-3 text-left text-sm font-semibold text-gray-800 hidden md:table-cell print:table-cell"
                                                scope="col"
                                            >
                                                <div className="group inline-flex">
                                                    {t("activityTable/columnHeaders/method")}
                                                    <Feature flag="DEPLOY_TARGET" value="DEVELOPMENT">
                                                        <span className="invisible ml-2 flex-none rounded text-gray-400 group-hover:visible group-focus:visible">
                                                            <div className="h-5 w-5" aria-hidden="true">
                                                                {/* {BarsArrowDownIcon} */}
                                                            </div>
                                                        </span>
                                                    </Feature>
                                                </div>
                                            </th>
                                            <ColumnHeader
                                                sortValue="status"
                                                textAlign="center"
                                                className="pl-0 xl:pl-6 hidden md:table-cell print:table-cell"
                                            />
                                            <ColumnHeader
                                                sortValue="statusDate"
                                                textAlign="right"
                                                className="pl-0 xl:pl-6"
                                            />
                                        </tr>
                                    </thead>
                                    <tbody className="bg-white divide-y divide-gray-200">
                                        {payments.map((payment) => {
                                            const currentStatus = payment.currentStatus;

                                            return (
                                                <tr
                                                    key={
                                                        payment.events[payment.events.length - 1].timestamp +
                                                        payment.payee +
                                                        payment.sourceLineNumber
                                                    }
                                                    className="group bg-white hover:bg-gray-50 hover:cursor-pointer card"
                                                    onClick={() =>
                                                        navigate(
                                                            showDetailPane(
                                                                "#detail/payment/",
                                                                calculatePaymentId(payment),
                                                            ),
                                                        )
                                                    }
                                                >
                                                    <td className="pl-4 sm:pl-6 lg:pl-8 pr-6 py-4 whitespace-nowrap text-sm text-gray-800 border-b border-gray-200 border-spacing-0">
                                                        <div className="flex relative">
                                                            <div className="group/filename text-sm">
                                                                <p className="text-gray-500 truncate max-w-xs">
                                                                    {payment.recipientName}
                                                                </p>
                                                                <p className="hidden group-hover/filename:block absolute bg-white -top-1 -left-4 px-4 py-1 rounded-full drop-shadow-md">
                                                                    {payment.recipientName}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className="pl-0 xl:pl-6 pr-6 py-4 text-right whitespace-nowrap text-sm text-gray-500 border-b border-gray-200 border-spacing-0">
                                                        <span className="text-gray-800 font-medium">
                                                            {formatFromCents(payment.amount)}
                                                        </span>{" "}
                                                        {t("currency")}
                                                    </td>
                                                    {/* TODO - Enable wcolumn for payment type when integration for multiple payment types is completed */}
                                                    <td className="px-6 py-4 text-center hidden md:table-cell print:table-cell whitespace-nowrap text-sm text-gray-500 border-b border-gray-200 border-spacing-0">
                                                        <div className="flex gap-1">
                                                            {payment.metadata.paymentMethod === "ACH" && (
                                                                <CurrencyDollarIcon
                                                                    className="flex-shrink-0 h-5 w-5 mr-0.5 text-gray-400 group-hover:text-gray-500"
                                                                    aria-hidden="true"
                                                                />
                                                            )}
                                                            {payment.metadata.paymentMethod === "Card" && (
                                                                <CreditCardIcon
                                                                    className="flex-shrink-0 h-5 w-5 mr-0.5 text-gray-400 group-hover:text-gray-500"
                                                                    aria-hidden="true"
                                                                />
                                                            )}
                                                            {payment.metadata.paymentMethod === "eCheck" && (
                                                                <WindowIcon
                                                                    className="flex-shrink-0 h-5 w-5 mr-0.5 text-gray-400 group-hover:text-gray-500"
                                                                    aria-hidden="true"
                                                                />
                                                            )}
                                                            {payment.metadata.paymentMethod === "Check" && (
                                                                <EnvelopeIcon
                                                                    className="flex-shrink-0 h-5 w-5 mr-0.5 text-gray-400 group-hover:text-gray-500"
                                                                    aria-hidden="true"
                                                                />
                                                            )}
                                                            <span className="text-gray-500">
                                                                {String(payment?.metadata?.paymentMethod)}
                                                            </span>
                                                        </div>
                                                    </td>
                                                    <td className="hidden text-center pl-0 xl:pl-6 pr-6 py-4 whitespace-nowrap text-sm text-gray-500 md:table-cell border-b border-gray-200 border-spacing-0 print:table-cell">
                                                        <span
                                                            className={classNames(
                                                                getStatusCssClassnames(currentStatus),
                                                                "inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium capitalize",
                                                            )}
                                                        >
                                                            <span className="capitalize">
                                                                {lowercase(payment.currentStatus)}
                                                            </span>
                                                        </span>
                                                    </td>
                                                    <td className="pl-0 xl:pl-6 pr-6 py-4 text-right whitespace-nowrap text-sm text-gray-500 border-b border-gray-200 border-spacing-0">
                                                        <time
                                                            dateTime={format(
                                                                payment.events[payment.events.length - 1]
                                                                    .timestamp,
                                                                "MM/dd/yyyy",
                                                            )}
                                                        >
                                                            {format(
                                                                payment.events[payment.events.length - 1]
                                                                    .timestamp,
                                                                "MM/dd/yyyy",
                                                            )}
                                                        </time>
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default RecentPayments;
