// import partnerLogo from "../images/scanco/scancoLogoOnly.svg";
import landing from "../images/landing.jpg";
import { useNavigate } from "react-router-dom";
import { useI18n } from "@hi18n/react";
import { ErrorCode, book } from "../locale";
import { LargeLogo } from "../pages/PublicLanding";
import React from "react";
import { PrivacyPolicyLink, TermsOfServiceLink } from "./ToSAndPrivacyLinks";

export type HandleErrorProps = {
    code: ErrorCode;
    message?: string;
};

export const HandleError: React.FC<HandleErrorProps> = (props) => {
    const { t } = useI18n(book);
    const navigate = useNavigate();

    return (
        <div className="flex min-h-screen">
            <div className="flex flex-1 flex-col justify-center min-h-screen py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
                <div className="mx-auto w-full max-w-sm lg:w-96">
                    <div className="flex flex-col justify-center">
                        <LargeLogo />
                        <h2 className="text-left mt-6 text-2xl font-medium text-indigo-600">
                            {t(`app/error/${props.code}`)}
                        </h2>
                        <h1 className="text-left mt-2 text-5xl font-semibold text-gray-900">
                            {t(`app/error/${props.code}-title`)}
                        </h1>
                        <h2 className="text-left mt-12 text-md text-gray-500">
                            {t(`app/error/${props.code}-subtitle`)}
                        </h2>
                    </div>

                    <div className="mt-8">
                        <div className="mt-6">
                            <div>
                                <button
                                    type="submit"
                                    className="flex w-full justify-center rounded-md border border-transparent bg-branded-primary py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-branded-primary-active capitalize"
                                    onClick={() => navigate("/")}
                                >
                                    {t(`app/error/${props.code}-button`)}
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="py-8 px-10 justify-left align-center text-center text-gray-700 text-xs font-normal">
                        {t("app/usage")} <TermsOfServiceLink /> and <PrivacyPolicyLink />
                    </div>
                </div>
            </div>
            <div className="hidden w-0 flex-1 relative top-0 sm: left-0 md:block">
                <img className="absolute inset-0 h-screen w-screen object-cover" src={landing} alt="" />
            </div>
        </div>
    );
};
