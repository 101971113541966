import { AnyAction, Reducer } from "redux";
import { AppThunk, RootState } from "../store";
import { createSelector } from "reselect";

export interface Env {
    [key: string]: string;
}

/**
 * @param env expected to be `process.env`, but could be any key/value
 * pairs.
 * @returns All Keys and Values from `env` where the key was prefixed:
 * `VITE_CX_`. The prefix is removed from the return value.
 */
export const parseFeatureEnv = (env: Record<string, string>): Env => {
    const prefix = "VITE_CX_";
    return Object.keys(env)
        .filter((key: string) => key.startsWith(prefix))
        .reduce((acc: { [key: string]: string }, cur: string) => {
            const configKey = cur.slice(prefix.length);
            acc[configKey] = env[cur] as string;
            return acc;
        }, {});
};

export const initFeatureEnv =
    (env: Record<string, string>): AppThunk =>
    (dispatch, _getState) => {
        const envFlags = parseFeatureEnv(env);
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        dispatch(setFeatureValues(envFlags));
    };

export interface FeatureState {
    value: string | boolean;
    options?: string[];
}
export interface FeatureListState {
    [key: string]: FeatureState;
}

export const initialFeatureState: FeatureListState = {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    DEPLOY_TARGET: {
        value: "POC",
        options: ["POC", "INTERIM", "DEVELOPMENT"],
    },
    // eslint-disable-next-line @typescript-eslint/naming-convention
    SHOW_CATS: {
        value: false,
    },
    // eslint-disable-next-line @typescript-eslint/naming-convention
    SHOW_COUNTER: {
        value: false,
    },
    // eslint-disable-next-line @typescript-eslint/naming-convention
    USER_ROLE: {
        value: "BUYER",
        options: ["BUYER", "SUPPLIER"],
    },
};

export const featuresReducer: Reducer<FeatureListState, AnyAction> = (
    state = initialFeatureState,
    action,
) => {
    switch (action.type) {
        case "FEATURES:SET_FEATURE_VALUE":
            return {
                ...state,
                [action.payload.key]: {
                    ...state[action.payload.key],
                    value: action.payload.value,
                },
            };
        case "FEATURES:SET_FEATURE_VALUES":
            return Object.keys(action.payload).reduce(
                (acc: FeatureListState, cur: string) => {
                    acc[cur] = {
                        ...(state[cur] || {}),
                        value: action.payload[cur],
                    };
                    return acc;
                },
                { ...state },
            );
        default:
            return state;
    }
};

export const setFeatureValues = (config: Env) => ({
    type: "FEATURES:SET_FEATURE_VALUES",
    payload: config,
});

export const setFeatureValue = (key: string, value: string | boolean) => {
    return {
        type: "FEATURES:SET_FEATURE_VALUE",
        payload: {
            key,
            value,
        },
    };
};

export const selectFeatureValue = (label: string) => (store: Pick<RootState, "features">) =>
    store.features[label].value;

export const selectAllFeatureValues = createSelector(
    (store: Pick<RootState, "features">) => store.features,
    (features) =>
        Object.keys(features).reduce((acc: any, cur: string) => {
            acc[cur] = features[cur].value;
            return acc;
        }, {}),
);
