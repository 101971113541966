import { BuildingOfficeIcon, ShieldCheckIcon } from "@heroicons/react/24/outline";
import { useAppSelector } from "../hooks";
import { selectCxUserName } from "../store/user";
import { UserCircleIcon } from "@heroicons/react/24/outline";
import Feature from "./Feature";
import { useI18n } from "@hi18n/react";
import { book } from "../locale";
import { selectPayer } from "../store/payer";

const Header = () => {
    const userName = useAppSelector(selectCxUserName);
    const payer = useAppSelector(selectPayer);
    const { t } = useI18n(book);
    return (
        <div className="bg-white shadow lg:border-t lg:border-gray-200 print:hidden">
            <div className="px-4 sm:px-6 lg:max-w-6xl lg:mx-auto lg:px-8">
                <div className="py-6 md:flex md:items-center md:justify-between">
                    <div className="flex-1 min-w-0">
                        {/* Profile */}
                        <div className="flex items-center">
                            <div>
                                <div className="flex items-center">
                                    <UserCircleIcon className="h-16 w-16 sm:hidden text-gray-400" />
                                    <h1 className="ml-3 text-2xl font-bold leading-7 text-gray-800 sm:leading-9 sm:truncate">
                                        {t("header/greeting", { name: userName })}
                                    </h1>
                                </div>
                                <dl className="mt-6 flex flex-col sm:ml-3 sm:mt-1 sm:flex-row sm:flex-wrap">
                                    <dt className="sr-only">{t("header/sr/company")}</dt>
                                    <dd className="flex items-center text-sm text-gray-500 font-medium capitalize sm:mr-6">
                                        <BuildingOfficeIcon
                                            className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                                            aria-hidden="true"
                                        />
                                        {payer?.name}
                                    </dd>
                                    <Feature flag="DEPLOY_TARGET" value="DEVELOPMENT">
                                        <>
                                            <dt className="sr-only">{t("header/sr/accountStatus")}</dt>
                                            <dd className="mt-3 flex items-center text-sm text-gray-500 font-medium sm:mr-6 sm:mt-0 capitalize">
                                                <ShieldCheckIcon
                                                    className="flex-shrink-0 mr-1.5 h-5 w-5 text-cc-core-700"
                                                    aria-hidden="true"
                                                />
                                                {t("header/verified")}
                                            </dd>
                                        </>
                                    </Feature>
                                </dl>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Header;
