import { format } from "date-fns";
import { formatFromCents } from "../utils";
import { useAppSelector } from "../hooks";
import { selectSortedPayments, selectSortedListings } from "../store/listings";
import { classNames } from "../utils/classNames";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { getStatusCssClassnames, lowercase } from "../utils/statusStyles";
import { selectAllBatches } from "../store/batches";
import { getPaymentsByVendorData } from "../utils/formatChartData";
import { BatchStatusName, calculatePaymentId, calculateProps } from "@corechain-technologies/types";
import { DetailDisplayType, DETAIL_BATCH, DETAIL_PAYMENT, DETAIL_VENDOR, showDetailPane } from "./DetailPane";

type SearchResultItem = {
    label: string;
    name: string;
    variety: string;
    createdDate: string;
    amountOrPreview: string;
    currentStatus: BatchStatusName;
    currentDate: string;
    detail: DetailDisplayType;
    hashId: string;
};

const SearchResultsTable: React.FC = () => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const currentSearch = useLocation().search;
    const criteria = searchParams.get("search-field")?.toString() ?? "";
    const sortedListingResults: SearchResultItem[] = useAppSelector(selectSortedListings)
        .map((item) => {
            const result = {
                label: item.id,
                name: item.type === "BATCH" ? item.original.payments[0].payer : item.original.payerName,
                variety: "payment set",
                createdDate: format(item.created_date, "MM/dd/yyyy") ?? "",
                amountOrPreview: formatFromCents(item.amount.toString()),
                currentStatus: item.status,
                currentDate: format(item.updated_date, "MM/dd/yyyy") ?? "",
                detail: DETAIL_BATCH,
                hashId: encodeURI(item.original.batchId),
            };
            return result as SearchResultItem;
        })
        .filter((item) => Object.values(item).toString().indexOf(criteria) > -1);
    const sortedPaymentResults: SearchResultItem[] = useAppSelector(selectSortedPayments)
        .map((item) => {
            const itemWithId = calculateProps(item);
            const result = {
                label: itemWithId.id,
                name: item.recipientName,
                variety: "payment",
                createdDate: format(item.createdDate, "MM/dd/yyyy"),
                amountOrPreview: formatFromCents(item.amount),
                currentStatus: item.currentStatus,
                currentDate: format(item.events[item.events.length - 1].timestamp, "MM/dd/yyyy"),
                detail: DETAIL_PAYMENT,
                hashId: encodeURI(itemWithId.id),
            };
            return result as SearchResultItem;
        })
        .filter((item) => Object.values(item).toString().indexOf(criteria) > -1);
    // const vendorResults: SearchResultItem[] = getPaymentsByVendorData(useAppSelector(selectAllBatches))
    //     .map((item) => {
    //         const result = {
    //             label: item.companyId,
    //             name: item.companyName,
    //             variety: "vendor",
    //             createdDate: item.datePaid,
    //             amountOrPreview: item.contactEmail,
    //             currentStatus: item.status as BatchStatusName,
    //             currentDate: item.datePaid,
    //             detail: DETAIL_VENDOR as DetailDisplayType,
    //             hashId: encodeURI(item.companyName),
    //         };
    //         return result;
    //     })
    //     .filter((item) => Object.values(item).toString().indexOf(criteria) > -1);

    const allSearchResults: SearchResultItem[] = [
        ...sortedListingResults,
        ...sortedPaymentResults,
        // ...vendorResults,
    ];
    // obviously this is temporary pending a real pagination solution
    console.log(allSearchResults, criteria, sortedListingResults);
    if (allSearchResults.length < 1) {
        return (
            <div className="mt-8 max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
                <div className="mt-8 max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
                    <div className="sm:flex sm:items-center">
                        <div className="sm:flex-auto">
                            <h2 className="text-lg leading-6 font-medium text-gray-800">Search results</h2>
                            <p className="pt-4 text-md leading-6 font-medium text-gray-600">
                                No matches found.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className="block">
            <div>
                <div className="mt-8 max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
                    <div className="sm:flex sm:items-center">
                        <div className="sm:flex-auto">
                            <h2 className=" text-lg leading-6 font-medium text-gray-800 ">Search results</h2>
                        </div>
                    </div>
                </div>
                {allSearchResults.map((result, i) => (
                    <div
                        key={result.label + i}
                        className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8 mt-2 cursor-pointer group card"
                    >
                        <div
                            className="group-hover:shadow-md bg-white overflow-hidden shadow rounded-lg p-6"
                            onClick={() =>
                                navigate(showDetailPane(result.detail, result.hashId, currentSearch))
                            }
                        >
                            <div className="flex flex-row w-100 justify-between align-top text-lg font-medium leading-6 text-gray-600 group-hover:text-gray-800">
                                <div>{result.name}</div>
                                <div className="text-sm">{result.createdDate}</div>
                            </div>
                            <div className="pl-4 py-2 text-gray-500">
                                <div className="capitalize">{result.variety}</div>
                                <div>{result.amountOrPreview}</div>
                            </div>
                            <div className="px-2 pt-2">
                                <div className="flex flex-row gap-2 text-sm font-medium rounded-md">
                                    <div className="text-center whitespace-nowrap text-sm text-gray-500 block border-gray-200 border-spacing-0">
                                        <span
                                            className={classNames(
                                                result.currentStatus
                                                    ? getStatusCssClassnames(result.currentStatus)
                                                    : "noStats",
                                                "px-2.5 py-0.5 rounded-full text-xs font-medium capitalize",
                                            )}
                                        >
                                            <span className="capitalize">
                                                {lowercase(result.currentStatus)}
                                            </span>
                                        </span>
                                    </div>
                                    <div className="text-right whitespace-nowrap text-sm text-gray-500 border-gray-200 border-spacing-0">
                                        <time dateTime={result.createdDate}>{result.createdDate}</time>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default SearchResultsTable;
