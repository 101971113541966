import { BatchEvent } from "@corechain-technologies/types";
import { CheckCircleIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { useI18n } from "@hi18n/react";
import { format } from "date-fns";
import { book } from "../locale";
import { classNames } from "../utils/classNames";
import { LifecycleStatus, statusCssClassnames } from "../utils/statusStyles";

export type LifeycleMarker = {
    icon: typeof CheckCircleIcon;
    statusStyle: keyof typeof statusCssClassnames;
    message: string;
};

export function getStatusSequenceMarkers(events: BatchEvent[]) {
    const lifecycleMarkers: Record<LifecycleStatus, LifeycleMarker> = {
        error: {
            icon: CheckCircleIcon,
            statusStyle: "failed",
            message: "paymentDetail/lifecycleMarkers/failed",
        },
        created: {
            icon: CheckCircleIcon,
            statusStyle: "processing",
            message: "paymentDetail/lifecycleMarkers/created",
        },
        approved: {
            icon: CheckCircleIcon,
            statusStyle: "processing",
            message: "paymentDetail/lifecycleMarkers/approved",
        },
        rejected: {
            icon: XMarkIcon,
            statusStyle: "failed",
            message: "paymentDetail/lifecycleMarkers/rejected",
        },
        scheduled: {
            icon: CheckCircleIcon,
            statusStyle: "processing",
            message: "paymentDetail/lifecycleMarkers/scheduled",
        },
        delivered: {
            icon: CheckCircleIcon,
            statusStyle: "processing",
            message: "paymentDetail/lifecycleMarkers/delivered",
        },
        accepted: {
            icon: CheckCircleIcon,
            statusStyle: "processing",
            message: "paymentDetail/lifecycleMarkers/accepted",
        },
        settled: {
            icon: CheckCircleIcon,
            statusStyle: "success",
            message: "paymentDetail/lifecycleMarkers/settled",
        },
    };

    function hasKey<O>(obj: Record<LifecycleStatus, LifeycleMarker>, key: PropertyKey): key is keyof O {
        return key in obj;
    }
    const statusMarkers: LifeycleMarker[] = events.map((e) => {
        const lookup: string | number | symbol = e.status.toLowerCase();
        if (hasKey(lifecycleMarkers, lookup)) {
            return lifecycleMarkers[lookup];
        } else {
            return { icon: XMarkIcon, statusStyle: "failed", message: "There was an error." };
        }
    });
    return statusMarkers;
}

interface LifeCycleFeedProps {
    events: BatchEvent[] | [];
}

export const LifeCycleFeed: React.FC<LifeCycleFeedProps> = ({ events }) => {
    const { t } = useI18n(book);

    return (
        <>
            {/* lifecycle feed */}
            <div className="py-4 sm:py-8 sm:px-6">
                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                    <ul>
                        {getStatusSequenceMarkers(events ?? []).map((statusMarker, i) => {
                            return (
                                <li key={i + (events.length > 0 ? events[i].status : "")}>
                                    <div className="ml-4">
                                        <div className="flex space-x-5 justify-left items-center">
                                            <div>
                                                <span
                                                    className={classNames(
                                                        i === (events.length > 0 ? events.length - 1 : NaN)
                                                            ? "bg-gray-300"
                                                            : "bg-gray-200",
                                                        "h-7 w-7 rounded-full flex items-center justify-center ring-4 ring-white",
                                                    )}
                                                >
                                                    {/* <!-- Heroicon name: mini/user --> */}
                                                    <div
                                                        className={classNames(
                                                            statusCssClassnames[
                                                                statusMarker.statusStyle as keyof typeof statusCssClassnames
                                                            ],
                                                            i !==
                                                                (events.length > 0
                                                                    ? events.length - 1
                                                                    : NaN) && "bg-gray-50",
                                                            "h-6 w-6 rounded-full inline-flex justify-center items-center",
                                                        )}
                                                    >
                                                        <statusMarker.icon
                                                            className="flex-shrink-0 h-6 w-6"
                                                            aria-hidden="true"
                                                        />
                                                    </div>
                                                </span>
                                            </div>
                                            <div className="flex items-baseline">
                                                <div className="px-2.5 py-0.5 text-xs font-medium text-right text-gray-600 whitespace-nowrap">
                                                    {events.length > 0
                                                        ? format(events[i].timestamp, "MM/dd/yyyy")
                                                        : ""}
                                                    {":"}
                                                </div>
                                                <div
                                                    className={classNames(
                                                        i === (events.length > 0 ? events.length - 1 : NaN)
                                                            ? "text-gray-600"
                                                            : "text-gray-500",
                                                        "py-0.5 text-sm",
                                                    )}
                                                >
                                                    {t(statusMarker.message as any)}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {i !== (events.length > 0 ? events.length - 1 : NaN) && (
                                        <div className="ml-4 h-8 w-4 border-r-2 border-grey-200"></div>
                                    )}
                                </li>
                            );
                        })}
                    </ul>
                </dd>
            </div>
            {/* lifecycle feed */}
        </>
    );
};
