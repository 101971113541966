import * as NEA from "fp-ts/lib/NonEmptyArray";
import { pipe } from "fp-ts/lib/function";
import { z } from "zod";
import { AppThunk, RootState } from ".";
import {
    Batch,
    BatchId,
    BatchStatus,
    BatchStatusName,
    BatchStatusSequence,
    PayerId,
    calculatePaymentId,
} from "@corechain-technologies/types";
import { Reducer } from "redux";
import { Selector, createSelector } from "reselect";
import { BatchListing, Listing } from "./listings";
import { createAction } from "../utils/createAction";
import { fetchStatistics } from "./statistics";
import { setNotificationWithoutTimeout, setNotificationWithTimeout } from "./notifications";
import { book } from "../locale";
import { getTranslator } from "@hi18n/core";
import * as Sentry from "@sentry/react";
import { trpc } from "../utils/trpc";
import { PaymentViewItem } from "@corechain-technologies/database/lib/recordTypes.js";
import { Ord, fromCompare } from "fp-ts/lib/Ord";
import * as A from "fp-ts/Array";
import { getVocabularyName } from "../utils";

const locale = getVocabularyName() || "error";
const features = import.meta.env?.VITE_CX_DEPLOY_TARGET || "error";

export type BatchesSortDirection = "asc" | "desc";

export type BatchesSortAttribute = "originationDate" | "status" | "amount" | "uploadedFileName";
export type BatchesSortOption = [BatchesSortAttribute, BatchesSortDirection];
export type BatchesSortOptions = BatchesSortOption[];
const defaultBatchesSortOption: BatchesSortOption = ["originationDate", "desc"];

type BatchesById = Record<BatchId, BatchListing>;

export type BatchesState = {
    entities: {
        allIds: BatchId[];
        byId: BatchesById;
    };
    batchListings: Omit<BatchListing, "original">[];
    isFileUploadError: boolean;
    sort: BatchesSortOptions;
    status: "UNINITIALIZED" | "INITIALIZED" | "FETCHING" | "FETCHING_BATCH_LISTINGS" | "UPDATING" | "FAILED";
    showPaymentMethod: boolean;
};

export const initialBatchesState: BatchesState = {
    entities: { allIds: [], byId: {} },
    isFileUploadError: false,
    batchListings: [],
    sort: [defaultBatchesSortOption],
    status: "UNINITIALIZED",
    showPaymentMethod: false,
};

export const getBatches = createAction("BATCHES:FETCHED_ALL", (batches: Batch[]) =>
    batches.map(batchToListing),
);
export const hydrateBatchListings = createAction(
    "BATCHES:FETCHED_ALL_LISTINGS",
    (batchListings: Omit<BatchListing, "original">[]) => batchListings,
);
export const addBatch = createAction("BATCHES:FETCHED_ONE", (batch: Batch) => batchToListing(batch));
export const addBatchListing = createAction(
    "BATCHES:FETCHED_ONE_BATCH_LISTING",
    (batchListing: Omit<BatchListing, "original">) => batchListing,
);

export const initFetchingBatches = createAction("BATCHES:FETCHING_ALL");
export const initFetchingBatchListings = createAction("BATCHES:FETCHING_ALL_LISTINGS");
export const updatingBatches = createAction("BATCHES:UPDATING_ALL");
export const batchesFetchError = createAction("BATCHES:ERROR");
export const switchFileUploadError = createAction(
    "BATCHES:IS_FILE_UPLOAD_ERROR",
    (isFileUploadError: boolean) => isFileUploadError,
);
export const toggleBatchesSort = createAction("BATCHES:TOGGLE_SORT", (key: BatchesSortAttribute) => key);
export const toggleBatchesSortDir = createAction(
    "BATCHES:TOGGLE_SORT_DIR",
    (key: BatchesSortDirection) => key,
);
export const clearBatchesSort = createAction("BATCHES:CLEAR_SORT");

export const batchesAllIdsReducer: Reducer<BatchId[]> = (state = [], action) => {
    if (getBatches.match(action)) {
        return action.payload.map((i) => i.id);
    }
    if (addBatch.match(action)) {
        return [...state, action.payload.id];
    }
    return state;
};

export const batchesByIdReducer: Reducer<BatchesById> = (state = {}, action) => {
    if (getBatches.match(action)) {
        return action.payload.reduce(
            (acc, cur) => {
                acc[cur.id] = cur;
                return acc;
            },
            {} as Record<BatchId, BatchListing>,
        );
    }
    if (addBatch.match(action)) {
        return { ...state, [action.payload.id]: action.payload };
    }
    return state;
};

export const sortBatchesReducer: Reducer<BatchesSortOptions> = (
    state = [defaultBatchesSortOption],
    action,
) => {
    if (clearBatchesSort.match(action)) {
        return [defaultBatchesSortOption];
    }
    if (toggleBatchesSort.match(action)) {
        console.log("toggleSort (key) happened from the store" + action.payload);
        if (
            action.payload === "amount" ||
            action.payload === "originationDate" ||
            action.payload === "uploadedFileName" ||
            action.payload === "status"
        ) {
            return [[action.payload, "asc"]];
        } else return [[action.payload, state[0][1]]];
    }
    if (toggleBatchesSortDir.match(action)) {
        return [[state[0][0], action.payload]];
    } else {
        return state;
    }
};

export const batchesReducer: Reducer<BatchesState> = (state = initialBatchesState, action) => {
    if (getBatches.match(action)) {
        return {
            ...state,
            status: "INITIALIZED",
            entities: {
                allIds: batchesAllIdsReducer([], action),
                byId: batchesByIdReducer({}, action),
            },
            showPaymentMethod: action.payload.some((batch) =>
                batch.original.payments.some((payment) => payment.metadata.paymentMethod),
            ),
            batchListings: state.batchListings.concat(),
            isFileUploadError: false,
        };
    }
    if (hydrateBatchListings.match(action)) {
        console.log("action.payloadjkkl", action.payload);
        return {
            ...state,
            status: "INITIALIZED",
            batchListings: action.payload.concat(),
        };
    }
    if (addBatch.match(action)) {
        return {
            ...state,
            status: "INITIALIZED",
            entities: {
                allIds: batchesAllIdsReducer(state.entities.allIds, action),
                byId: batchesByIdReducer(state.entities.byId, action),
            },
        };
    }
    if (addBatchListing.match(action)) {
        return {
            ...state,
            status: "INITIALIZED",
            batchListings: state.batchListings.concat(action.payload),
        };
    }
    if (initFetchingBatches.match(action)) {
        return {
            ...state,
            status: "FETCHING",
        };
    }
    if (initFetchingBatchListings.match(action)) {
        return {
            ...state,
            status: "FETCHING_BATCH_LISTINGS",
        };
    }
    if (updatingBatches.match(action)) {
        return {
            ...state,
            status: "UPDATING",
        };
    }
    if (switchFileUploadError.match(action)) {
        return { ...state, isFileUploadError: action.payload, status: "FAILED" };
    }
    if (batchesFetchError.match(action)) {
        return {
            ...state,
            status: "FAILED",
        };
    }
    if (
        clearBatchesSort.match(action) ||
        toggleBatchesSort.match(action) ||
        toggleBatchesSortDir.match(action)
    ) {
        return {
            ...state,
            sort: sortBatchesReducer(state.sort, action),
        };
    }
    return state;
};

export const selectAllBatchIds: Selector<RootState, readonly BatchId[]> = (state) => {
    return state.batches.entities.allIds;
};

export const selectAllBatchesById: Selector<RootState, BatchesById> = (state) => {
    return state.batches.entities.byId;
};

export const selectAllBatches = createSelector([selectAllBatchIds, selectAllBatchesById], (ids, batches) => {
    return ids.map((batchId: BatchId): Listing & { type: "BATCH" } => {
        const holder = batches[batchId];
        if (holder) {
            return holder;
        } else {
            throw new Error("no batches in selector");
        }
    });
});

export const selectBatchesStatus = createSelector(
    (state: RootState) => {
        return state.batches;
    },
    (batches) => {
        return batches.status;
    },
);

export const isFileUploadError: Selector<RootState, boolean> = (state) => {
    return state.batches.isFileUploadError;
};

// should we attempt to mock payment method?
const shouldMockPaymentMethod = import.meta.env?.VITE_MOCK_PAYMENT_METHOD === "true";
// base-16 weight for generating the mock "card" payment method. 4 = 25% chance of card, 16 = 100% chance of card
const mockPaymentMethodCardWeight = parseInt(
    import.meta.env?.VITE_MOCK_PAYMENT_METHOD_CARD_WEIGHT || "4",
    10,
);

export function batchToListing(batch: Batch): BatchListing {
    return {
        /* eslint-disable @typescript-eslint/naming-convention */
        id: batch.batchId,
        type: "BATCH",
        // customerFileId: "customerFileId",
        uploadedFileName: batch.fileName ?? `pif_${batch.batchId.slice(0, 12)}_.txt`,
        original: {
            ...batch,
            payments: pipe(
                batch.payments,
                NEA.map((payment) => {
                    const { metadata } = payment;
                    if (metadata.paymentMethod || !shouldMockPaymentMethod) {
                        return payment;
                    }
                    const sigChar = calculatePaymentId(payment).slice(-1);
                    const sigVal = parseInt(sigChar, 16);
                    // const paymentMethod = sigVal <= mockPaymentMethodCardWeight ? "Check" : "ACH"; // original logic for generating mock payment method
                    function mockMethod(sigVal: number) {
                        if (sigVal < 2) {
                            return "Check";
                        } else if (sigVal < 5) {
                            return "Card";
                        } else if (sigVal > 7) {
                            return "eCheck";
                        } else {
                            return "ACH";
                        }
                    }
                    const paymentMethod = mockMethod(sigVal);
                    return { ...payment, metadata: { ...metadata, paymentMethod } };
                }),
            ),
        },
        amount: batch.amount,
        created_date: batch.events[0].timestamp,
        updated_date: batch.events[batch.events.length - 1].timestamp,
        status: batch.events[batch.events.length - 1].status,
        /* eslint-enable @typescript-eslint/naming-convention */
    };
}

export function fetchFilteredBatches(args: Parameters<typeof trpc.filteredBatches.query>[0]): AppThunk {
    return async (dispatch) => {
        dispatch(initFetchingBatchListings());
        const result = await trpc.filteredBatches.query(args);
        console.log("thisresult", result);
        const batchListingsData: Omit<BatchListing, "original">[] = result.map((batchListing) => {
            return {
                id: batchListing.batchId,
                type: "BATCH",
                uploadedFileName: batchListing.fileName ?? `pif_${batchListing.batchId.slice(0, 12)}_.txt`,
                amount: batchListing.total,
                created_date: batchListing.createdDate,
                updated_date: batchListing.lastStatusDate,
                status: batchListing.lastStatus.toLowerCase() as BatchStatus,
            };
        });

        dispatch(hydrateBatchListings(batchListingsData));
    };
}

export function fetchBatches(force: boolean = false): AppThunk {
    return async (dispatch, getState) => {
        const { services, batches, payer, user } = getState();
        if (batches.status !== "UNINITIALIZED" && !force) {
            return;
        }
        if (force) {
            dispatch(updatingBatches());
        } else {
            // dispatch(initFetchingBatches());
        }
        if (!user.cxUser || !payer) {
            throw new Error("Hopefully we never really get here?");
        }
        try {
            const result = await trpc.batches.list.query(payer.id);
            const batchesData = z.array(Batch).safeParse(result);

            if (!batchesData.success) {
                Sentry.captureMessage("Error decoding batch data");
                throw new Error("Error parsing batch data", { cause: batchesData.error });
            }

            dispatch(getBatches(batchesData.data));
        } catch (e) {
            Sentry.captureException(e);
            dispatch(batchesFetchError());
            if (features === "DEVELOPMENT") {
                dispatch(
                    setNotificationWithoutTimeout({
                        message: "Error fetching batches",
                        notificationType: "ERROR",
                    }),
                );
            }
        }
    };
}

export function makeEventsArray(paymentViewItem: PaymentViewItem): Batch["events"] {
    return [];
}

export function makeFetchBatches(payerId: PayerId, batchId: BatchId, n: number = 0): AppThunk {
    return async (dispatch: any, getState: () => RootState) => {
        const { t } = getTranslator(book, locale);

        // dispatch(initFetchingBatches());
        let result: Awaited<ReturnType<typeof trpc.batches.get.query>> | null = null;
        try {
            result = await trpc.batches.get.query({ payerId, batchId });
        } catch (err) {
            dispatch(batchesFetchError());
            Sentry.captureException(err);
            return;
        }

        if (result.err && result.val.code !== "NOT_FOUND") {
            console.log("error", result);
            dispatch(batchesFetchError());
            Sentry.captureException(result.val);
            return;
        }

        const timeout = 1000 * 1.3 ** n; // Thanks, Wolfram Alpha! 🤓
        if (result.err && result.val.code === "NOT_FOUND") {
            if (n > 20) {
                dispatch(
                    setNotificationWithTimeout({
                        message: t("app/notifications/uploadFetchError", { batchId }),
                        notificationType: "ERROR",
                    }),
                    Sentry.captureMessage(
                        `Error fetching batch details for batch ${batchId}. Timeout Exceeded.`,
                    ),
                );
                dispatch(switchFileUploadError(true));
                return;
            } else {
                setTimeout(() => {
                    dispatch(makeFetchBatches(payerId, batchId, n + 1));
                }, timeout);
            }
        } else if (result.ok) {
            const batchesData = Batch.safeParse(result.val);

            if (!batchesData.success) {
                const err = new Error("Error parsing batch data", { cause: batchesData.error });
                dispatch(
                    setNotificationWithoutTimeout({
                        message: err.message,
                        notificationType: "ERROR",
                    }),
                );
                Sentry.captureException(err);
                return;
            } else {
                dispatch(addBatch(batchesData.data));
                dispatch(fetchStatistics(payerId));
                // dispatch(
                //     setNotificationWithTimeout({
                //         message: t("app/notifications/uploadSuccess"),
                //         notificationType: "SUCCESS",
                //     }),
                // );
                // taken out so the user doesnt see two as of the implementation of makeFetchFilteredBatches
            }
        } else {
            dispatch(batchesFetchError());
            Sentry.captureMessage(`Unknown error fetching batch details for batch ${batchId}`);
        }
    };
}

export function makeFetchFilteredBatches(payerId: PayerId, batchId: BatchId, n: number = 0): AppThunk {
    return async (dispatch: any, getState: () => RootState) => {
        const { t } = getTranslator(book, locale);

        dispatch(initFetchingBatchListings());

        let result: Awaited<ReturnType<typeof trpc.filteredBatches.query>> | null = null;
        const args: Parameters<typeof trpc.filteredBatches.query>[0] = { payerId, batchId };
        try {
            result = await trpc.filteredBatches.query(args);
        } catch (err) {
            dispatch(batchesFetchError());
            Sentry.captureException(err);
            return;
        }

        // if (result.err && result.val.code !== "NOT_FOUND") {
        //     console.log("error", result);
        //     dispatch(batchesFetchError());
        //     Sentry.captureException(result.val);
        //     return;
        // }

        const timeout = 1000 * 1.3 ** n; // Thanks, Wolfram Alpha! 🤓

        if (!result || result.length === 0) {
            if (n > 20) {
                dispatch(
                    setNotificationWithTimeout({
                        message: t("app/notifications/uploadFetchError", { batchId }),
                        notificationType: "ERROR",
                    }),
                    Sentry.captureMessage(
                        `Error fetching batch details for batch ${batchId}. Timeout Exceeded.`,
                    ),
                );
                dispatch(switchFileUploadError(true));
                return;
            } else {
                setTimeout(() => {
                    dispatch(makeFetchFilteredBatches(payerId, batchId, n + 1));
                }, timeout);
            }
        } else if (result.length > 0) {
            // const batchesData = Batch.safeParse(result[0]);
            const newBatchListing: Omit<BatchListing, "original"> = {
                amount: result[0].total,
                created_date: result[0].createdDate,
                id: result[0].batchId,
                status: result[0].lastStatus,
                type: "BATCH",
                uploadedFileName: result[0].fileName ?? `pif_${result[0].batchId.slice(0, 12)}_.txt`,
                updated_date: result[0].lastStatusDate,
            };
            // if (!batchesData.success) {
            // const err = new Error("Error parsing batch data", { cause: batchesData.error });
            // dispatch(
            //     setNotificationWithoutTimeout({
            //         message: err.message,
            //         notificationType: "ERROR",
            //     }),
            // );
            // Sentry.captureException(err);
            // return;
            // } else {
            dispatch(addBatchListing(newBatchListing));
            dispatch(fetchStatistics(payerId));
            dispatch(
                setNotificationWithTimeout({
                    message: t("app/notifications/uploadSuccess"),
                    notificationType: "SUCCESS",
                }),
            );
            // }
            // } else {
            //     dispatch(batchesFetchError());
            //     Sentry.captureMessage(`Unknown error fetching batch details for batch ${batchId}`);
        }
    };
}

const makeBatchesOrderFunc = (
    sortOption: Readonly<BatchesSortOption> = defaultBatchesSortOption,
): Ord<Omit<BatchListing, "original">> => {
    const [attribute, direction] = sortOption;
    const eq = 0;
    const lt = direction === "asc" ? 1 : -1;
    const gt = direction === "desc" ? 1 : -1;

    return fromCompare((a, b) => {
        if (attribute === "amount") {
            const aAmount = Number(a.amount);
            const bAmount = Number(b.amount);
            return aAmount === bAmount ? eq : aAmount > bAmount ? lt : gt;
        }
        if (attribute === "originationDate") {
            return a.created_date.valueOf() === b.created_date.valueOf()
                ? eq
                : a.created_date.valueOf() > b.created_date.valueOf()
                  ? lt
                  : gt;
        }
        if (attribute === "status") {
            // TODO: update our status handling so status from listings and status from batches can live in the same universe
            const aStatus = getStatusOrder(BatchStatusName[a.status.toUpperCase() as BatchStatusName]);
            const bStatus = getStatusOrder(BatchStatusName[b.status.toUpperCase() as BatchStatusName]);
            if (aStatus > bStatus) {
                return lt;
            } else if (aStatus < bStatus) {
                return gt;
            } else if (aStatus === bStatus) {
                return a.created_date.valueOf() === b.created_date.valueOf()
                    ? eq
                    : a.created_date.valueOf() > b.created_date.valueOf()
                      ? -1
                      : 1; // note, the date subsort is ALWAYS descending, even when the status sort is ascending
            }
        }
        if (attribute === "uploadedFileName") {
            const aUploadedFileName = a.uploadedFileName?.toLowerCase();
            const bUploadedFileName = b.uploadedFileName?.toLowerCase();
            if (aUploadedFileName && bUploadedFileName) {
                if (aUploadedFileName > bUploadedFileName) {
                    return lt;
                } else if (aUploadedFileName < bUploadedFileName) {
                    return gt;
                } else if (aUploadedFileName === bUploadedFileName) {
                    const aAmount = Number(a.amount);
                    const bAmount = Number(b.amount);
                    return aAmount === bAmount ? eq : aAmount > bAmount ? lt : gt;
                }
            }
        }
        return 0;
    });
};

export function getDateForBatch(batch: Batch): Date {
    return batch.events[0].timestamp;
}

export const isUpdatingBatches: Selector<RootState, boolean> = (state) => {
    return state.batches.status === "UPDATING";
};

export const selectFilteredBatchListings: Selector<RootState, Omit<BatchListing, "original">[]> = (state) => {
    return state.batches.batchListings;
};

export const selectBatchListingsSortOptions: Selector<Pick<RootState, "batches">, BatchesSortOptions> = (
    state,
) => {
    return state.batches.sort;
};

export const selectBatchListings = createSelector(
    [selectFilteredBatchListings, selectBatchListingsSortOptions],
    (batchListings, sortOptions) => {
        const order = makeBatchesOrderFunc(sortOptions[0]);
        return pipe(batchListings, A.sort(order));
    },
);

export const isLoadingBatchListings: Selector<RootState, boolean> = (state) => {
    return state.batches.status === "FETCHING_BATCH_LISTINGS";
};

function getStatusOrder(status: BatchStatusName) {
    // the type cast below is the lesser (least?) of two (multiple?) evils... the "correct" way
    // to do this would involve converting the union of (typeof BatchStatusSequence)[keyof typeof BatchStatusSequence]
    // into a tuple, which is possible, but reportedly all kinds of bad
    return BatchStatusSequence.indexOf(status as (typeof BatchStatusSequence)[number]);
} // this is a big hack to avoid importing it from listings.ts and causing circular import issue
