import { useEffect, useState } from "react";
import { getVocabularyName } from ".";

const locale = getVocabularyName() || "error";

// thanks, copilot!
function extname(path: string) {
    const index = path.lastIndexOf(".");
    return index < 0 ? "" : path.slice(index);
}

// thanks, copilot!
function basename(path: string) {
    const index = path.lastIndexOf(".");
    return index < 0 ? path : path.slice(0, index);
}

const useBrandedImage = (fileName: string) => {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);
    const [image, setImage] = useState<string | null>(null);

    useEffect(() => {
        const fetchImage = async () => {
            const base = basename(fileName);
            const ext = extname(fileName);
            try {
                // See https://github.com/rollup/plugins/tree/master/packages/dynamic-import-vars#limitations
                if (ext === ".svg") {
                    const response = await import(`../images/${locale}/${base}.svg`);
                    setImage(response.default);
                } else if (ext === ".png") {
                    const response = await import(`../images/${locale}/${base}.png`);
                    setImage(response.default);
                } else {
                    setError(`Unsupported file type: ${ext} (in ${fileName})`);
                }
            } catch (err: unknown) {
                setError(`Failed to load image: ${fileName}, locale: ${locale}`);
            } finally {
                setLoading(false);
            }
        };
        //eslint-disable-next-line
        fetchImage();
    }, [fileName]);

    return {
        loading,
        error,
        image,
    };
};

export default useBrandedImage;
