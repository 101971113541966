import { User } from "@corechain-technologies/types";
import { Reducer } from "redux";
import { AppDispatch, AppThunk, RootState } from ".";
import { Selector, createSelector } from "reselect";
import * as msal from "@azure/msal-browser";
import { loginRequestConfig, msalConfig } from "../authConfig";
import { ReadonlyDeep } from "type-fest";
import { createAction } from "../utils/createAction";
import { setNotificationWithoutTimeout } from "./notifications";
import * as Sentry from "@sentry/react";
import { setAuthToken, trpc } from "../utils/trpc";

export enum AuthStatus {
    UNINITIALIZED = "UNINITIALIZED",
    NOT_LOGGED_IN = "NOT_LOGGED_IN",
    PENDING = "PENDING",
    LOGGED_IN = "LOGGED_IN",
    LOGGED_OUT = "LOGGED_OUT",
    ERROR = "ERROR",
}

export type UserState = {
    cxUser: User | null;
    azureAccount: msal.AccountInfo | null;
    authStatus: AuthStatus;
    showLoginNotification: boolean;
};

export const initialUserState: UserState = {
    cxUser: null,
    azureAccount: null,
    authStatus: AuthStatus.UNINITIALIZED,
    showLoginNotification: true,
};

export const msalInstance = new msal.PublicClientApplication(msalConfig);
const msalInitializePromise = msalInstance.initialize();

msalInitializePromise.then(() => {
    if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
        // Account selection logic is app dependent. Adjust as needed for different use cases.
        msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
    }

    // update account state if user signs-in from another tab or window
    msalInstance.enableAccountStorageEvents();
});

msalInstance.addEventCallback((event: msal.EventMessage) => {
    if (event.eventType === msal.EventType.LOGIN_SUCCESS && event.payload) {
        const payload = event.payload as msal.AuthenticationResult;
        const account = payload.account;
        msalInstance.setActiveAccount(account);
    }
});

export const restoreSession = createAction("USER:RESTORE_SESSION", (data: User) => ({
    cxUser: data,
}));
export const azureLoginSuccess = createAction(
    "USER:AZURE_LOGIN_SUCCESS",
    (account: msal.AccountInfo) => account,
);
export const azureTokenSuccess = createAction("USER:AZURE_TOKEN_SUCCESS");
export const logout = createAction("USER:LOGOUT");

export const userReducer: Reducer<UserState> = (state = initialUserState, action) => {
    if (restoreSession.match(action)) {
        return {
            ...state,
            cxUser: action.payload.cxUser,
            authStatus: AuthStatus.LOGGED_IN,
        };
    }
    if (logout.match(action)) {
        return { ...initialUserState, authStatus: AuthStatus.LOGGED_OUT };
    }
    if (azureLoginSuccess.match(action)) {
        let status = AuthStatus.PENDING;
        if (state.authStatus === AuthStatus.LOGGED_IN) {
            status = AuthStatus.LOGGED_IN;
        }
        return { ...state, azureAccount: action.payload, authStatus: status };
    }
    if (azureTokenSuccess.match(action)) {
        let status = AuthStatus.PENDING;
        if (state.authStatus === AuthStatus.LOGGED_IN) {
            status = AuthStatus.LOGGED_IN;
        }
        return { ...state, authStatus: status };
    }
    return state;
};

export async function handleAzureLogin() {
    await msalInitializePromise;

    try {
        // Check if there is an existing signed-in account
        const accounts = msalInstance.getAllAccounts();
        if (accounts.length > 0) {
            // Try to silently authenticate if there is an existing session
            // const silentRequest = {
            //   scopes: ["openid", "profile", "user.read"]
            // };
            const silentResponse = await msalInstance.ssoSilent(loginRequestConfig);
            console.log("Silent authentication success:", silentResponse);
            return;
        }

        // If there is no existing session, initiate a new popup flow
        //   const request = {
        //     scopes: ["openid", "profile", "user.read"]
        //   };
        const response = await msalInstance.loginPopup(loginRequestConfig);
        console.log("Popup authentication success:", response);
    } catch (error) {
        console.log("Authentication error:", error);
    }
}

export async function getAzureAccessToken(account: msal.AccountInfo) {
    const request = { ...loginRequestConfig, account };
    try {
        const tokenResponse = await msalInstance.acquireTokenSilent(request);
        setAuthToken(tokenResponse.accessToken);
        return tokenResponse;
    } catch (error) {
        // TODO: look up BrowserAuthError to see if there's a better way
        if (error instanceof msal.InteractionRequiredAuthError) {
            // return msalInstance.acquireTokenRedirect(request);
            return msalInstance.acquireTokenPopup(request);
        }
        if (error instanceof msal.BrowserAuthError) {
            console.log("BAR", error);
        }
    }
}

export const isAzureAuthenticated = createSelector(
    (state: RootState) => state.user.azureAccount,
    (azureAccount) => !!azureAccount,
);

export function initAzureLogin(withUserFetch: boolean = false): AppThunk {
    return async (dispatch: AppDispatch, getState: () => RootState) => {
        const accounts: msal.AccountInfo[] = [];
        try {
            // await msalInstance.initialize();
            // await msalInstance.handleRedirectPromise();
            // await msalInstance.loginPopup();
            // const account = msalInstance.getActiveAccount();
            const allAccounts = msalInstance.getAllAccounts();
            if (allAccounts.length) {
                // there shouldn't be multiple accounts here today,
                // but there could be multiple in the future...
                accounts.push(allAccounts[0]);
            }
        } catch (error) {
            // Intentionally not handled - we just redirect to the login below
        }
        if (accounts[0]) {
            dispatch(azureLoginSuccess(accounts[0]));

            // populating store and then redirecting, grab access token
            const token = await getAzureAccessToken(accounts[0]);

            if (token) {
                dispatch(azureTokenSuccess());
            }
        } else {
            // await msalInstance.loginRedirect();
            await msalInstance.loginPopup();
            // sometimes the popup does not correctly redirect the app
            // in this case, we need to re-initialize the login
            // and get the correct token
            return dispatch(initAzureLogin(withUserFetch));
        }
        if (withUserFetch) {
            await dispatch(fetchUser);
        }
    };
}

export function initAzureLogout(): AppThunk {
    return async (dispatch: AppDispatch, getState: () => RootState) => {
        dispatch(logout());
        await msalInstance.logoutPopup();
        // await msalInstance.logoutRedirect({
        //     postLogoutRedirectUri: `${window.location.origin}/`,
        // });
    };
}

export async function fetchUser(dispatch: AppDispatch, getState: () => RootState) {
    try {
        const { payer } = getState();
        const result = await trpc.user.get.query();

        if (result.err) {
            return; // post a notification that user fetch has failed
        }

        if (result.ok) {
            dispatch(restoreSession(result.val));
            Sentry.setUser({ id: result.val.userId, username: result.val.name });
        }
    } catch (error) {
        dispatch(
            setNotificationWithoutTimeout({
                message: "User info not found. Click to dismiss.",
                notificationType: "ERROR",
            }),
        );
        Sentry.captureException(error);
    }
}

export function initFetchCxUser(): AppThunk {
    return fetchUser;
}

export const selectUserRoles: Selector<RootState, User["roles"]> = createSelector(
    [isAzureAuthenticated, (state: Pick<RootState, "user">) => state.user],
    (loggedIn, user) => (loggedIn && user.cxUser ? user.cxUser.roles.slice() : []),
);

export const selectUserEmail: Selector<RootState, string> = createSelector(
    [isAzureAuthenticated, (state: Pick<RootState, "user">) => state.user],
    (loggedIn, user) => (loggedIn ? user.azureAccount?.username ?? "" : ""),
);

export const selectCxUserName: Selector<RootState, string> = createSelector(
    [isAzureAuthenticated, (state: Pick<RootState, "user">) => state.user],
    (loggedIn, user) => (loggedIn ? user.cxUser?.name ?? "" : ""),
);

export const selectAuthStatus: Selector<Pick<RootState, "user">, AuthStatus> = createSelector(
    (state: Pick<RootState, "user">) => state.user,
    (user) => user.authStatus,
);

export const selectAzureAccount: Selector<
    Pick<RootState, "user">,
    ReadonlyDeep<msal.AccountInfo> | null
> = createSelector(
    (state: Pick<RootState, "user">) => state.user,
    (user) => user.azureAccount,
);

export const selectDefaultCxUser: Selector<RootState, ReadonlyDeep<User> | null> = createSelector(
    [isAzureAuthenticated, (state: Pick<RootState, "user">) => state.user],
    (loggedIn, user) => (loggedIn ? user.cxUser : null),
);
