import { useAppSelector } from "../hooks";
import { selectFormPosition } from "../store/supplierOnboarding";
import { Outlet, useMatch, useNavigate } from "react-router-dom";
import { useEffect } from "react";

const SupplierOnboarding = () => {
    const formPosition = useAppSelector(selectFormPosition);
    const navigate = useNavigate();
    const match = useMatch("/:payer/app/supplier-onboarding/:path");

    useEffect(() => {
        if (formPosition === "COMPANY_USER_INFO" && match?.params?.path !== "company-user-info") {
            navigate("company-user-info");
        }
        if (formPosition === "PAYMENT_METHOD" && match?.params?.path !== "payment-method") {
            navigate("payment-method");
        }
        if (formPosition === "PAYMENT_INFO" && match?.params?.path !== "payment-info") {
            navigate("payment-info");
        }
        if (formPosition === "COMPLETE" && match?.params?.path !== "complete") {
            navigate("complete");
        }
    }, [match?.params?.path, navigate, formPosition]);

    let width = "max-w-6xl";
    if (formPosition === "PAYMENT_METHOD") {
        width = "max-w-lg";
    }
    if (formPosition === "COMPLETE") {
        width = "max-w-4xl";
    }

    return (
        <div className={`${width} p-4 mx-auto`}>
            <Outlet />
        </div>
    );
};

export default SupplierOnboarding;
