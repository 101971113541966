import React from "react";
import { useAppSelector } from "../hooks";
import { Outlet, useNavigate } from "react-router-dom";
import { PaymentWithDate, selectSortedPayments } from "../store/listings";
import { format } from "date-fns";
import { formatFromCents } from "../utils";
import { useI18n } from "@hi18n/react";
import { book } from "../locale";
import { showDetailPane } from "./DetailPane";
import { PrintHeader } from "./PrintHeader";

export const payerStatusStyles = {
    active: "bg-green-100 text-green-800",
    pending: "bg-yellow-100 text-yellow-800",
    inactive: "bg-gray-200 text-gray-800",
};
type PayerStatusStyles = "active" | "pending" | "inactive";

export interface TempPayerFields {
    payments: PaymentWithDate[];
    payerName: string;
    payerId: string;
    totalPaymentsValue: number;
    previousPaymentDate: Date;
    previousPaymentValue: number;
    initialPaymentDate: Date;
    payerStatus: PayerStatusStyles;
}

export type TempPayerData = { [name: string]: TempPayerFields };

const DisplayPayers: React.FC = () => {
    const navigate = useNavigate();
    const { t } = useI18n(book);
    const selectedPayments = useAppSelector(selectSortedPayments);

    const paymentsToVendorByPayer: TempPayerData = selectedPayments.reduce((acc, cur) => {
        if (cur.originName === "") {
            return acc;
        }
        // remove filtering by "ALMA" and implement supplier user filtering
        if (cur.recipientName !== "ALMA") {
            return acc;
        }
        if (acc[cur.originName]) {
            acc[cur.originName].totalPaymentsValue += Number(cur.amount);
        } else {
            acc[cur.originName] = {
                payments: [cur],
                payerName: cur.originName,
                payerId: cur.payerId,
                totalPaymentsValue: Number(cur.amount),
                previousPaymentDate: cur.createdDate,
                previousPaymentValue: Number(cur.amount),
                initialPaymentDate: cur.createdDate,
                payerStatus: "active",
            };
        }
        return acc;
    }, {} as TempPayerData);

    const payerList: TempPayerFields[] = Object.keys(paymentsToVendorByPayer).map((d: string) => {
        return paymentsToVendorByPayer[d];
    });

    return (
        <>
            <Outlet />
            <div>
                <div className="mt-8 max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
                    <PrintHeader title={t("payerList/title")} className="-mt-8" />
                    <div className="mt-2 print:mt-0 flex flex-col">
                        <div className="inline-block min-w-full py-2 align-middle">
                            <div className="shadow-sm ring-1 ring-black ring-opacity-5">
                                <table className="min-w-full border-separate border-spacing-0">
                                    <thead className="bg-gray-50 card">
                                        <tr>
                                            <th
                                                scope="col"
                                                className="sticky print:static top-0 border-b border-gray-300 bg-gray-50 bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:pl-6 lg:pl-8"
                                            >
                                                {t("payerList/companyName")}
                                            </th>
                                            <th
                                                scope="col"
                                                className="sticky print:static top-0 hidden border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:table-cell print:table-cell"
                                            >
                                                {t("payerList/lastPayment")}
                                            </th>
                                            <th
                                                scope="col"
                                                className="sticky print:static top-0 hidden border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter md:table-cell print:table-cell"
                                            >
                                                {t("payerList/enrolled")}
                                            </th>
                                            <th
                                                scope="col"
                                                className="sticky print:static top-0 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter"
                                            >
                                                {t("payerList/status")}
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody className="bg-white">
                                        {/* <!-- Odd row --> */}
                                        {payerList.map((payer, i) => (
                                            <tr
                                                key={payer.payerId + +payer.totalPaymentsValue + i}
                                                className={`${
                                                    i % 2 === 0 ? "bg-white" : "bg-gray-50"
                                                } hover:bg-gray-100 cursor-pointer group card`}
                                                onClick={() =>
                                                    navigate(
                                                        showDetailPane("#detail/payer/", payer.payerName),
                                                    )
                                                }
                                            >
                                                <td className="max-w-0 py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:w-auto sm:max-w-none sm:pl-6 lg:pl-8">
                                                    <div className="font-medium text-gray-700 group-hover:text-gray-900">
                                                        {payer.payerName}
                                                    </div>
                                                    <div className="font-normal mt-1 truncate text-gray-500">
                                                        {t("payerList/totalReceivedInline")}{" "}
                                                        <span className="text-gray-700">
                                                            {formatFromCents(payer.totalPaymentsValue)}
                                                        </span>
                                                    </div>
                                                    <dl className="sm:hidden font-normal truncate text-gray-500 print:hidden">
                                                        <dt className="sr-only">
                                                            {t("payerList/lastPayment")}
                                                        </dt>
                                                        <dd className="mt-1">
                                                            {t("payerList/lastPaymentInline")}{" "}
                                                            <span className="text-gray-700">
                                                                {format(
                                                                    payer.previousPaymentDate,
                                                                    "MM/dd/yyyy",
                                                                )}
                                                            </span>
                                                        </dd>
                                                        <dt className="sr-only">{t("payerList/role")}</dt>
                                                        <dd className="mt-1">
                                                            {t("payerList/amountReceivedInline")}{" "}
                                                            <span className="text-gray-700">
                                                                {formatFromCents(payer.previousPaymentValue)}
                                                            </span>
                                                        </dd>
                                                    </dl>
                                                </td>
                                                <td className="hidden sm:table-cell print:table-cell whitespace-nowrap px-3 py-4 text-sm">
                                                    <div className="font-medium text-gray-700 group-hover:text-gray-900">
                                                        {format(payer.previousPaymentDate, "MM/dd/yyyy")}
                                                    </div>
                                                    <div className="font-normal text-gray-500 capitalize">
                                                        {formatFromCents(payer.previousPaymentValue)}
                                                    </div>
                                                </td>
                                                <td className="max-w-0 px-3 py-4 text-sm font-medium text-gray-900 sm:w-auto sm:max-w-none">
                                                    <div className="text-gray-500 group-hover:text-gray-700">
                                                        {format(payer.initialPaymentDate, "MM/dd/yyyy")}
                                                    </div>
                                                    <dl className="md:hidden print:hidden first-letter:font-normal">
                                                        <dt className="sr-only">{t("payerList/status")}</dt>
                                                        <dd className="mt-1">
                                                            <span
                                                                className={`${
                                                                    payerStatusStyles[
                                                                        payer.payerStatus as
                                                                            | "active"
                                                                            | "pending"
                                                                            | "inactive"
                                                                    ]
                                                                } inline-flex w-min-content text-center rounded-full bg-green-100 px-2 text-xs font-semibold text-green-800 capitalize`}
                                                            >
                                                                {payer.payerStatus}
                                                            </span>
                                                        </dd>
                                                    </dl>
                                                </td>
                                                <td className="hidden md:table-cell print:table-cell whitespace-nowrap px-3 py-4 text-sm">
                                                    <div>
                                                        <span
                                                            className={`${
                                                                payerStatusStyles[
                                                                    payer.payerStatus as
                                                                        | "active"
                                                                        | "pending"
                                                                        | "inactive"
                                                                ]
                                                            } inline-flex w-min-content text-center rounded-full bg-green-100 px-2 text-xs font-semibold text-green-800 capitalize`}
                                                        >
                                                            {payer.payerStatus}
                                                        </span>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default DisplayPayers;
