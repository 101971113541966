import { CONFIG_ALL, environment } from "@corechain-technologies/config";
import type { AppRouter } from "@corechain-technologies/cxpay-api/dist/index.js";
import { createTRPCProxyClient, httpLink, loggerLink } from "@trpc/client";
import { TRPCError } from "@trpc/server";
import SuperJSON from "superjson";
import { JSONValue } from "superjson/dist/types";
import { Ok, Err, Result } from "ts-results";

let authToken: string = "";

export function setAuthToken(token: string) {
    authToken = token;
}

SuperJSON.registerClass(TRPCError, { identifier: "TRPCError" });
SuperJSON.registerClass(Ok, { identifier: "Ok" });
SuperJSON.registerClass(Err, { identifier: "Err" });
SuperJSON.allowErrorProps("name", "message", "stack", "code");

const environmentName = import.meta.env.VITE_CORECHAIN_ENV;
if (!environment.isType(environmentName)) {
    throw new Error(
        `VITE_CORECHAIN_ENV invalid. Got ${JSON.stringify(environmentName)}, expected one of: ${Object.keys(CONFIG_ALL).join(", ")}`,
    );
}

export const trpc = createTRPCProxyClient<AppRouter>({
    links: [
        httpLink({
            url: CONFIG_ALL[environmentName]["@environment"].baseCxpayApiUrl,
            headers() {
                return {
                    Authorization: `Bearer ${authToken}`,
                    "dapr-app-id": "cxpay-api",
                };
            },
            fetch(url, opts) {
                console.log(`fetch:${url}`, opts);
                return fetch(url, { ...opts, credentials: "include" });
            },
        }),
        loggerLink({
            console,
            enabled: () => process.env.VITE_CORECHAIN_ENV !== "production",
        }),
    ],
    transformer: SuperJSON,
});
