import { useAppDispatch, useAppSelector } from "../hooks";
// import partnerLogo from "../images/scanco/scancoLogoOnly.svg";
import landing from "../images/landing.jpg";
import { initAzureLogin, msalInstance, selectAuthStatus, selectDefaultCxUser } from "../store/user";
import { Navigate, useLocation } from "react-router-dom";
import { useI18n } from "@hi18n/react";
import { book } from "../locale";
import useBrandedImage from "../utils/useBrandedImage";
import { PayerState, setPayer } from "../store/payer";
import { useEffect } from "react";
import { selectActivePayer } from "../store";
import { PrivacyPolicyLink, TermsOfServiceLink } from "../components/ToSAndPrivacyLinks";
import { getVocabularyName } from "../utils";

export const LargeLogo = () => {
    const { t } = useI18n(book);
    const { image, error } = useBrandedImage(t("app/sidebar/logo"));
    if (image) {
        return (
            <>
                <img
                    className="hidden lg:inline-block h-auto w-36 py-8 justify-self-center self-center"
                    src={image ?? ""}
                    alt="sidebar logo"
                />
                <img
                    className="lg:hidden h-auto w-36 py-8 inline-flex justify-self-center self-center"
                    src={image ?? ""}
                    alt="sidebar logo"
                />
            </>
        );
    }
    return <>{error ? error : ""}</>;
};

type PublicLandingPageProps = {
    payer?: PayerState;
};
const PublicLandingPage = ({ payer }: PublicLandingPageProps) => {
    const dispatch = useAppDispatch();
    const { t } = useI18n(book);
    const activePayer = useAppSelector(selectActivePayer);

    useEffect(() => {
        const account = msalInstance.getActiveAccount();
        if (account) {
            dispatch(initAzureLogin(true));
        }
    }, [msalInstance, dispatch]);

    useEffect(() => {
        payer && dispatch(setPayer(payer));
    }, [payer, dispatch]);

    const handleLogin = () => dispatch(initAzureLogin(true));

    if (activePayer) {
        return <Navigate to={`/${activePayer.name}/app`} />;
    }

    return (
        <div className={["flex", "min-h-screen", getVocabularyName()].join(" ")}>
            <div className="flex flex-1 flex-col justify-center min-h-screen py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
                <div className="mx-auto w-full max-w-sm lg:w-96">
                    <div className="flex flex-col justify-center">
                        <LargeLogo />
                        <h2 className="text-center mt-6 text-3xl font-bold tracking-tight text-gray-900">
                            {t("app/greeting")}
                        </h2>
                    </div>

                    <div className="mt-8">
                        <div className="mt-6">
                            <div>
                                <button
                                    type="submit"
                                    className="flex w-full justify-center rounded-md border border-transparent bg-branded-primary py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-branded-primary-active"
                                    onClick={() => handleLogin()}
                                >
                                    Sign in
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="py-8 px-10 justify-left align-center text-center text-gray-700 text-xs font-normal">
                        {t("app/usage")} <TermsOfServiceLink /> and <PrivacyPolicyLink />
                    </div>
                </div>
            </div>
            <div className="hidden w-0 flex-1 relative top-0 sm: left-0 md:block">
                <img className="absolute inset-0 h-screen w-screen object-cover" src={landing} alt="" />
            </div>
        </div>
    );
};

export default PublicLandingPage;
