import { NavLink } from "react-router-dom";
import { useI18n } from "@hi18n/react";
import { book } from "../locale";

export const TermsOfServiceLink = () => {
    const { t } = useI18n(book);
    return (
        <NavLink
            to="https://corechain.tech/legal/terms-of-service"
            target="_blank"
            className="underline hover:text-gray-900"
        >
            {t("app/terms-of-service")}{" "}
        </NavLink>
    );
};

export const PrivacyPolicyLink = () => {
    const { t } = useI18n(book);
    return (
        <NavLink
            to="https://corechain.tech/legal/privacy-policy"
            target="_blank"
            className="underline hover:text-gray-900"
        >
            {t("app/privacy-policy")}
        </NavLink>
    );
};
