import { formatFromCents } from "../utils";
import { useAppSelector } from "../hooks";
import { classNames } from "../utils/classNames";
import Feature from "./Feature";
import { book } from "../locale";
import { useNavigate } from "react-router-dom";
import { getStatusCssClassnames, lowercase } from "../utils/statusStyles";
import PaginationControls from "../components/PaginationControls";
import ColumnHeader from "../components/ColumnHeader";
import { useI18n } from "@hi18n/react";
import { selectSortedReceivables } from "../store/listings";
import { CreditCardIcon, CurrencyDollarIcon } from "@heroicons/react/24/outline";
import { showDetailPane } from "./DetailPane";
import { PrintHeader } from "./PrintHeader";

export const ReceivablesMobile: React.FC = () => {
    const { t } = useI18n(book);
    const navigate = useNavigate();
    const paymentsToVendorHistory = useAppSelector(selectSortedReceivables);

    return (
        <div className="shadow sm:hidden print:hidden">
            <ul className="mt-3 divide-y divide-gray-200 overflow-hidden shadow sm:hidden">
                {paymentsToVendorHistory.map((payment, i) => (
                    <li key={payment.vendorPaymentId + i}>
                        <div
                            className="px-4 py-4 bg-white hover:bg-gray-50 cursor-pointer"
                            onClick={() =>
                                navigate(showDetailPane("#detail/receivable/", payment.vendorPaymentId))
                            }
                        >
                            <span className="flex space-x-4 items-center">
                                <span className="flex-1 flex space-x-2 truncate">
                                    <span className="flex flex-col text-gray-500 text-sm truncate">
                                        <span className="truncate">{payment.payer}</span>
                                        <span>
                                            <span className="text-gray-800 font-medium">
                                                <>{formatFromCents(payment.amount)}</>
                                            </span>{" "}
                                            {t("currency")}
                                        </span>
                                        <time dateTime={payment.date}>
                                            <>{payment.date}</>
                                        </time>
                                    </span>
                                </span>
                                <>
                                    {payment.method === "Card" && (
                                        <CreditCardIcon
                                            className="flex-shrink-0 h-5 w-5 text-gray-400"
                                            aria-hidden="true"
                                        />
                                    )}
                                    {payment.method === "ACH" && (
                                        <CurrencyDollarIcon
                                            className="flex-shrink-0 h-5 w-5 text-gray-400"
                                            aria-hidden="true"
                                        />
                                    )}{" "}
                                    <span className="text-sm text-gray-500">{payment.method}</span>
                                </>
                            </span>
                        </div>
                    </li>
                ))}
            </ul>
            <Feature flag="DEPLOY_TARGET" value="DEVELOPMENT">
                <PaginationControls />
            </Feature>
        </div>
    );
};

const ReceivablesDesktop: React.FC = () => {
    const { t } = useI18n(book);
    const navigate = useNavigate();
    const paymentsToVendorHistory = useAppSelector(selectSortedReceivables);

    return (
        <div className="hidden sm:block print:block">
            <div className="mt-10 print:mt-0 max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
                <PrintHeader title={t("receivables/title")} className="pb-2" />
                <div className="flex flex-col">
                    <div className="inline-block align-middle min-w-full pb-2">
                        <div className="shadow-sm ring-1 ring-black ring-opacity-5">
                            <table className="min-w-full divide-y divide-gray-200 border-separate border-spacing-0">
                                <thead className="bg-gray-50 card">
                                    <tr>
                                        <ColumnHeader sortValue="payer" className="pl-4 sm:pl-6 lg:pl-8" />
                                        <ColumnHeader
                                            sortValue="amount"
                                            textAlign="right"
                                            className="pl-0 xl:pl-6"
                                        />
                                        <th
                                            className="sticky print:static top-0 border-b border-gray-300 bg-gray-50 bg-opacity-75 backdrop-blur backdrop-filter px-6 py-3 text-left text-sm font-semibold text-gray-800 hidden lg:table-cell print:table-cell"
                                            scope="col"
                                        >
                                            <div className="group inline-flex">
                                                {t("activityTable/columnHeaders/method")}
                                                <Feature flag="DEPLOY_TARGET" value="DEVELOPMENT">
                                                    <span className="invisible ml-2 flex-none rounded text-gray-400 group-hover:visible group-focus:visible">
                                                        <div className="h-5 w-5" aria-hidden="true">
                                                            {/* {BarsArrowDownIcon} */}
                                                        </div>
                                                    </span>
                                                </Feature>
                                            </div>
                                        </th>
                                        <ColumnHeader
                                            sortValue="status"
                                            textAlign="center"
                                            className="pl-4 xl:pl-6 hidden md:table-cell print:table-cell"
                                        />
                                        <ColumnHeader sortValue="statusDate" textAlign="right" />
                                        <th
                                            className="sticky print:static whitespace-nowrap top-0 border-b border-gray-300 bg-gray-50 bg-opacity-75 backdrop-blur backdrop-filter py-3.5 pl-6 pr-4 text-right text-sm font-semibold text-gray-900 sm:pr-6 lg:pr-8"
                                            scope="col"
                                        >
                                            <div className="group inline-flex justify-right">
                                                {t("activityTable/columnHeaders/paymentIdReceivables")}
                                            </div>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className="bg-white divide-y divide-gray-200">
                                    {paymentsToVendorHistory.map((payment, i) => {
                                        const currentStatus = payment.status;

                                        return (
                                            <tr
                                                key={payment.vendorPaymentId + i}
                                                className="group bg-white hover:bg-gray-50 hover:cursor-pointer card"
                                                onClick={() =>
                                                    navigate(
                                                        showDetailPane(
                                                            "#detail/receivable/",
                                                            payment.vendorPaymentId,
                                                        ),
                                                    )
                                                }
                                            >
                                                <td className="pl-4 sm:pl-6 lg:pl-8 pr-6 py-4 whitespace-nowrap text-sm text-gray-800 border-b border-gray-200 border-spacing-0">
                                                    <div className="flex">
                                                        <div className="group inline-flex space-x-2 truncate text-sm">
                                                            <p className="text-gray-500 truncate group-hover:text-gray-800">
                                                                {payment.payer}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className="pl-0 xl:pl-6 pr-6 py-4 text-right whitespace-nowrap text-sm text-gray-500 border-b border-gray-200 border-spacing-0">
                                                    <span className="text-gray-800 font-medium">
                                                        {formatFromCents(payment.amount)}
                                                    </span>{" "}
                                                    {t("currency")}
                                                </td>
                                                <td className="px-6 py-4 hidden lg:table-cell print:table-cell text-center whitespace-nowrap text-sm text-gray-500 border-b border-gray-200 border-spacing-0">
                                                    <div className="flex gap-1">
                                                        {payment.method === "ACH" && (
                                                            <CurrencyDollarIcon
                                                                className="flex-shrink-0 h-5 w-5 mr-0.5 text-gray-400 group-hover:text-gray-500"
                                                                aria-hidden="true"
                                                            />
                                                        )}
                                                        {payment.method === "Card" && (
                                                            <CreditCardIcon
                                                                className="flex-shrink-0 h-5 w-5 mr-0.5 text-gray-400 group-hover:text-gray-500"
                                                                aria-hidden="true"
                                                            />
                                                        )}
                                                        <span className="text-gray-500">
                                                            {payment.method.toString()}
                                                        </span>
                                                    </div>
                                                </td>
                                                <td className="hidden text-center pl-0 xl:pl-6 pr-6 py-4 whitespace-nowrap text-sm text-gray-500 md:table-cell border-b border-gray-200 border-spacing-0 print:table-cell">
                                                    <span
                                                        className={classNames(
                                                            getStatusCssClassnames(currentStatus),
                                                            "inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium capitalize",
                                                        )}
                                                    >
                                                        <span className="capitalize">
                                                            {lowercase(payment.status)}
                                                        </span>
                                                    </span>
                                                </td>
                                                <td className="px-6 py-4 text-right whitespace-nowrap text-sm text-gray-500 border-b border-gray-200 border-spacing-0">
                                                    <time dateTime={payment.date}>{payment.date}</time>
                                                </td>
                                                <td className="pl-3 md:pl-6 pr-4 sm:pr-6 lg:pr-8 py-4 text-right whitespace-nowrap text-sm text-gray-500 border-b border-gray-200 border-spacing-0">
                                                    <div className="group inline-flex justify-right text-sm">
                                                        <p className="text-gray-500 font-mono">
                                                            {payment.vendorPaymentId.slice(0, 7)}
                                                        </p>
                                                    </div>
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                            {/* Pagination */}
                            <Feature flag="DEPLOY_TARGET" value="DEVELOPMENT">
                                <nav
                                    className="bg-white px-4 py-3 flex items-center justify-between sm:px-6 print:hidden"
                                    aria-label="Pagination"
                                >
                                    <div className="hidden sm:block print:block">
                                        <p className="text-sm text-gray-700">
                                            Showing<span className="font-medium"> 1</span> to
                                            <span className="font-medium">
                                                {" "}
                                                {paymentsToVendorHistory.length}
                                            </span>{" "}
                                            of
                                            <span className="font-medium">
                                                {" "}
                                                {paymentsToVendorHistory.length}
                                            </span>{" "}
                                            results
                                        </p>
                                    </div>
                                    <div className="flex-1 flex justify-between sm:justify-end">
                                        <div className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50">
                                            Previous
                                        </div>
                                        <div className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50">
                                            Next
                                        </div>
                                    </div>
                                </nav>
                            </Feature>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ReceivablesDesktop;
