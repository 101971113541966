import { AnyAction } from "redux";

type MyMatchable<T extends string, AC extends (...args: any[]) => any> = AC & {
    type: T;
    match(action: AnyAction): action is ReturnType<AC>;
};
export interface ActionCreator<T extends string, A extends any[], P> {
    (...args: A): { type: T; payload: P };
}
export interface ActionPreparer<A extends any[], P> {
    (...args: A): P;
}

export function createAction<T extends string>(type: T): MyMatchable<T, () => { type: T }>;
export function createAction<T extends string, P, A extends any[]>(
    type: T,
    actionPreparer: ActionPreparer<A, P>,
): MyMatchable<T, ActionCreator<T, A, P>>;
export function createAction<T extends string, P, A extends any[]>(
    type: T,
    actionPreparer?: ActionPreparer<A, P>,
) {
    function actionCreator(...args: A) {
        if (actionPreparer) {
            const payload = actionPreparer(...args);
            return {
                type,
                payload,
            };
        }
        return { type };
    }
    actionCreator.toString = () => `${type}`;
    actionCreator.type = type;
    actionCreator.match = (action: AnyAction) => action.type === type;

    return actionCreator;
}
