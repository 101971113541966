import { XMarkIcon } from "@heroicons/react/24/outline";
import { LinkIcon } from "@heroicons/react/24/solid";
import { PaymentWithDate, selectAllPayments } from "../store/listings";
import { formatFromCents, tempFormatUuidString } from "../utils";
import { classNames } from "../utils/classNames";
import { useI18n } from "@hi18n/react";
import { book } from "../locale";
import { BatchStatusName, calculatePaymentId } from "@corechain-technologies/types";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppSelector } from "../hooks";
import { getStatusCssClassnames, LifecycleStatus, statusCssClassnames } from "../utils/statusStyles";
import { dismissDetailPane, showDetailPane } from "./DetailPane";
import { LifeCycleFeed } from "./LifeCycleFeed";

const ReceivablesDetail: React.FC = () => {
    const { t } = useI18n(book);
    const location = useLocation();
    const navigate = useNavigate();
    const payments: PaymentWithDate[] = useAppSelector(selectAllPayments);

    const payment = payments.filter((payment) =>
        location.hash.includes(encodeURI(calculatePaymentId(payment))),
    )[0];

    if (!payment) {
        navigate("/404", { replace: true });
        return;
    }

    if (!("destinationAccountId" in payment)) {
        console.warn(
            [
                `Only NachaPaymentInstructionMessages are supported, currently. ${payment.correlationId}`,
                `does not have a "destinationAccountId" property, so it's not a NachaPaymentInstructionMessage.`,
            ].join("\n"),
        );
        return <></>;
    }

    const currentStatus = payment ? payment.currentStatus : BatchStatusName.ERROR;

    return (
        <>
            <div className="px-4 sm:px-6">
                <div className="flex items-start justify-between">
                    <div
                        className="group text-lg font-medium cursor-pointer"
                        onClick={() =>
                            // link to payer profile on payer profiles page
                            navigate(showDetailPane("#detail/payer/", payment.originName))
                        }
                    >
                        <span className="group-hover:text-gray-700 text-gray-500 font-normal">
                            {payment?.metadata.paymentMethod + " "}
                            {t("receivablesDetail/paymentFrom") + " "}
                        </span>
                        <span className="group-hover:text-gray-800 text-gray-600">
                            {payment.originName}
                            <LinkIcon className="stroke-gray-500 group-hover:stroke-gray-700 inline-flex self-top ml-1 h-4 w-4" />
                        </span>
                        <div className="flex items-center justify-left gap-2 pt-2">
                            <div
                                className={classNames(
                                    getStatusCssClassnames(currentStatus),
                                    "inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium capitalize",
                                )}
                            >
                                <span className="capitalize">{payment.currentStatus.toLowerCase()}</span>
                            </div>
                        </div>
                    </div>

                    <div className="ml-3 flex h-7 items-center">
                        <button
                            type="button"
                            className="rounded-md bg-white text-gray-400 hover:text-gray-500"
                            onClick={() => navigate(dismissDetailPane())}
                        >
                            <span className="sr-only">Close panel</span>
                            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                        </button>
                    </div>
                </div>
            </div>
            <div className="relative bg-gray-200 mt-4 flex-1 px-4 sm:px-6 overflow-scroll">
                {/* /payment info */}
                <div className="absolute inset-0 pt-5 pb-6 px-4 sm:px-6">
                    <div
                        className="h-full border overflow-y-scroll bg-white shadow border-gray-200 sm:rounded-lg"
                        aria-hidden="true"
                    >
                        <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
                            <dl className="sm:divide-y sm:divide-gray-200">
                                <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                                    <dt className="text-sm font-medium text-gray-500">
                                        {t("receivablesDetail/receivingAccount")}{" "}
                                    </dt>
                                    <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                        {`******${payment.destinationAccountId.slice(
                                            payment.destinationAccountId.length - 3,
                                        )}`}
                                    </dd>
                                </div>
                                <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                                    <dt className="text-sm font-medium text-gray-500">
                                        {t("receivablesDetail/totalAmount")}
                                    </dt>
                                    <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                        {formatFromCents(payment.amount.toString() ?? "")}
                                    </dd>
                                </div>
                                <div className="group py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                                    <dt className="text-sm font-medium text-gray-500">
                                        {t("receivablesDetail/paymentId")}{" "}
                                    </dt>
                                    <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                        {tempFormatUuidString(payment.id).slice(0, 7)}
                                    </dd>
                                </div>
                                <LifeCycleFeed events={payment ? payment.events : []} />
                            </dl>
                        </div>
                    </div>
                </div>
                {/* /payment info */}
            </div>
        </>
    );
};

export default ReceivablesDetail;
