import { formatFromCents } from "../utils";
import { showDetailPane } from "./DetailPane";
import { PaymentListingProps } from "./PaymentListingDesktop";
import { getStatusCssClassnames, lowercase } from "../utils/statusStyles";
import { classNames } from "../utils/classNames";
import { format } from "date-fns";
import { useI18n } from "@hi18n/react";
import { book } from "../locale";
import { useNavigate } from "react-router-dom";
import { CurrencyDollarIcon, CreditCardIcon } from "@heroicons/react/20/solid";
import { BatchId, calculatePaymentId } from "@corechain-technologies/types";

const PaymentListingMobile: React.FC<PaymentListingProps> = ({ payment }) => {
    const { t } = useI18n(book);
    const navigate = useNavigate();

    return (
        <li key={payment.correlationId}>
            <div
                className="px-4 py-4 bg-white hover:bg-gray-50 cursor-pointer"
                onClick={() =>
                    navigate(
                        showDetailPane(
                            "#detail/payment/",
                            calculatePaymentId({
                                batchId: payment.batchId,
                                payerId: payment.payerId,
                                sourceLineNumber: payment.data.sourceLineNumber,
                            }),
                        ),
                    )
                }
            >
                <span className="flex space-x-4 items-center">
                    <span className="flex-1 flex space-x-2 truncate">
                        <span className="flex flex-col text-gray-500 text-sm truncate">
                            <span className="truncate">{payment.data.recipientName}</span>
                            <span>
                                <span className="text-gray-800 font-medium">
                                    <span className="text-gray-500">$</span>
                                    {formatFromCents(Number(payment.amount))}
                                </span>{" "}
                                {t("currency")}
                            </span>
                            {payment.lastStatusDate ? (
                                <time dateTime={format(payment.lastStatusDate, "MM/dd/yyyy")}>
                                    <>{format(payment.lastStatusDate, "MM/dd/yyyy")}</>
                                </time>
                            ) : (
                                <></>
                            )}
                        </span>
                    </span>
                    {/* TODO - Enable wcolumn for payment type when integration for multiple payment types is completed */}
                    {/* {payment.method === "Card" && (
                        <CreditCardIcon className="flex-shrink-0 h-5 w-5 text-gray-400" aria-hidden="true" />
                    )}
                    {payment.method === "ACH" && (
                        <CurrencyDollarIcon
                            className="flex-shrink-0 h-5 w-5 text-gray-400"
                            aria-hidden="true"
                        />
                    )}{" "}
                    <span className="text-sm text-gray-500">{payment.method}</span> */}
                </span>
            </div>
        </li>
    );
};

export default PaymentListingMobile;
