import { BatchStatusName } from "@corechain-technologies/types";
import { BatchListing } from "../store/listings";
import { useNavigate } from "react-router-dom";
import { formatFromCents } from "../utils";
import { showDetailPane } from "./DetailPane";
import { classNames } from "../utils/classNames";
import { getStatusCssClassnames, lowercase } from "../utils/statusStyles";
import { format } from "date-fns";
import { useI18n } from "@hi18n/react";
import { book } from "../locale";

interface BatchListingDesktopProps {
    batchListing: Omit<BatchListing, "original">;
}

const BatchListingDesktop: React.FC<BatchListingDesktopProps> = ({ batchListing }) => {
    const { t } = useI18n(book);
    const navigate = useNavigate();

    return (
        <tr
            className="bg-white cursor-pointer group hover:bg-gray-50 card"
            onClick={() => navigate(showDetailPane("#detail/batch/", batchListing.id))}
        >
            <td className="pl-4 sm:pl-6 lg:pl-8 py-4 pr-1 text-left whitespace-nowrap text-sm text-gray-500 border-b border-gray-200 border-spacing-0">
                <time dateTime={format(batchListing.created_date, "MM/dd/yyyy")}>
                    {format(batchListing.created_date, "MM/dd/yyyy")}
                </time>
            </td>
            <td className="eleven21:pl-1 py-4 whitespace-nowrap text-sm text-gray-800 border-b border-gray-200 border-spacing-0">
                <div className="flex relative">
                    <div className="group/filename text-sm">
                        <p className="text-gray-500 truncate max-w-xs xl:max-w-lg">
                            {batchListing.uploadedFileName}
                        </p>
                        <p className="hidden group-hover/filename:block absolute bg-white -top-1 -left-4 px-4 py-1 rounded-full drop-shadow-md">
                            {batchListing.uploadedFileName}
                        </p>
                    </div>
                </div>
            </td>
            <td className="pr-6 eleven21:pl-6 py-4 text-right whitespace-nowrap text-sm font-medium text-gray-500 border-b border-gray-200 border-spacing-0">
                <span>$</span>
                <span className="text-gray-800">{formatFromCents(batchListing.amount.toString())}</span>{" "}
                {t("currency")}
            </td>
            <td className="hidden sm:table-cell print:table-cell text-center pr-6 eleven21:pl-1 py-4 whitespace-nowrap text-sm text-gray-500 border-b border-gray-200 border-spacing-0">
                <span
                    className={classNames(
                        getStatusCssClassnames(batchListing.status as BatchStatusName),
                        "inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium",
                    )}
                >
                    <span className="capitalize">{lowercase(batchListing.status)}</span>
                </span>
            </td>
        </tr>
    );
};

export default BatchListingDesktop;
