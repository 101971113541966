import {
    Area,
    AreaChart,
    CartesianGrid,
    ReferenceLine,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis,
} from "recharts";
import { useAppSelector } from "../hooks";
import { selectStatistics } from "../store/statistics";
import { BatchListing, PaymentWithDate } from "../store/listings";
import { formatFromCents } from "../utils";
import { downloadPayeeChartDateReport, downloadPayerChartDateReport } from "../utils/reporting";
import { formatSortedPaymentsByMethod, formatVolumeByMethod } from "../utils/formatChartData";
import { CloudArrowDownIcon } from "@heroicons/react/24/outline";
import { useI18n } from "@hi18n/react";
import { book } from "../locale";
import { PaymentListing } from "../store/payments";
import { PaymentMethod } from "@corechain-technologies/types";

const coreGreen = "#28dd5f";
const coreStatic = "#00b33a";
const coreDark = "#012b27";

const LABEL_ACH = "ACH";
const LABEL_VIRTUAL_CARD = "Card";
const LABEL_CHECK = "Check";
const LABEL_E_CHECK = "eCheck";

const PaymentMethodLabel = {
    [PaymentMethod.ACH]: LABEL_ACH,
    [PaymentMethod.VIRTUAL_CARD]: LABEL_VIRTUAL_CARD,
    [PaymentMethod.CHECK]: LABEL_CHECK,
    [PaymentMethod.E_CHECK]: LABEL_E_CHECK,
};

const CHART_COLOR_ACH = "#008C2D";
const CHART_COLOR_VIRTUAL_CARD = "#07AFF0";
const CHART_COLOR_CHECK = "#a6fead";
const CHART_COLOR_E_CHECK = coreGreen;

const PaymentMethodColor = {
    [PaymentMethod.ACH]: CHART_COLOR_ACH,
    [PaymentMethod.VIRTUAL_CARD]: CHART_COLOR_VIRTUAL_CARD,
    [PaymentMethod.CHECK]: CHART_COLOR_CHECK,
    [PaymentMethod.E_CHECK]: CHART_COLOR_E_CHECK,
};

function sortTooltipLabels(label: string | number | undefined) {
    const method = PaymentMethod.parse(label);
    const sortOrder = [
        PaymentMethod.ACH,
        PaymentMethod.VIRTUAL_CARD,
        PaymentMethod.E_CHECK,
        PaymentMethod.CHECK,
    ];
    return method ? sortOrder.indexOf(method) : 0;
}

interface CustomizedAxisTickProps {
    x: number;
    y: number;
    payload: any;
}

const AngledDateTick = ({ x, y, payload }: CustomizedAxisTickProps) => {
    return (
        <g transform={`translate(${x},${y})`}>
            <text
                x={0}
                y={0}
                dx={-3}
                dy={8}
                fontSize={10}
                textAnchor="end"
                transform="rotate(-45)"
                className="fill-gray-600"
            >
                {payload.value}
            </text>
        </g>
    );
};

const CurrencyTick = ({ x, y, payload }: CustomizedAxisTickProps) => {
    return (
        <g transform={`translate(${x},${y})`}>
            <text
                x={0}
                y={0}
                // dx={-8}
                fontSize={10}
                textAnchor="end"
                alignmentBaseline="central"
                className="fill-gray-600"
            >
                {`${formatFromCents(payload.value)}`}
            </text>
        </g>
    );
};

interface MarkerShapeProps {
    label: PaymentMethod;
    className?: string | undefined;
}

const MarkerShape: React.FC<MarkerShapeProps> = ({ label }) => {
    return (
        <svg viewBox="0 0 80 20">
            <>
                <rect x={8} y={2} fill={PaymentMethodColor[label]} className="w-4 h-3" />
                <text x={28} y={13} className="text-xs fill-gray-600">
                    {`${PaymentMethodLabel[label]}`}
                </text>
            </>
        </svg>
    );
};

interface ChartMarkerProps {
    methodsToDisplay: PaymentMethod[];
}

const ChartMarkers: React.FC<ChartMarkerProps> = ({ methodsToDisplay }) => {
    return (
        <div className="mt-3 h-5 flex justify-center">
            {methodsToDisplay.map((method) => (
                <MarkerShape label={method} key={method} />
            ))}
        </div>
    );
};

interface PaymentChartProps {
    data: any;
    methodsToDisplay: PaymentMethod[];
    width?: number;
    height?: number;
    className?: string;
}

const PaymentChart: React.FC<PaymentChartProps> = ({ data, methodsToDisplay, width, height, className }) => {
    // width, height and className props are passed in when rendering in print view
    return (
        <AreaChart data={data} width={width} height={height} className={className}>
            <CartesianGrid className="stroke-gray-300" strokeDasharray="3 3" />
            {methodsToDisplay.map((method) => (
                <Area
                    dataKey={method}
                    fill={PaymentMethodColor[method]}
                    fillOpacity={1}
                    stackId={1}
                    isAnimationActive={false}
                    activeDot={false}
                    stroke=""
                    key={method}
                />
            ))}
            <XAxis
                dataKey="date"
                tick={<AngledDateTick x={10} y={10} payload="date" />}
                height={44}
                interval={0}
                reversed
            />
            <YAxis type="number" tick={<CurrencyTick x={-2} y={0} payload="total" />} width={80} />
            {data.length === 1 &&
                methodsToDisplay.map((method) => (
                    <ReferenceLine
                        transform={"translate(3)"}
                        stroke={PaymentMethodColor[method]}
                        strokeWidth={6}
                        segment={[
                            { x: data.length - 1, y: 0 },
                            {
                                x: data.length - 1,
                                y: data[0][method],
                            },
                        ]}
                        key={method}
                    />
                ))}
            <Tooltip
                itemSorter={(item) => sortTooltipLabels(item.dataKey)}
                formatter={(value: string | number) => formatFromCents(value)}
                contentStyle={{ color: "#000" }}
                itemStyle={{ color: "#000" }}
            />
        </AreaChart>
    );
};

interface PaymentsTimelineProps {
    // note that for sets above minimum, the responsive chart should display 10-30 listings nicely on its own
    minDataRange: 5 | 10; // controls threshold below which chart has a 'tail' where insufficient data points exist
    maxDataRange: 10 | 30; // limit on how much data to display for aesthetic reasons, 10 mobile, 30 other sizes
    dataSource: BatchListing[] | PaymentWithDate[];
    paymentSet: PaymentListing[];
    isPayer: boolean;
}

const PaymentsTimeline: React.FC<PaymentsTimelineProps> = ({
    minDataRange,
    maxDataRange,
    dataSource,
    paymentSet,

    isPayer,
}) => {
    const showPaymentMethod = false;
    const { t } = useI18n(book);
    const stats = useAppSelector(selectStatistics);

    const dataSet = formatSortedPaymentsByMethod(paymentSet, showPaymentMethod);
    if (dataSet.length === 0) {
        return <></>;
    }

    const methodsToDisplay = formatVolumeByMethod(paymentSet, showPaymentMethod).map(({ method }) => method);
    // methodsToDisplay.push(PaymentMethod.ACH);
    // dataSet[0].ACH = 100000;
    // dataSet[0].CHECK = 200000;
    // dataSet[1].ACH = 200000;
    // dataSet[1].CHECK = 100000;
    // dataSet[2].ACH = 100000;
    // dataSet[2].CHECK = 300000;

    const dataPadding = Array(minDataRange).fill("");
    const data =
        dataSet.length >= minDataRange
            ? dataSet.slice(0, maxDataRange)
            : dataPadding.concat(dataSet).slice(dataSet.length, dataSet.length + minDataRange);

    return (
        <div>
            <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
                <div className="bg-white overflow-hidden shadow rounded-lg">
                    <div className="p-5">
                        <div className="h-5 flex justify-center align-middle gap-1 text-sm font-medium text-gray-500">
                            {t("paymentsTimeline/header", { maxDataRange: maxDataRange.toString() })}
                        </div>
                        <div className="relative pt-4">
                            <ResponsiveContainer width="100%" height={200} className="print:hidden">
                                <PaymentChart data={data} methodsToDisplay={methodsToDisplay} />
                            </ResponsiveContainer>
                            <PaymentChart
                                data={data}
                                methodsToDisplay={methodsToDisplay}
                                width={900}
                                height={200}
                                className="hidden print:block"
                            />
                            <ChartMarkers methodsToDisplay={methodsToDisplay} />
                        </div>
                    </div>
                    <div className="px-5 py-3 bg-gray-50 text-sm text-gray-600">
                        <span
                            className="cursor-pointer font-medium hover:text-gray-800 hover:stroke-gray-800"
                            onClick={() =>
                                isPayer
                                    ? downloadPayerChartDateReport(dataSource as BatchListing[], stats)
                                    : downloadPayeeChartDateReport(dataSource as PaymentWithDate[], stats)
                            }
                        >
                            <CloudArrowDownIcon className="inline-block mr-3 h-5" aria-hidden="true" />
                            <span>{t("reports/downloadReport")}</span>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PaymentsTimeline;
