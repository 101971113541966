import { useEffect, useState, useRef } from "react";
import { useAppDispatch, useAppSelector } from "../hooks";
import {
    enterCompanyUserInfo,
    selectCurrentFormData,
    SupplierOnboardingRecord,
} from "../store/supplierOnboarding";
import { usaStates } from "../utils/usaStates";
import {
    cursorForPhoneNumber,
    cursorForTin,
    makePhoneNumberMask,
    makeTinMask,
    makeValidationMessageSentence,
} from "../utils";
import ValidationMessage from "./ValidationMessage";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import { z } from "zod";

const completedtinExpression = /^[0-9]{9}$/;

const companyInfoFormSchema = z.object({
    companyName: z.string().nonempty(),
    country: z.enum(["United States", "Mexico", "Canada"]),
    tin: z.string().regex(completedtinExpression),
    tinType: z.enum(["ein", "ssn"]),
    streetAddress: z.string().nonempty(),
    city: z.string().nonempty(),
    stateOrProvince: z.enum(usaStates),
    zip: z.string().regex(/^\d{5}$/),
});

// type foo = keyof z.infer<typeof companyInfoFormSchema>;
type CompanyInfoFormFields = keyof SupplierOnboardingRecord["companyUserInfo"]["companyInfo"];

const completedPhoneNumberExpression = /^[0-9]{10}$/;

const userContactFormSchema = z.object({
    firstName: z.string().nonempty(),
    lastName: z.string().nonempty(),
    emailAddress: z.string().email(),
    phoneNumber: z.string().regex(completedPhoneNumberExpression),
});

// type bar = keyof z.infer<typeof userContactFormSchema>;
type UserContactFormFields = keyof SupplierOnboardingRecord["companyUserInfo"]["contactPerson"];

const CompanyUserInfoForm = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const currentState = useAppSelector(selectCurrentFormData).companyUserInfo;
    const [companyInfo, setCompanyInfo] = useState<
        SupplierOnboardingRecord["companyUserInfo"]["companyInfo"]
    >(currentState.companyInfo);
    const [companyInfoValidationMessage, setCompanyInfoValidationMessage] = useState<CompanyInfoFormFields[]>(
        [],
    );
    const [formMessage, setFormMessage] = useState<boolean>(false);
    const [companyInfoFlag, setCompanyInfoFlag] = useState<boolean>(true);
    const [contactPerson, setContactPerson] = useState<
        SupplierOnboardingRecord["companyUserInfo"]["contactPerson"]
    >(currentState.contactPerson);
    const [userContactValidationMessage, setUserContactValidationMessage] = useState<UserContactFormFields[]>(
        [],
    );
    const [userContactFormMessage, setUserContactFormMessage] = useState<boolean>(false);
    const [userContactFlag, setUserContactFlag] = useState<boolean>(true);

    const phoneNumberCard = useRef<HTMLInputElement>();

    useEffect(() => {
        if (userContactValidationMessage.length === 0) {
            setUserContactFormMessage(false);
        }
    }, [userContactValidationMessage]);

    const handlePhoneNumberChange = () => {
        if (phoneNumberCard?.current?.value) {
            const masked = makePhoneNumberMask(phoneNumberCard?.current?.value);
            phoneNumberCard.current.value = masked;
            const raw = masked.replace(/(\D)/g, "");
            if (raw.length === 0) phoneNumberCard.current.value = "";

            setContactPerson({ ...contactPerson, phoneNumber: raw });

            const input = document.getElementById("phoneNumber") as any;

            if (input) {
                input.focus();
                const pos = cursorForPhoneNumber(raw.length);
                input.setSelectionRange(pos, pos);
            }
        }
    };

    useEffect(
        () => handlePhoneNumberChange(),
        // eslint-disable-next-line
        [contactPerson.phoneNumber],
    );

    useEffect(
        () => {
            if (phoneNumberCard.current && contactPerson.phoneNumber.length > 0 && userContactFlag) {
                phoneNumberCard.current.value = makePhoneNumberMask(contactPerson.phoneNumber);
            }
            setUserContactFlag(false);
        },
        // eslint-disable-next-line
        [userContactFlag],
    );

    const setUserContactMask = () => {
        if (
            (phoneNumberCard.current?.value || phoneNumberCard?.current?.value === "") &&
            contactPerson.phoneNumber.length === 0
        ) {
            phoneNumberCard.current.value = "(___) ___-____";
            const input = document.getElementById("phoneNumber") as any;
            if (input) {
                input.focus();
                input.setSelectionRange(1, 1);
            }
        } else {
            const input = document.getElementById("phoneNumber") as any;

            if (input) {
                input.focus();
                const pos = cursorForPhoneNumber(contactPerson.phoneNumber.length);
                input.setSelectionRange(pos, pos);
            }
        }
    };

    const handleContactPersonChange = (event: React.FormEvent<HTMLInputElement>) => {
        setContactPerson({ ...contactPerson, [event.currentTarget.name]: event.currentTarget.value });
    };

    const handleUserContactBlur = (
        e: React.FocusEvent<HTMLInputElement> | React.FocusEvent<HTMLSelectElement>,
    ) => {
        const name = e.currentTarget.name as keyof typeof contactPerson;
        const value =
            name === "phoneNumber" ? e.currentTarget.value.replace(/(\D)/g, "") : e.currentTarget.value;

        if (userContactFormSchema.shape[name].safeParse(value).success) {
            setUserContactValidationMessage(
                userContactValidationMessage.filter((message) => message !== name),
            );
        } else {
            setUserContactValidationMessage([...userContactValidationMessage, name]);
        }
    };

    const isThereUserContactMessage = (
        key: UserContactFormFields, // do not export! bespoke to each form
    ) => (userContactValidationMessage.includes(key) ? makeValidationMessageSentence(key) : null);

    const tinCard = useRef<HTMLInputElement>();

    useEffect(() => {
        if (companyInfoValidationMessage.length === 0) {
            setFormMessage(false);
        }
    }, [companyInfoValidationMessage]);

    const changeTinType = () => {
        setCompanyInfoValidationMessage(companyInfoValidationMessage.filter((message) => message !== "tin"));
        setCompanyInfo({ ...companyInfo, tinType: companyInfo.tinType === "ein" ? "ssn" : "ein" });
    };

    const handleTinChange = () => {
        const tinType = companyInfo.tinType;
        if (tinCard?.current?.value) {
            if (tinType === "ein") {
                const masked = makeTinMask(tinCard.current.value, tinType);
                tinCard.current.value = masked;
                const raw = masked.replace(/(\D)/g, "");
                if (raw.length === 0) tinCard.current.value = "";

                setCompanyInfo({ ...companyInfo, tin: raw });

                const input = document.getElementById("tin") as any;

                if (input) {
                    input.focus();
                    const pos = cursorForTin(raw.length, tinType);
                    input.setSelectionRange(pos, pos);
                }
            } else if (tinType === "ssn") {
                const masked = makeTinMask(tinCard.current.value, tinType);
                tinCard.current.value = masked;
                const raw = masked.replace(/(\D)/g, "");
                if (raw.length === 0) tinCard.current.value = "";

                setCompanyInfo({ ...companyInfo, tin: raw });

                const input = document.getElementById("tin") as any;

                if (input) {
                    input.focus();
                    const pos = cursorForTin(raw.length, tinType);
                    input.setSelectionRange(pos, pos);
                }
            }
        }
    };

    useEffect(
        () => handleTinChange(),
        // eslint-disable-next-line
        [companyInfo.tin, companyInfo.tinType],
    );

    useEffect(
        () => {
            if (tinCard.current && companyInfo.tin.length > 0 && companyInfoFlag) {
                tinCard.current.value = makeTinMask(companyInfo.tin, companyInfo.tinType);
            }
            setCompanyInfoFlag(false);
        },
        // eslint-disable-next-line
        [companyInfoFlag],
    );

    const setMask = () => {
        if ((tinCard.current?.value || tinCard?.current?.value === "") && companyInfo.tin.length === 0) {
            if (companyInfo.tinType === "ein") {
                tinCard.current.value = "__-_______";
                const input = document.getElementById("tin") as any;
                input.focus();
                input.setSelectionRange(0, 0);
            } else if (companyInfo.tinType === "ssn") {
                tinCard.current.value = "___-__-____";
                const input = document.getElementById("tin") as any;
                input.focus();
                input.setSelectionRange(0, 0);
            }
        } else {
            const input = document.getElementById("tin") as any;
            if (input) {
                input.focus();
                const pos = cursorForTin(companyInfo.tin.length, companyInfo.tinType);
                input.setSelectionRange(pos, pos);
            }
        }
    };

    const handleAddressChange = (
        event: React.FormEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>,
    ) => {
        setCompanyInfo({ ...companyInfo, [event.currentTarget.name]: event.currentTarget.value });
    };

    const handleSubmit = (e: any) => {
        e.preventDefault();
        const isCompanyInfoError = companyInfoValidationMessage.length > 0;
        const isUserContactError = userContactValidationMessage.length > 0;
        if (isCompanyInfoError && isUserContactError) {
            setFormMessage(true);
            setUserContactFormMessage(true);
        } else if (isCompanyInfoError && !isUserContactError) {
            setFormMessage(true);
        } else if (!isCompanyInfoError && isUserContactError) {
            setUserContactFormMessage(true);
        } else {
            const companyUserInfo: SupplierOnboardingRecord["companyUserInfo"] = {
                companyInfo,
                contactPerson,
            };
            dispatch(enterCompanyUserInfo(companyUserInfo));
        }
    };

    const handleCompanyInfoBlur = (
        e: React.FocusEvent<HTMLInputElement> | React.FocusEvent<HTMLSelectElement>,
    ) => {
        const name = e.target.name;
        const value = name === "tin" ? e.target.value.replace(/(\D)/g, "") : e.target.value;

        if (companyInfoFormSchema.shape[name as CompanyInfoFormFields].safeParse(value).success) {
            setCompanyInfoValidationMessage(
                companyInfoValidationMessage.filter((message) => message !== e.currentTarget.name),
            );
        } else {
            setCompanyInfoValidationMessage([
                ...companyInfoValidationMessage,
                e.currentTarget.name as CompanyInfoFormFields,
            ]);
            console.log(companyInfoFormSchema.shape[name as CompanyInfoFormFields].safeParse(value));
            console.log(value);
        }
    };

    const isThereCompanyInfoMessage = (
        key: CompanyInfoFormFields, // do not export! bespoke to each form
    ) => (companyInfoValidationMessage.includes(key) ? makeValidationMessageSentence(key) : null);

    return (
        <motion.form initial={{ opacity: 0 }} animate={{ opacity: 1 }} className="space-y-6 lg:mt-10">
            <div className="bg-white px-4 py-5 shadow sm:rounded-lg sm:p-6">
                <div className="md:grid md:grid-cols-3 md:gap-6">
                    <div className="md:col-span-1">
                        <h3 className="text-lg font-medium leading-6 text-gray-900">Company Info</h3>
                        {formMessage && (
                            <ValidationMessage
                                message="You must fill out all fields with appropriate values"
                                noPadding
                            />
                        )}
                    </div>
                    <div className="mt-5 md:col-span-2 md:mt-0">
                        <div className="grid grid-cols-6 gap-6">
                            <div className="col-span-6">
                                <label
                                    htmlFor="companyName"
                                    className="block text-sm font-medium text-gray-700"
                                >
                                    Company name
                                </label>
                                <input
                                    type="text"
                                    name="companyName"
                                    id="companyName"
                                    onChange={handleAddressChange}
                                    onBlur={handleCompanyInfoBlur}
                                    value={companyInfo.companyName}
                                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                />
                                <ValidationMessage message={isThereCompanyInfoMessage("companyName")} />
                            </div>

                            <div className="col-span-6 sm:col-span-3">
                                <label
                                    htmlFor="tin"
                                    className="block text-sm font-medium text-gray-700 divide-x-2 divide-gray-300"
                                >
                                    <span className="pr-1.5">
                                        {companyInfo.tinType === "ein" ? "EIN" : "SSN"}
                                    </span>
                                    <span
                                        className="pl-1.5 text-gray-400 hover:text-gray-600 cursor-pointer"
                                        onClick={changeTinType}
                                    >
                                        {companyInfo.tinType === "ein" ? "SSN" : "EIN"}
                                    </span>
                                </label>
                                <input
                                    type="text"
                                    name="tin"
                                    id="tin"
                                    onClick={setMask}
                                    onChange={handleTinChange}
                                    onBlur={handleCompanyInfoBlur}
                                    ref={tinCard as any} // TODO: fix this
                                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                />
                                <ValidationMessage
                                    message={isThereCompanyInfoMessage("tin")}
                                    tinType={companyInfo.tinType}
                                />
                            </div>

                            <div className="col-span-6 sm:col-span-3">
                                <label htmlFor="country" className="block text-sm font-medium text-gray-700">
                                    Country
                                </label>
                                <select
                                    id="country"
                                    disabled
                                    onChange={handleAddressChange}
                                    onBlur={handleCompanyInfoBlur}
                                    value={companyInfo.country}
                                    className="mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                >
                                    <option value="United States">United States</option>
                                </select>
                                <ValidationMessage message={isThereCompanyInfoMessage("country")} />
                            </div>

                            <div className="col-span-6">
                                <label
                                    htmlFor="streetAddress"
                                    className="block text-sm font-medium text-gray-700"
                                >
                                    Street address
                                </label>
                                <input
                                    type="text"
                                    name="streetAddress"
                                    id="streetAddress"
                                    onChange={handleAddressChange}
                                    onBlur={handleCompanyInfoBlur}
                                    value={companyInfo.streetAddress}
                                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                />
                                <ValidationMessage message={isThereCompanyInfoMessage("streetAddress")} />
                            </div>

                            <div className="col-span-6 sm:col-span-6 lg:col-span-2">
                                <label htmlFor="city" className="block text-sm font-medium text-gray-700">
                                    City
                                </label>
                                <input
                                    type="text"
                                    name="city"
                                    id="city"
                                    onChange={handleAddressChange}
                                    onBlur={handleCompanyInfoBlur}
                                    value={companyInfo.city}
                                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                />
                                <ValidationMessage message={isThereCompanyInfoMessage("city")} />
                            </div>

                            <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                                <label htmlFor="region" className="block text-sm font-medium text-gray-700">
                                    State / Province
                                </label>
                                <select
                                    id="stateOrProvince"
                                    name="stateOrProvince"
                                    className="mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                    value={companyInfo.stateOrProvince}
                                    onChange={handleAddressChange}
                                    onBlur={handleCompanyInfoBlur}
                                >
                                    <option value="">Select state...</option>
                                    {usaStates.map((state) => (
                                        <option key={state} value={state}>
                                            {state}
                                        </option>
                                    ))}
                                </select>
                                <ValidationMessage message={isThereCompanyInfoMessage("stateOrProvince")} />
                            </div>

                            <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                                <label htmlFor="zip" className="block text-sm font-medium text-gray-700">
                                    ZIP / Postal code
                                </label>
                                <input
                                    type="text"
                                    placeholder="ZIP / Postal code (digits only)"
                                    name="zip"
                                    id="zip"
                                    onChange={handleAddressChange}
                                    onBlur={handleCompanyInfoBlur}
                                    value={companyInfo.zip}
                                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                />
                                <ValidationMessage message={isThereCompanyInfoMessage("zip")} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="bg-white px-4 py-5 shadow sm:rounded-lg sm:p-6">
                <div className="md:grid md:grid-cols-3 md:gap-6">
                    <div className="md:col-span-1">
                        <h3 className="text-lg font-medium leading-6 text-gray-900">User Contact</h3>
                        {userContactFormMessage && (
                            <ValidationMessage
                                message="You must fill out all fields with appropriate values"
                                noPadding
                            />
                        )}
                    </div>
                    <div className="mt-5 md:col-span-2 md:mt-0">
                        <div className="grid grid-cols-6 gap-6">
                            <div className="col-span-6 lg:col-span-3">
                                <label
                                    htmlFor="firstName"
                                    className="block text-sm font-medium text-gray-700"
                                >
                                    First Name
                                </label>
                                <input
                                    type="text"
                                    name="firstName"
                                    id="firstName"
                                    onChange={handleContactPersonChange}
                                    onBlur={handleUserContactBlur}
                                    value={contactPerson.firstName}
                                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                />
                                <ValidationMessage message={isThereUserContactMessage("firstName")} />
                            </div>
                            <div className="col-span-6 lg:col-span-3">
                                <label htmlFor="lastName" className="block text-sm font-medium text-gray-700">
                                    Last Name
                                </label>
                                <input
                                    type="text"
                                    name="lastName"
                                    id="lastName"
                                    onChange={handleContactPersonChange}
                                    onBlur={handleUserContactBlur}
                                    value={contactPerson.lastName}
                                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                />
                                <ValidationMessage message={isThereUserContactMessage("lastName")} />
                            </div>
                            <div className="col-span-6 sm:col-span-4">
                                <label
                                    htmlFor="emailAddress"
                                    className="block text-sm font-medium text-gray-700"
                                >
                                    Email address
                                </label>
                                <input
                                    type="email"
                                    name="emailAddress"
                                    id="emailAddress"
                                    onChange={handleContactPersonChange}
                                    onBlur={handleUserContactBlur}
                                    value={contactPerson.emailAddress}
                                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                />
                                <ValidationMessage message={isThereUserContactMessage("emailAddress")} />
                            </div>
                            <div className="col-span-6 sm:col-span-2">
                                <label
                                    htmlFor="phoneNumber"
                                    className="block text-sm font-medium text-gray-700"
                                >
                                    Phone Number
                                </label>
                                <input
                                    type="text"
                                    name="phoneNumber"
                                    id="phoneNumber"
                                    onClick={setUserContactMask}
                                    onChange={handlePhoneNumberChange}
                                    onBlur={handleUserContactBlur}
                                    ref={phoneNumberCard as any} //TODO: fix this
                                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                />
                                <ValidationMessage message={isThereUserContactMessage("phoneNumber")} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex justify-end">
                <button
                    onClick={() => navigate("/")}
                    type="button"
                    className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                >
                    Cancel
                </button>
                <button
                    type="submit"
                    onClick={handleSubmit}
                    className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                >
                    Save
                </button>
            </div>
        </motion.form>
    );
};

export default CompanyUserInfoForm;
