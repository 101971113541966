import { classNames } from "../utils/classNames";
import { useI18n } from "@hi18n/react";
import { book } from "../locale";
import { statusCssClassnames } from "../utils/statusStyles";
import { format } from "date-fns";

interface LoadingListingProps {
    batches?: boolean;
}

const LoadingListing: React.FC<LoadingListingProps> = ({ batches }) => {
    const { t } = useI18n(book);
    const date = format(new Date(), "MM/dd/yyyy");

    return (
        <tr className="bg-white cursor-pointer animate-pulse card">
            {batches && (
                <>
                    <td className="pl-8 py-4 text-left whitespace-nowrap text-sm text-gray-500 border-b border-gray-200 border-spacing-0">
                        {date}
                    </td>
                    <td className="max-w-0 w-full pl-1 py-4 whitespace-nowrap text-sm text-gray-800 border-b border-gray-200 border-spacing-0">
                        <div className="flex">
                            <div className="w-full group inline-flex space-x-2 truncate text-sm">
                                <p className="text-gray-500 truncate group-hover:text-gray-800">
                                    {t("placeholder/header")}
                                </p>
                                <svg className="animate-spin h-5 w-5" viewBox="0 0 24 24">
                                    <circle
                                        className="opacity-0"
                                        cx="12"
                                        cy="12"
                                        r="10"
                                        stroke="white"
                                        strokeWidth="4"
                                    ></circle>
                                    <path
                                        className="opacity-50"
                                        fill="currentColor"
                                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                    ></path>
                                </svg>
                            </div>
                        </div>
                    </td>
                    <td className="pr-6 py-4 text-right whitespace-nowrap text-sm text-gray-500 border-b border-gray-200 border-spacing-0">
                        <span className="text-gray-800 font-medium">{t("placeholder/currency")}</span>
                    </td>
                    <td className="hidden md:flex md:justify-center pr-4 py-4 whitespace-nowrap text-sm text-gray-500  border-b border-gray-200 border-spacing-0">
                        <span
                            className={classNames(
                                statusCssClassnames.processing,
                                "inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium capitalize",
                            )}
                        >
                            <span className="capitalize">{t("placeholder/status")}</span>
                        </span>
                    </td>
                </>
            )}
            {!batches && (
                <>
                    <td className="pl-4 py-4 text-left whitespace-nowrap text-sm text-gray-500 border-b border-gray-200 border-spacing-0">
                        <div className="group inline-flex text-sm">
                            <p className="text-gray-500 font-mono">...</p>
                        </div>
                    </td>
                    <td className="pl-2py-4 text-left whitespace-nowrap text-sm text-gray-500 border-b border-gray-200 border-spacing-0">
                        <div className="w-full group inline-flex space-x-2 truncate text-sm">
                            <p className="text-gray-500 truncate group-hover:text-gray-800">
                                {t("placeholder/header")}
                            </p>
                            <svg className="animate-spin h-5 w-5" viewBox="0 0 24 24">
                                <circle
                                    className="opacity-0"
                                    cx="12"
                                    cy="12"
                                    r="10"
                                    stroke="white"
                                    strokeWidth="4"
                                ></circle>
                                <path
                                    className="opacity-50"
                                    fill="currentColor"
                                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                ></path>
                            </svg>
                        </div>
                        {/* {date} */}
                    </td>
                    <td className="max-w-0 pl-2 py-4 whitespace-nowrap text-sm text-gray-800 border-b border-gray-200 border-spacing-0">
                        <div className="flex justify-end">
                            <div className="flex flex-row justify-end truncate text-sm">
                                <span className="text-gray-800 font-medium">{t("placeholder/currency")}</span>
                            </div>
                        </div>
                    </td>
                    {/* method //TODO: unhide */}
                    {/* <td className="max-w-0 pl-2 py-4 whitespace-nowrap text-sm text-gray-800 border-b border-gray-200 border-spacing-0">
                        <div className="flex">
                            <div className="flex flex-row truncate text-sm">
                                <span className="text-gray-400 font-medium">pending</span>
                            </div>
                        </div>
                    </td> */}
                    <td className="hidden pl-2 py-4 whitespace-nowrap text-sm text-gray-500 md:flex border-b border-gray-200 border-spacing-0">
                        <span
                            className={classNames(
                                statusCssClassnames.processing,
                                "inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium capitalize",
                            )}
                        >
                            <span className="capitalize">{t("placeholder/status")}</span>
                        </span>
                    </td>

                    <td className="pr-4 py-4 text-right whitespace-nowrap text-sm text-gray-500 border-b border-gray-200 border-spacing-0">
                        <div className="group inline-flex justify-right text-sm">
                            <p className="text-gray-500 font-mono">...</p>
                        </div>
                    </td>
                </>
            )}
        </tr>
    );
};

export default LoadingListing;
