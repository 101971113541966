import { useAppSelector } from "../hooks";
import { selectAllBatches } from "../store/batches";
import { selectStatistics } from "../store/statistics";
import { downloadVendorTimeReport, downloadDateReport, downloadLifetimeReport } from "../utils/reporting";
import { CloudArrowDownIcon } from "@heroicons/react/24/outline";
import { useI18n } from "@hi18n/react";
import { book } from "../locale";
import { PrintHeader } from "./PrintHeader";

const ReportsList: React.FC = () => {
    const { t } = useI18n(book);
    const stats = useAppSelector(selectStatistics);
    const batchListings = useAppSelector(selectAllBatches);

    if (batchListings.length === 0) {
        return (
            <div>
                <div className="mt-8 max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
                    <div className="sm:flex sm:items-center">
                        <div className="sm:flex-auto">
                            <h2 className=" text-lg leading-6 font-medium text-gray-800 ">Reports</h2>
                            <p className="mt-2 text-sm text-gray-700">
                                Once payments have been processed, data can be used to generate CSV-formatted
                                reports
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    const reports = [
        {
            title: t("reports/paymentsByDate"),
            type: "CSV",
            desc: "Detail info of all payments, sorted by date.",
            icon: <CloudArrowDownIcon className="inline-block mr-3 h-5 w-5" aria-hidden="true" />,
            action: downloadDateReport,
            href: "#",
        },
        {
            title: t("reports/lifetimeStatistics"),
            type: "CSV",
            desc: "High-level view of lifetime payments statistics.",
            icon: <CloudArrowDownIcon className="inline-block mr-3 h-5 w-5" aria-hidden="true" />,
            action: downloadLifetimeReport,
            href: "#",
        },
        {
            title: t("reports/vendorLifetime"),
            type: "CSV",
            desc: "List of total payments to each vendor, and total value of those payments.",
            icon: <CloudArrowDownIcon className="inline-block mr-3 h-5 w-5" aria-hidden="true" />,
            action: downloadVendorTimeReport,
            href: "#",
        },
    ];

    return (
        <div className="mt-8 print:mt-0">
            <PrintHeader title={t("reports/title")} className="mx-auto px-4 sm:px-6 lg:px-8" />
            {reports.map((report) => (
                <div
                    key={report.desc + report.title}
                    className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8 mt-2 card"
                >
                    <div className="bg-white overflow-hidden shadow rounded-lg">
                        <div className="px-4 py-5 sm:p-6">
                            <h3 className="text-lg font-medium leading-6 text-gray-900">{report.title}</h3>
                            <div className="mt-2 max-w-xl text-sm text-gray-500">
                                <p>{report.desc}</p>
                            </div>
                        </div>
                        <div className="bg-gray-50 px-5 py-3">
                            <div className="text-sm">
                                <a
                                    href={report.href}
                                    className="font-medium text-gray-600 hover:text-gray-800 p-2 rounded-md"
                                    onClick={() => report.action(batchListings, stats)}
                                >
                                    {report.icon}
                                    <span>Download {report.type}</span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
};
export default ReportsList;
