import { XMarkIcon } from "@heroicons/react/24/outline";
import { LinkIcon } from "@heroicons/react/24/solid";
import { PaymentWithDate } from "../store/listings";
import { formatFromCents, tempFormatUuidString } from "../utils";
import { classNames } from "../utils/classNames";
import { useI18n } from "@hi18n/react";
import { book } from "../locale";
import { BatchStatusName } from "@corechain-technologies/types";
import { useNavigate } from "react-router-dom";
import { getStatusCssClassnames } from "../utils/statusStyles";
import { LifeCycleFeed } from "./LifeCycleFeed";
import { useAppSelector } from "../hooks";
import { dismissDetailPane, showDetailPane } from "./DetailPane";
import { selectHideBatchesTable } from "../store/payer";

export const CloseOverlay: React.FC = () => {
    const navigate = useNavigate();
    return (
        <div className="ml-3 flex h-7 items-center">
            <button
                type="button"
                className="rounded-md bg-white text-gray-400 hover:text-gray-500"
                onClick={() => navigate(dismissDetailPane())}
            >
                <span className="sr-only">Close panel</span>
                <XMarkIcon className="h-6 w-6" aria-hidden="true" />
            </button>
        </div>
    );
};
interface PaymentDetailHeaderProps {
    payment: PaymentWithDate;
    currentStatus: BatchStatusName;
}

export const PaymentDetailHeader: React.FC<PaymentDetailHeaderProps> = ({ payment, currentStatus }) => {
    const { t } = useI18n(book);
    return (
        <div className="group text-lg font-medium cursor-pointer">
            <span className="group-hover:text-gray-700 text-gray-500 font-normal">
                {t("paymentDetail/paymentTo") + " "}
            </span>
            <span className="group-hover:text-gray-800 text-gray-600">
                {payment?.recipientName.toString()}
                <LinkIcon className="stroke-gray-500 group-hover:stroke-gray-700 inline-flex self-top ml-1 h-4 w-4" />
            </span>
            <div className="flex items-center justify-left gap-2 pt-2">
                <div
                    className={classNames(
                        getStatusCssClassnames(currentStatus),
                        "inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium capitalize",
                    )}
                >
                    <span className="capitalize">{payment?.currentStatus.toLowerCase()}</span>
                </div>
            </div>
        </div>
    );
};

interface PaymentDetailProps {
    payment: PaymentWithDate;
}

export const PaymentDetail: React.FC<PaymentDetailProps> = ({ payment }) => {
    const { t } = useI18n(book);
    const navigate = useNavigate();
    const hideBatches = useAppSelector(selectHideBatchesTable);

    return (
        <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
            <dl className="sm:divide-y sm:divide-gray-200">
                <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                    <dt className="text-sm font-medium text-gray-500">
                        {t("batchDetail/originatingAccount")}{" "}
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                        {payment?.originName.toString()}
                    </dd>
                </div>
                <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                    <dt className="text-sm font-medium text-gray-500">{t("batchDetail/totalAmount")}</dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                        {formatFromCents(payment?.amount.toString() ?? "")}
                    </dd>
                </div>
                <div
                    className={classNames(
                        "group py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6",
                        !hideBatches && "hover:bg-gray-50 cursor-pointer",
                    )}
                    onClick={() =>
                        !hideBatches &&
                        navigate(showDetailPane("#detail/batch/", tempFormatUuidString(payment.batchId)))
                    }
                >
                    <dt className="text-sm font-medium text-gray-500">
                        {t("paymentDetail/originalBatchId")}{" "}
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                        {tempFormatUuidString(payment.batchId)}
                        {!hideBatches && (
                            <LinkIcon className="stroke-gray-500 group-hover:stroke-gray-700 inline-flex self-top ml-1 h-4 w-4" />
                        )}
                    </dd>
                </div>
                <LifeCycleFeed events={payment ? payment.events : []} />
            </dl>
        </div>
    );
};
