import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import Feature from "./Feature";
import { useI18n } from "@hi18n/react";
import { book } from "../locale";
import { useNavigate } from "react-router-dom";

const Search = () => {
    const { t } = useI18n(book);
    const navigate = useNavigate();

    return (
        <div className="flex-1 flex">
            <Feature flag="DEPLOY_TARGET" value="DEVELOPMENT">
                <form
                    action="#"
                    method="GET"
                    className="flex w-full md:ml-0 p-3"
                    onSubmit={() => navigate("search")}
                >
                    <label htmlFor="search-field" className="sr-only">
                        Search
                    </label>
                    <div className="relative w-full text-gray-400 focus-within:text-gray-600">
                        <div
                            className="pointer-events-none absolute inset-y-0 left-0 flex items-center"
                            aria-hidden="true"
                        >
                            <MagnifyingGlassIcon className="h-5 w-10" aria-hidden="true" />
                        </div>
                        <input
                            id="search-field"
                            name="search-field"
                            className="block h-full w-full border-gray-200 bg-white focus:bg-gray-50 pl-10 pr-3 rounded-md text-gray-900 sm:text-sm"
                            placeholder={t("search/placeholder")}
                            type="search"
                        />
                    </div>
                </form>
            </Feature>
        </div>
    );
};

export default Search;
