import { classNames } from "../utils/classNames";
import { useI18n } from "@hi18n/react";
import { book } from "../locale";
import { Navigate, NavLink, useMatch, useNavigate } from "react-router-dom";
import { useAppSelector } from "../hooks";
import { selectPayer } from "../store/payer";

const Tabs: React.FC = () => {
    const { t } = useI18n(book);

    const match = useMatch({ path: "/:payerName/app/payments/:tab/*" });
    const navigate = useNavigate();
    const payer = useAppSelector(selectPayer);

    const navigateToPath = (path: string) => {
        navigate(path);
    };

    if (!payer) {
        throw new Error("No payer");
    }

    if (!match) {
        return <Navigate to={`/${payer.name}/app/payments/payment-sets`} replace />;
    }

    const tabs = payer
        ? [
              {
                  name: t("tabs/paymentSets"),
                  href: `/${payer.name}/app/payments/payment-sets`,
              },
              {
                  name: t("tabs/paymentsIndividual"),
                  href: `/${payer.name}/app/payments/payments-individual`,
              },
          ]
        : [];

    return (
        <div className="mt-6 mb-1 print:hidden">
            <div className="seven12:hidden">
                <label htmlFor="tabs" className="sr-only">
                    Select a tab
                </label>
                {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
                <select
                    id="tabs"
                    name="tabs"
                    onChange={(e) => navigateToPath(e.target.value)}
                    className="block w-full rounded-md border-gray-300 mt-0.5"
                >
                    {tabs.map((tab, i) => (
                        <option value={tab.href} key={tab.name + tab.href + i}>
                            {tab.name}
                        </option>
                    ))}
                </select>
            </div>
            <div className="hidden seven12:block max-w-6xl mx-auto px-4 seven12:px-6 lg:px-8">
                <nav className="flex" aria-label="Tabs">
                    {tabs.map((tab, tabIdx) => (
                        <NavLink
                            key={`${tabIdx} + ${tab.href}`}
                            to={tab.href}
                            className={(props: { isActive: boolean }) => {
                                return classNames(
                                    props.isActive
                                        ? "text-gray-800 border-b-2 border-gray-700"
                                        : "text-gray-500 border-b-2 border-transparent hover:text-gray-900 focus:text-gray-900",
                                    "group cursor-pointer px-3 py-2 font-medium text-sm",
                                );
                            }}
                        >
                            <span>{tab.name}</span>
                        </NavLink>
                    ))}
                </nav>
            </div>
        </div>
    );
};

export default Tabs;
