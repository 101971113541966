import { CreditCardIcon, CurrencyDollarIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { formatFromCents } from "../utils";
import { classNames } from "../utils/classNames";
import { getStatusCssClassnames } from "../utils/statusStyles";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppSelector } from "../hooks";
import { selectAllBatches } from "../store/batches";
import { getPaymentsByVendorData, TempVendorFields } from "../utils/formatChartData";
import { vendorStatusStyles } from "./VendorList";
import { BatchStatusName } from "@corechain-technologies/types";
import { useI18n } from "@hi18n/react";
import { book } from "../locale";
import { dismissDetailPane, showDetailPane } from "./DetailPane";

export type PaymentToVendorHistory = {
    amount: number;
    date: string;
    method: string;
    status: BatchStatusName;
    vendorPaymentId: string;
    payer: string;
};

const VendorPaymentHistory: React.FC = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { t } = useI18n(book);
    const batchListings = useAppSelector(selectAllBatches);
    const paymentsByVendorData: TempVendorFields[] = getPaymentsByVendorData(batchListings);
    const selectedVendorData =
        paymentsByVendorData
            .filter((vendor) => location.hash.includes(encodeURI(vendor.companyName)))
            .sort((a, b) => Number(new Date(b.datePaid)) - Number(new Date(a.datePaid))) ?? null;

    let companyName = "no data";
    let companyStatus;
    let companyFirstPaid = "";
    let totalPayments = 0;
    let totalValue = 0;
    let paymentsToVendorHistory: PaymentToVendorHistory[] = [];
    let vendorStatusStyle = "";

    if (selectedVendorData.length > 0) {
        companyName = selectedVendorData[0].companyName ?? "no data";
        companyStatus = selectedVendorData[0].status;
        companyFirstPaid = selectedVendorData[0].datePaid;
        totalPayments = selectedVendorData.length;
        paymentsToVendorHistory = selectedVendorData.map((vendorData) => {
            return {
                amount: vendorData.amountPaid,
                date: vendorData.datePaid,
                method: vendorData.method,
                status: vendorData.paymentStatus,
                vendorPaymentId: vendorData.vendorPaymentId,
                payer: vendorData.payer,
            };
        });
        totalValue = paymentsToVendorHistory.reduce(
            (acc, cur: PaymentToVendorHistory) => acc + cur.amount,
            0,
        );
        vendorStatusStyle = companyStatus;
    }

    if (companyName === "no data") {
        navigate("/404", { replace: true });
        return;
    }

    return (
        <>
            <div className="px-4 sm:px-6">
                <div className="flex items-start justify-between">
                    <div className="text-lg font-medium text-gray-900 ">
                        {companyName}
                        <div className="flex items-center justify-left gap-2 pt-2">
                            <div
                                className={classNames(
                                    vendorStatusStyles[vendorStatusStyle as keyof typeof vendorStatusStyles],
                                    "inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium capitalize",
                                )}
                            >
                                <span className="capitalize">{companyStatus}</span>
                            </div>
                        </div>
                    </div>

                    <div className="ml-3 flex h-7 items-center">
                        <button
                            type="button"
                            className="rounded-md bg-white text-gray-400 hover:text-gray-500"
                            onClick={() => navigate(dismissDetailPane())}
                        >
                            <span className="sr-only">Close panel</span>
                            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                        </button>
                    </div>
                </div>
            </div>
            <div className="relative bg-gray-200 mt-4 flex-1 px-4 sm:px-6 overflow-scroll">
                <div className="absolute inset-0 pt-5 pb-6 px-4 sm:px-6">
                    <div
                        className="h-full border overflow-y-scroll bg-white shadow border-gray-200 sm:rounded-lg"
                        aria-hidden="true"
                    >
                        <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
                            <dl className="sm:divide-y sm:divide-gray-200">
                                <div className="py-4 px-4 flex gap-1 sm:py-5 sm:px-6">
                                    <span className="text-sm text-gray-900">
                                        {totalPayments.toLocaleString()}
                                    </span>
                                    <span className="text-sm font-medium text-gray-500">
                                        {totalPayments === 1
                                            ? t("vendorPaymentHistory/paymentWithTotalValue")
                                            : t("vendorPaymentHistory/paymentsWithTotalValue")}
                                    </span>
                                    <span className="text-sm text-gray-900">
                                        {formatFromCents(totalValue ?? "")}
                                    </span>
                                </div>
                                <div className="py-4 sm:py-5 sm:px-6">
                                    <dd className="mt-1 text-sm text-gray-700 sm:col-span-2 sm:mt-0">
                                        <ul className="divide-y divide-gray-200 rounded-md border border-gray-200">
                                            {paymentsToVendorHistory.map((payment, i) => {
                                                const currentStatus = payment.status;
                                                return (
                                                    <li
                                                        key={i + payment.toString()}
                                                        className="group flex items-center justify-between py-3 pl-3 pr-4 text-sm bg-white hover:bg-gray-50 cursor-pointer"
                                                        onClick={() =>
                                                            navigate(
                                                                showDetailPane(
                                                                    "#detail/payment/",
                                                                    payment.vendorPaymentId,
                                                                ),
                                                            )
                                                        }
                                                    >
                                                        <div className="flex w-0 flex-1">
                                                            <span className="w-0 flex-1 capitalize truncate group-hover:text-gray-900">
                                                                {payment.date}
                                                            </span>
                                                        </div>
                                                        <div className="flex w-0 flex-1 justify-end group-hover:text-gray-900">
                                                            <span className="w-0 flex-1 truncate text-right">
                                                                {formatFromCents(payment.amount)}
                                                            </span>
                                                        </div>
                                                        <div className="flex w-0 flex-1 justify-end group-hover:text-gray-900">
                                                            {/* TODO - Enable wcolumn for payment type when integration for multiple payment types is completed */}
                                                            <div className="flex justify-left gap-1">
                                                                <div className="flex justify-center">
                                                                    {payment.method === "ACH" && (
                                                                        <CurrencyDollarIcon
                                                                            className="flex-shrink-0 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                                                                            aria-hidden="true"
                                                                        />
                                                                    )}
                                                                    {payment.method === "Card" && (
                                                                        <CreditCardIcon
                                                                            className="flex-shrink-0 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                                                                            aria-hidden="true"
                                                                        />
                                                                    )}
                                                                </div>
                                                                <div className="ml-1 flex-shrink-0">
                                                                    {payment.method}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="flex w-0 flex-1 justify-end">
                                                            <span
                                                                className={classNames(
                                                                    getStatusCssClassnames(currentStatus),
                                                                    "inline-flex justify-center px-2.5 py-0.5 rounded-full text-xs font-medium capitalize",
                                                                )}
                                                            >
                                                                <span className="capitalize">
                                                                    {payment.status}
                                                                </span>
                                                            </span>
                                                        </div>
                                                    </li>
                                                );
                                            })}
                                        </ul>
                                    </dd>
                                </div>
                            </dl>
                        </div>
                    </div>
                </div>

                {/* /End replace */}
            </div>
        </>
    );
};

export default VendorPaymentHistory;
