import { User } from "@corechain-technologies/types";
import { Reducer } from "redux";
import { RootState } from ".";
import { createAction } from "../utils/createAction";
import { createSelector } from "reselect";
import { z } from "zod";

export const PayerState = User.shape.payers.element.omit({ hideBatchesTable: true }).nullable();

export type PayerState = z.infer<typeof PayerState>;

const initialPayerState: PayerState = null;

export const setPayer = createAction("PAYER:SET", (payer: PayerState) => payer);

export const selectPayer = createSelector(
    (state: Pick<RootState, "payer">) => state.payer,
    (payer) => payer,
);

export const selectChannelPartner = createSelector(
    (state: Pick<RootState, "payer">) => state.payer,
    (payer) => payer?.channelPartner,
);

export const payerReducer: Reducer<PayerState> = (state = initialPayerState, action) => {
    if (setPayer.match(action)) {
        return action.payload ? { ...state, ...action.payload } : state;
    } else {
        return state;
    }
};

export const selectHideBatchesTable = createSelector(
    (state: Pick<RootState, "payer" | "user">) => ({ payer: state.payer, user: state.user.cxUser }),
    ({ payer, user }) => {
        if (payer && user && user.payers[0]) {
            const currentPayer = user.payers.filter((currentPayer) => currentPayer.id === payer.id)[0];
            return currentPayer?.hideBatchesTable ?? true;
        }
        return true;
    },
);
