import RecentPayments from "../components/RecentPayments";
import Header from "../components/Header";
import InsightCards from "../components/InsightCards";
import PaymentsTimeline from "../components/PaymentsTimeline";
import ComparisonCards from "../components/ComparisonCards";
import { Outlet } from "react-router-dom";
import { useAppSelector } from "../hooks";
import { Role as UserRole } from "@corechain-technologies/types";
import { selectAllFeatureValues } from "../store/features";
import { selectHideBatchesTable } from "../store/payer";
import RecentBatches from "../components/RecentBatches";
import { selectAllBatches } from "../store/batches";
import { selectPayments } from "../store/payments";

const BuyerContent = () => {
    const hideBatchesTable = useAppSelector(selectHideBatchesTable);
    const dataSource = useAppSelector(selectAllBatches);
    const paymentSet = useAppSelector(selectPayments);
    return (
        <div className="mt-8">
            <div className="hidden sm:block">
                <PaymentsTimeline
                    minDataRange={10}
                    maxDataRange={30}
                    dataSource={dataSource}
                    paymentSet={paymentSet}
                    isPayer={true}
                />
            </div>
            <div className="sm:hidden">
                <PaymentsTimeline
                    minDataRange={10}
                    maxDataRange={10}
                    dataSource={dataSource}
                    paymentSet={paymentSet}
                    isPayer={true}
                />
            </div>
            <InsightCards userRole={UserRole.BUYER} />
            <ComparisonCards userRole={UserRole.BUYER} />
            {!hideBatchesTable && <RecentBatches />}
            {hideBatchesTable && <RecentPayments />}
        </div>
    );
};

// const SupplierContent = () => {
//     const dataSource: PaymentWithDate[] = useAppSelector(selectAllPayments).filter(
//         (payment) => payment.recipientName === "ALMA",
//     );
//     const dataSet = formatSortedPayeePaymentsByMethod(dataSource);
//     return (
//         <div className="mt-8">
//             <div className="hidden sm:block">
//                 <PaymentsTimeline
//                     minDataRange={10}
//                     maxDataRange={30}
//                     dataSource={dataSource}
//                     dataSet={dataSet}
//                     isPayer={false}
//                 />
//             </div>
//             <div className="sm:hidden">
//                 <PaymentsTimeline
//                     minDataRange={10}
//                     maxDataRange={10}
//                     dataSource={dataSource}
//                     dataSet={dataSet}
//                     isPayer={false}
//                 />
//             </div>
//             <InsightCards userRole={UserRole.SUPPLIER} />
//             <ComparisonCards userRole={UserRole.SUPPLIER} />
//             <RecentReceivables />
//         </div>
//     );
// };

const HomeContent = () => {
    const features = useAppSelector(selectAllFeatureValues);
    const userRole: UserRole = features.USER_ROLE === "BUYER" ? UserRole.BUYER : UserRole.SUPPLIER;

    return (
        <>
            <Outlet />
            <Header />
            {userRole === UserRole.BUYER && <BuyerContent />}
            {/* <Feature flag="DEPLOY_TARGET" value="DEVELOPMENT">
                <>{userRole === UserRole.SUPPLIER && <SupplierContent />}</>
            </Feature> */}
        </>
    );
};

const Home: React.FC = () => {
    return <HomeContent />;
};

export default Home;
