import { motion } from "framer-motion";
import { TaxpayerIdentificationNumberType } from "../store/supplierOnboarding";

interface ValidationMessageProps {
    message: string | null;
    big?: boolean;
    noPadding?: boolean;
    tinType?: TaxpayerIdentificationNumberType;
}

const ValidationMessage: React.FC<ValidationMessageProps> = ({
    message,
    big = false,
    noPadding = false,
    tinType = "ein",
}) => {
    if (!message) {
        return <></>;
    }
    let display = message;

    if (message.split(" ")[1] === "tin") {
        if (tinType === "ein") {
            display = "Invalid EIN";
        }
        if (tinType === "ssn") {
            display = "Invalid SSN";
        }
    }

    const fontSize = big ? "text-md" : "text-sm";
    const padding = noPadding ? "" : "pl-2";
    return (
        <div className="relative">
            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                className={`text-red-500 absolute ${padding} ${fontSize}`}
            >
                {display}
            </motion.div>
        </div>
    );
};

export default ValidationMessage;
