import { useMatch, useNavigate } from "react-router-dom";
import ActivityTable from "../components/PaymentsTable";
import UploadBox from "../components/UploadBox";
import { useAppSelector } from "../hooks";
import { selectHideBatchesTable } from "../store/payer";
import { useEffect } from "react";
import { selectPayer } from "../store/payer";

const Payments: React.FC = () => {
    const navigate = useNavigate();
    const hideBatchesTable = useAppSelector(selectHideBatchesTable);
    const match = useMatch({ path: "/:payerName/app/payments" });
    const payer = useAppSelector(selectPayer);

    useEffect(() => {
        if (payer && match && hideBatchesTable) {
            navigate(`/${payer.id}/app/payments/payments-individual`);
        }
    }, []);

    return (
        <>
            {!hideBatchesTable && <UploadBox path="/batches/upload" />}
            <ActivityTable />
        </>
    );
};

export default Payments;
