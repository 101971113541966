import currency from "currency.js";
import { Reporter } from "io-ts/Reporter";
import * as E from "fp-ts/Either";
import { TaxpayerIdentificationNumberType } from "../store/supplierOnboarding";
import type { VocabularyName } from "../locale";

export function tempFormatUuidString(id: string): string {
    if (id.includes("-")) return id.toLowerCase() as any;

    return [
        id.substring(0, 8),
        id.substring(8, 12),
        id.substring(12, 16),
        id.substring(16, 20),
        id.substring(20, 32),
    ]
        .join("-")
        .toLowerCase() as any;
}

export function getVocabularyName(loc?: Location): VocabularyName {
    const location = loc ?? window.location;
    if (location.hostname.startsWith("scanco")) {
        return "scanco";
    }

    return "corechain";
}

export function resolveUrl(from: string | URL, to: string | URL) {
    const resolvedUrl = new URL(to, new URL(from, "resolve://"));
    if (resolvedUrl.protocol === "resolve:") {
        // `from` is a relative URL.
        const { pathname, search, hash } = resolvedUrl;
        return pathname + search + hash;
    }
    return resolvedUrl.toString();
}

export function parseJsonWithBigInt(input: string): unknown {
    return JSON.parse(input, (k, v) => (typeof v === "bigint" ? String(v) : v));
}

export function formatFromCents(cents: number | string): string {
    const prefs = {
        fromCents: true,
        symbol: "",
        separator: ",",
        decimal: ".",
        precision: 2,
    };

    return currency(cents, prefs).format();
}

export const camelCaseToFirstUpperCaseWithSpaces = (keyName: string) => {
    const stringified = keyName
        .split(/(?=[A-Z])/)
        .map((string) => string.charAt(0).toLowerCase() + string.slice(1) + " ")
        .toString()
        .replaceAll(",", "");
    const removeFinalSpace =
        stringified.slice(stringified.length - 1, stringified.length) === " "
            ? stringified.slice(0, stringified.length - 1)
            : stringified;
    const upperCaseFirstWord = removeFinalSpace.slice(0, 1).toUpperCase() + removeFinalSpace.slice(1);
    return upperCaseFirstWord;
};

export const makeValidationMessageSentence = (keyName: string) => {
    const stringified = keyName
        .split(/(?=[A-Z])/)
        .map((string) => string.charAt(0).toLowerCase() + string.slice(1) + " ")
        .toString()
        .replaceAll(",", "");
    const removeFinalSpace =
        stringified.slice(stringified.length - 1, stringified.length) === " "
            ? stringified.slice(0, stringified.length - 1)
            : stringified;
    return `Invalid ${removeFinalSpace}`;
};

export const frontEndIotsReporter: Reporter<string[]> = {
    report: (validation): string[] => {
        if (E.isRight(validation)) {
            return validation.right;
        } else {
            return validation.left.map((a) => a.context[0].type.name);
        }
    },
};

export function maskNumbers(input: string, numbersRevealed: number = 0) {
    // non-interactive masking
    const split = input.split("").map((char, index, og) => {
        if (
            char === "(" ||
            char === ")" ||
            char === "-" ||
            char === " " ||
            index > og.length - (numbersRevealed + 1)
        ) {
            return char;
        } else {
            return "*";
        }
    });
    return split.join("");
}

// supplier onboarding form helpers
export function makeTinMask(input: string, tinType: TaxpayerIdentificationNumberType): string {
    if (tinType === "ein") {
        const split = input.replace(/\D/g, "").match(/(\d{0,2})(\d{0,9})/);

        if (split) {
            const d1 = split[1][0] || "_";
            const d2 = split[1][1] || "_";

            const d3 = split[2][0] || "_";
            const d4 = split[2][1] || "_";
            const d5 = split[2][2] || "_";
            const d6 = split[2][3] || "_";
            const d7 = split[2][4] || "_";
            const d8 = split[2][5] || "_";
            const d9 = split[2][6] || "_";

            const masked = `${d1}${d2}-${d3}${d4}${d5}${d6}${d7}${d8}${d9}`;
            return masked;
        }
    } else if (tinType === "ssn") {
        const split = input.replace(/\D/g, "").match(/(\d{0,3})(\d{0,2})(\d{0,4})/);

        if (split) {
            const d1 = split[1][0] || "_";
            const d2 = split[1][1] || "_";
            const d3 = split[1][2] || "_";

            const d4 = split[2][0] || "_";
            const d5 = split[2][1] || "_";

            const d6 = split[3][0] || "_";
            const d7 = split[3][1] || "_";
            const d8 = split[3][2] || "_";
            const d9 = split[3][3] || "_";

            const masked = `${d1}${d2}${d3}-${d4}${d5}-${d6}${d7}${d8}${d9}`;
            return masked;
        }
    }
    return "";
}
// tin mask cursor helpers
export function cursorForTin(length: number, tinType: TaxpayerIdentificationNumberType) {
    if (tinType === "ein") {
        if (length === 1) return 1;
        if (length === 2) return 2;

        if (length === 3) return 4;
        if (length === 4) return 5;
        if (length === 5) return 6;
        if (length === 6) return 7;
        if (length === 7) return 8;
        if (length === 8) return 9;
        if (length === 9) return 10;
    } else if (tinType === "ssn") {
        if (length === 1) return 1;
        if (length === 2) return 2;
        if (length === 3) return 3;

        if (length === 4) return 5;
        if (length === 5) return 6;

        if (length === 6) return 8;
        if (length === 7) return 9;
        if (length === 8) return 10;
        if (length === 9) return 11;
    }
    return 0;
}
// phone number mask cursor helpers
export function makePhoneNumberMask(input: string): string {
    const split = input.replace(/\D/g, "").match(/(\d{0,3})(\d{0,3})(\d{0,4})/);

    if (split) {
        const d1 = split[1][0] || "_";
        const d2 = split[1][1] || "_";
        const d3 = split[1][2] || "_";

        const d4 = split[2][0] || "_";
        const d5 = split[2][1] || "_";
        const d6 = split[2][2] || "_";

        const d7 = split[3][0] || "_";
        const d8 = split[3][1] || "_";
        const d9 = split[3][2] || "_";
        const d10 = split[3][3] || "_";

        const masked = `(${d1}${d2}${d3}) ${d4}${d5}${d6}-${d7}${d8}${d9}${d10}`;
        return masked;
    } else return "";
}

export function cursorForPhoneNumber(length: number) {
    if (length === 1) return 2;
    if (length === 2) return 3;
    if (length === 3) return 4;

    if (length === 4) return 7;
    if (length === 5) return 8;
    if (length === 6) return 9;

    if (length === 7) return 11;
    if (length === 8) return 12;
    if (length === 9) return 13;
    if (length === 10) return 14;
    return 0;
}

//supplier onboarding form fast-forward object literals
// const fastForward = {
//     companyName: "i",
//     country: "United States",
//     streetAddress: "i@i.i",
//     city: "i",
//     tin: "111111111",
//     tinType: "ein" as TaxpayerIdentificationNumberType,
//     stateOrProvince: "Alabama",
//     zip: "00000",
// };
// const fastForward = {
//     firstName: "i",
//     lastName: "i",
//     emailAddress: "i@i.i",
//     phoneNumber: "1234567890",
// };
// const fastForward = {
//     nameOnAccount: "i",
//     nameOfBank: "i",
//     accountType: "businessSavings" as const,
//     accountNumber: "0",
//     routingNumber: "0",
// };
