import App from "./App";
import { LoaderFunction, Navigate, createBrowserRouter, useRouteLoaderData } from "react-router-dom";
import Home from "./pages/Home";
import Payments from "./pages/Payments";
import { RequireAuth } from "./components/RequireAuth";
import Settings from "./pages/Settings";
import Feature from "./components/Feature";
import ManageVendors from "./pages/Vendors";
import PaymentsTable from "./components/IndividualPaymentsTable";
import BatchesTable from "./components/BatchesTable";
import * as authConfig from "./authConfig";
import SearchResults from "./pages/SearchResults";
import PublicLandingPage from "./pages/PublicLanding";
import Receivables from "./pages/Receivables";
import PayerProfiles from "./pages/PayerProfiles";
import SupplierOnboarding from "./components/SupplierOnboarding";
import CompanyUserInfoForm from "./components/CompanyUserInfoForm";
import PaymentMethodForm from "./components/PaymentMethodForm";
import CompleteFormCard from "./components/CompleteFormCard";
import Reports from "./pages/Reports";
import PaymentInfoForm from "./components/PaymentInfoForm";
import { trpc } from "./utils/trpc";
import { selectPayer, setPayer, type PayerState } from "./store/payer";
import { useAppDispatch, useAppSelector } from "./hooks";
import { useEffect } from "react";
import { ErrorPage } from "./pages/ErrorPage";

const payerLoader = (async ({ params }): Promise<NonNullable<PayerState>> => {
    if (params.payerNameOrId) {
        const result = await trpc.payers.getPayerFromShortNameOrId.query(params.payerNameOrId);
        return {
            ...result.payer,
            channelPartner: result.channelPartner,
        };
    }
    throw new Error("Error loading payer");
}) satisfies LoaderFunction;

export const router = createBrowserRouter([
    {
        errorElement: <ErrorPage code="404" />,
    },
    {
        path: "/",
        element: <PublicLandingPage />,
    },
    {
        path: "/:payerNameOrId",
        element: <Navigate to="app" replace />,
    },
    {
        id: "root",
        path: "/:payerNameOrId",
        loader: payerLoader,
        errorElement: <ErrorPage code="404" />,
        children: [
            {
                path: "app",
                Component: () => {
                    const data = useRouteLoaderData("root") as Awaited<ReturnType<typeof payerLoader>>;
                    const payer = useAppSelector(selectPayer);
                    const dispatch = useAppDispatch();

                    useEffect(() => {
                        if (data && payer?.id !== data.id) {
                            dispatch(setPayer(data));
                        }
                    }, [data, payer, dispatch]);

                    if (!payer?.id) {
                        return <></>;
                    } else {
                        return (
                            <RequireAuth payerId={payer.id}>
                                <App />
                            </RequireAuth>
                        );
                    }
                },
                children: [
                    { index: true, element: <Home /> },
                    {
                        path: "payments",
                        element: <Payments />,
                        children: [
                            {
                                path: "payment-sets",
                                element: <BatchesTable />,
                            },
                            {
                                path: "payments-individual",
                                element: <PaymentsTable />,
                            },
                        ],
                    },
                    {
                        path: "vendors",
                        element: (
                            <Feature flag="DEPLOY_TARGET" value="DEVELOPMENT" redirect="..">
                                <ManageVendors />
                            </Feature>
                        ),
                    },
                    {
                        path: "receivables",
                        element: (
                            <Feature flag="DEPLOY_TARGET" value="DEVELOPMENT" redirect="/app">
                                <Receivables />
                            </Feature>
                        ),
                    },
                    {
                        path: "payers",
                        element: (
                            <Feature flag="DEPLOY_TARGET" value="DEVELOPMENT" redirect="/app">
                                <PayerProfiles />
                            </Feature>
                        ),
                    },
                    {
                        path: "search",
                        element: (
                            <Feature flag="DEPLOY_TARGET" value="DEVELOPMENT" redirect="/app">
                                <SearchResults />
                            </Feature>
                        ),
                    },
                    {
                        path: "reports",
                        element: <Reports />,
                    },
                    {
                        path: "settings",
                        element: (
                            <Feature flag="DEPLOY_TARGET" value="DEVELOPMENT" redirect="/app">
                                <Settings />
                            </Feature>
                        ),
                    },
                    {
                        path: "debug",
                        element: (
                            <section>
                                <h1>authConfig</h1>
                                <pre>{JSON.stringify(authConfig, null, 4)}</pre>
                            </section>
                        ),
                    },
                    {
                        path: "supplier-onboarding",
                        element: (
                            <Feature flag="USER_ROLE" value="SUPPLIER" redirect="/app">
                                <SupplierOnboarding />
                            </Feature>
                        ),
                        children: [
                            {
                                path: "company-user-info",
                                element: <CompanyUserInfoForm />,
                            },
                            {
                                path: "payment-method",
                                element: <PaymentMethodForm />,
                            },
                            {
                                path: "payment-info",
                                element: <PaymentInfoForm />,
                            },
                            {
                                path: "complete",
                                element: <CompleteFormCard />,
                            },
                        ],
                    },
                ],
            },
        ],
    },
]);
