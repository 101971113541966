import DisplayVendors from "../components/VendorList";
import UploadBox from "../components/UploadBox";
import Feature from "../components/Feature";

const ManageVendors: React.FC = () => {
    return (
        <>
            <UploadBox path="/vendors/upload" />
            <Feature flag="DEPLOY_TARGET" value="DEVELOPMENT">
                <DisplayVendors />
            </Feature>
        </>
    );
};

export default ManageVendors;
