import { useState } from "react";
import { useAppDispatch, useAppSelector } from "../hooks";
import {
    enterPaymentMethod,
    jumpToCompanyUserInfo,
    PaymentMethod,
    selectCurrentFormData,
} from "../store/supplierOnboarding";
import { motion } from "framer-motion";

const PaymentMethodForm = () => {
    const dispatch = useAppDispatch();
    const currentState = useAppSelector(selectCurrentFormData).paymentMethod;
    const [method, setMethod] = useState<PaymentMethod>(currentState);
    const handleSubmit = (e: any) => {
        e.preventDefault();
        dispatch(enterPaymentMethod(method));
    };

    const handleCardChange = () => setMethod("card");
    const handleAchChange = () => setMethod("ach");

    return (
        <motion.form initial={{ opacity: 0 }} animate={{ opacity: 1 }} className="space-y-6 lg:mt-10">
            <div className="shadow sm:overflow-hidden sm:rounded-md">
                <div className="space-y-6 bg-white py-6 px-4 sm:p-6">
                    <div>
                        <h3 className="text-lg font-medium leading-6 text-gray-900">Payment Method</h3>
                        <p className="mt-1 text-sm text-gray-500">How would you like to be paid?</p>
                    </div>

                    <fieldset className="mt-6">
                        <div className="mt-4 space-y-4">
                            <div className="flex items-center">
                                <input
                                    id="push-everything"
                                    name="push-notifications"
                                    type="radio"
                                    checked={method === "card"}
                                    onChange={handleCardChange}
                                    className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                />
                                <label htmlFor="push-everything" className="ml-3">
                                    <span className="block text-sm font-medium text-gray-700">
                                        Virtual Card
                                    </span>
                                </label>
                            </div>
                            <div className="flex items-center">
                                <input
                                    id="push-email"
                                    name="push-notifications"
                                    type="radio"
                                    checked={method === "ach"}
                                    onChange={handleAchChange}
                                    className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                />
                                <label htmlFor="push-email" className="ml-3">
                                    <span className="block text-sm font-medium text-gray-700">
                                        ACH / Direct Deposit
                                    </span>
                                </label>
                            </div>
                        </div>
                    </fieldset>
                </div>
                <div className="bg-gray-50 px-4 py-3 text-right sm:px-6">
                    <button
                        onClick={() => dispatch(jumpToCompanyUserInfo())}
                        type="button"
                        className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    >
                        Go Back
                    </button>
                    <button
                        type="submit"
                        onClick={(e) => handleSubmit(e)}
                        className="inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 ml-3 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2"
                    >
                        Save
                    </button>
                </div>
            </div>
        </motion.form>
    );
};

export default PaymentMethodForm;
