import { ReadonlyRecord } from "fp-ts/ReadonlyRecord";
import { Reducer } from "redux";

export type HttpMethod = "GET" | "POST" | "PUT" | "DELETE" | "PATCH" | "OPTIONS" | "HEAD";

export type Service = Readonly<{
    pathName: string;
    allowedMethods: ReadonlyArray<HttpMethod>;
}>;

export type ServicesState = ReadonlyRecord<string, Service>;

export const initialServicesState: ServicesState = {
    user: { pathName: "user", allowedMethods: ["GET"] },
    batches: { pathName: "batches", allowedMethods: ["GET"] },
    receipts: { pathName: "vendor/receipts", allowedMethods: ["GET"] },
    statistics: { pathName: "statistics", allowedMethods: ["GET"] },
};

export const servicesReducer: Reducer<ServicesState> = (state = initialServicesState) => {
    return state;
};
