import { Fragment, useEffect, useState } from "react";
import { Transition } from "@headlessui/react";
import { CheckCircleIcon, ExclamationCircleIcon, InformationCircleIcon } from "@heroicons/react/24/outline";
import { XMarkIcon } from "@heroicons/react/20/solid";
import { NotificationCx, removeNotification } from "../store/notifications";
import { Uuid } from "@corechain-technologies/uuid";
import { useAppDispatch } from "../hooks";

interface NotificationProps {
    show: boolean;
    data: NotificationCx;
}

const Notification: React.FC<NotificationProps> = ({ show, data }) => {
    const [notificationShow, setNotificationShow] = useState(true);
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (data) {
            setNotificationShow(true);
            if (data.timeout) {
                setTimeout(() => setNotificationShow(false), data.timeout - 600); // note, this value must be greater than timeout in notifications slice, so the toast dissmisses itself before losing its data
            }
        }
    }, [data]);

    let notification: NotificationCx = {
        id: "" as Uuid,
        message: "",
        notificationType: "INFORMATIONAL",
        timestamp: new Date(),
    };

    if (data) {
        notification = data;
    }

    let icon = <></>;
    if (notification.notificationType === "SUCCESS") {
        icon = <CheckCircleIcon className="h-6 w-6 text-green-400" aria-hidden="true" />;
    } else if (notification.notificationType === "ERROR") {
        icon = <ExclamationCircleIcon className="h-6 w-6 text-red-400" aria-hidden="true" />;
    } else if (notification.notificationType === "INFORMATIONAL") {
        icon = <InformationCircleIcon className="h-6 w-6 text-yellow-400" aria-hidden="true" />;
    }

    return (
        <>
            <Transition
                show={show && notificationShow}
                as={Fragment}
                enter="transform ease-out duration-300 transition"
                enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
                enterTo="translate-y-0 opacity-100 sm:translate-x-0"
                leave="transition ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
            >
                <div className="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
                    <div className="p-4">
                        <div className="flex items-start">
                            <div className="flex-shrink-0">{icon}</div>
                            <div className="ml-3 w-0 flex-1 pt-0.5">
                                <p className="text-sm font-medium text-gray-900">{notification.message}</p>
                                <p className="mt-1 text-sm text-gray-400">
                                    {notification.timestamp.toDateString() +
                                        ", " +
                                        notification.timestamp.toLocaleTimeString()}
                                </p>
                            </div>
                            <div className="ml-4 flex flex-shrink-0">
                                <button
                                    type="button"
                                    className="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500"
                                    onClick={() => {
                                        setNotificationShow(false);
                                        setTimeout(() => dispatch(removeNotification(notification.id)), 1000);
                                    }}
                                >
                                    <span className="sr-only">Close</span>
                                    <XMarkIcon className="h-5 w-5" aria-hidden="true" />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </Transition>
        </>
    );
};

export default Notification;
