import { trpc } from "../utils/trpc";
import type * as db from "@corechain-technologies/database";
import { SortDirection, getStatusOrder } from "./listings";
import {
    BatchId,
    BatchStatusName,
    CorrelationId,
    PayerId,
    PaymentInstructionMessage,
} from "@corechain-technologies/types";
import { createAction } from "../utils/createAction";
import { Reducer } from "redux";
import { AppThunk, RootState } from ".";
import { z } from "zod";
import { PaymentViewItem } from "@corechain-technologies/database/lib/recordTypes.js";
import { Selector, createSelector } from "reselect";
import { pipe } from "fp-ts/function";
import { Ord, fromCompare } from "fp-ts/Ord";
import * as A from "fp-ts/Array";

// const args: db.prisma.Prisma.PaymentViewFindManyArgs = { where: {}, select: {}, cursor: {} };
// const payments = await trpc.payments.get.query(args);

// console.log("payments" , payments);

export interface PaymentListing extends Omit<PaymentViewItem, "data"> {
    data: PaymentInstructionMessage;
}

export type PaymentsSortAttribute =
    // | "date"
    // | "originationDate"
    "statusDate" | "amount" | "status" | "payee" | "originationDate";
// | "payer"
// | "uploadedFileName"

export type PaymentsSortOption = [PaymentsSortAttribute, SortDirection];
export type PaymentsSortOptions = Array<PaymentsSortOption>;
export const defaultPaymentsSortOption: PaymentsSortOption = ["statusDate", "desc"];
type PaymentsById = Record<CorrelationId, PaymentListing>;

export type PaymentsState = {
    entities: {
        allIds: CorrelationId[];
        byId: PaymentsById;
    };
    sort: PaymentsSortOptions;
    status: "UNINITIALIZED" | "INITIALIZED" | "FETCHING" | "UPDATING" | "FAILED";
};

export const initialPaymentsState: PaymentsState = {
    entities: { allIds: [], byId: {} },
    sort: [defaultPaymentsSortOption],
    status: "UNINITIALIZED",
};

export const getPayments = createAction("PAYMENTS:FETCHED_ALL", (payments: PaymentListing[]) => {
    return payments;
});

export const initFetchingPayments = createAction("PAYMENTS:FETCHING_ALL");
export const updatingPayments = createAction("PAYMENTS:UPDATING_ALL"); // notional at this point
export const paymentsFetchError = createAction("PAYMENTS:ERROR");

export const togglePaymentsSort = createAction("PAYMENTS:TOGGLE_SORT", (key: PaymentsSortAttribute) => key);
export const togglePaymentsSortDir = createAction("PAYMENTS:TOGGLE_SORT_DIR", (key: SortDirection) => key);
export const clearPaymentsSort = createAction("PAYMENTS:CLEAR_SORT");

export const sortPaymentsReducer: Reducer<PaymentsSortOptions> = (
    state = [defaultPaymentsSortOption],
    action,
) => {
    if (clearPaymentsSort.match(action)) {
        return [defaultPaymentsSortOption];
    }
    if (togglePaymentsSort.match(action)) {
        console.log("togglePaymentSort (key) happened from the store" + action.payload);
        if (
            action.payload === "payee" ||
            action.payload === "amount" ||
            action.payload === "statusDate" ||
            action.payload === "originationDate" ||
            action.payload === "status"
        ) {
            return [[action.payload, "asc"]];
        } else return [[action.payload, state[0][1]]];
    }
    if (togglePaymentsSortDir.match(action)) {
        return [[state[0][0], action.payload]];
    } else {
        return state;
    }
};

export const paymentsAllIdsReducer: Reducer<CorrelationId[]> = (state = [], action) => {
    if (getPayments.match(action)) {
        return action.payload.map((i) => i.correlationId);
    }
    return state;
};

export const paymentsByIdReducer: Reducer<PaymentsById> = (state = {}, action) => {
    if (getPayments.match(action)) {
        console.log("get payments was hit");
        return action.payload.reduce(
            (acc, cur) => {
                acc[cur.data.correlationId] = cur;
                return acc;
            },
            {} as Record<CorrelationId, PaymentListing>,
        );
    }
    // if (addBatch.match(action)) {
    //     return { ...state, [action.payload.id]: action.payload };
    // }
    return state;
};

export const paymentsReducer: Reducer<PaymentsState> = (state = initialPaymentsState, action) => {
    if (getPayments.match(action)) {
        return {
            ...state,
            status: "INITIALIZED",
            entities: {
                allIds: paymentsAllIdsReducer([], action),
                byId: paymentsByIdReducer({}, action),
            },
        };
    }
    // if (addBatch.match(action)) {
    //     return {
    //         ...state,
    //         status: "INITIALIZED",
    //         entities: {
    //             allIds: paymentsAllIdsReducer(state.entities.allIds, action),
    //             byId: paymentsByIdReducer(state.entities.byId, action),
    //         },
    //     };
    // }
    if (initFetchingPayments.match(action)) {
        return {
            ...state,
            status: "FETCHING",
        };
    }
    if (updatingPayments.match(action)) {
        return {
            ...state,
            status: "UPDATING",
        };
    }

    if (paymentsFetchError.match(action)) {
        return {
            ...state,
            status: "FAILED",
        };
    }
    if (
        clearPaymentsSort.match(action) ||
        togglePaymentsSort.match(action) ||
        togglePaymentsSortDir.match(action)
    ) {
        return { ...state, sort: sortPaymentsReducer(state.sort, action) };
    }
    return state;
};

export function fetchPayments(): AppThunk {
    return async (dispatch) => {
        dispatch(initFetchingPayments());
        const result = await trpc.payments.get.query({});
        // const foo: PaymentViewItem;
        // const paymentsData = z.array(PaymentInstructionMessage).safeParse(result);
        // console.log("payments data", result);
        // dispatch(getPayments(paymentsData.success ? paymentsData.data : []));

        const paymentData: PaymentListing[] = result.map((payment) => {
            return {
                ...payment,
                batchId: payment.batchId as BatchId,
                correlationId: payment.correlationId as CorrelationId,
                payerId: payment.payerId as PayerId,
                data: PaymentInstructionMessage.parse(payment.data),
            };
        });

        dispatch(getPayments(paymentData));
    };
}

export function fetchFilteredPayments(args: Parameters<typeof trpc.filteredPayments.query>[0]): AppThunk {
    return async (dispatch) => {
        dispatch(initFetchingPayments());
        const result = await trpc.filteredPayments.query(args);
        const paymentData: PaymentListing[] = result.map((payment) => ({
            ...payment,
            batchId: payment.batchId as BatchId,
            correlationId: payment.correlationId as CorrelationId,
            payerId: payment.payerId as PayerId,
            data: PaymentInstructionMessage.parse(payment.data), // TODO: change the cxpay-api fetch function to return a thoroughly typed result and not this generic database return type that we have to cast, as we did in the previous 5 lines
        }));
        dispatch(getPayments(paymentData));
    };
}

const makePaymentsOrderFunc = (
    sortOption: PaymentsSortOption = defaultPaymentsSortOption,
): Ord<PaymentListing> => {
    const [attribute, direction] = sortOption;
    const eq = 0;
    const lt = direction === "asc" ? 1 : -1;
    const gt = direction === "desc" ? 1 : -1;

    return fromCompare((a, b) => {
        const aLastUpdate = a.lastStatusDate;
        const bLastUpdate = b.lastStatusDate;
        const aCreated = a.createdDate;
        const bCreated = b.createdDate;
        if (attribute === "amount") {
            const aAmount = Number(a.amount);
            const bAmount = Number(b.amount);
            return aAmount === bAmount ? eq : aAmount > bAmount ? lt : gt;
        }
        if (attribute === "statusDate") {
            return aLastUpdate?.valueOf() === bLastUpdate?.valueOf()
                ? eq
                : (aLastUpdate?.valueOf() ?? 0) > (bLastUpdate?.valueOf() ?? 0)
                  ? lt
                  : gt;
        }
        if (attribute === "originationDate") {
            return aCreated?.valueOf() === bCreated?.valueOf()
                ? eq
                : (aCreated?.valueOf() ?? 0) > (bCreated?.valueOf() ?? 0)
                  ? lt
                  : gt;
        }
        if (attribute === "status") {
            const aStatus = getStatusOrder(a.lastStatus as BatchStatusName); // TODO: fix me!
            const bStatus = getStatusOrder(b.lastStatus as BatchStatusName);
            if (aStatus > bStatus) {
                return lt;
            } else if (aStatus < bStatus) {
                return gt;
            } else if (aStatus === bStatus) {
                return aLastUpdate?.valueOf() === bLastUpdate?.valueOf()
                    ? eq
                    : (aLastUpdate?.valueOf() ?? 0) > (bLastUpdate?.valueOf() ?? 0)
                      ? -1
                      : 1; // note, the date subsort is ALWAYS descending, even when the status sort is ascending
                // really? to the above
            }
        }
        if (attribute === "payee") {
            // note this does not read from the ACH Payee field! Recipient name!
            const aName = a.data.recipientName.toLowerCase();
            const bName = b.data.recipientName.toLowerCase();
            if (aName > bName) {
                return lt;
            } else if (aName < bName) {
                return gt;
            } else if (aName === bName) {
                const aAmount = Number(a.amount);
                const bAmount = Number(b.amount);
                return aAmount === bAmount ? eq : aAmount > bAmount ? lt : gt;
            }
        }
        return 0;
    });
};

export const selectAllPaymentIds: Selector<RootState, CorrelationId[]> = (state) => {
    return state.payments.entities.allIds;
};

export const selectAllPaymentsById: Selector<RootState, PaymentsById> = (state) => {
    return state.payments.entities.byId;
};

export const selectAllPayments: Selector<RootState, PaymentListing[]> = createSelector(
    [selectAllPaymentIds, selectAllPaymentsById],
    (ids, payments) => {
        return ids.map((id) => payments[id]);
    },
);

export const selectPaymentsSortOptions: Selector<Pick<RootState, "payments">, PaymentsSortOptions> = (
    state,
) => {
    return state.payments.sort;
};

export const selectPayments = createSelector(
    [selectAllPayments, selectPaymentsSortOptions],
    (payments, sortOpts) => {
        const order = makePaymentsOrderFunc(sortOpts[0]);
        return pipe(payments, A.sort(order));
    },
);
