import { Selector, createSelector } from "reselect";
import { Reducer } from "redux";

import {
    BatchEvent,
    BatchStatusSequence,
    Batch,
    Receipt,
    Role,
    includesAllRoles,
} from "@corechain-technologies/types";

import { RootState } from ".";
import { selectAllBatches } from "./batches";
import { selectAllReceipts } from "./receipts";

import * as A from "fp-ts/Array";
import * as string from "fp-ts/string";
import { pipe } from "fp-ts/function";
import { Ord, fromCompare } from "fp-ts/Ord";

import { selectUserRoles } from "./user";
import {
    BatchId,
    BatchStatus,
    BatchStatusName,
    CorrelationId,
    PaymentInstructionMessage,
} from "@corechain-technologies/types";
import { createAction } from "../utils/createAction";
import { PaymentToVendorHistory } from "../components/VendorPaymentHistory";
import { getPaymentsByVendorData, TempVendorFields } from "../utils/formatChartData";
import { z } from "zod";

export type Listing = {
    // common fields
    amount: bigint;
    /* eslint-disable @typescript-eslint/naming-convention */
    created_date: Date;
    updated_date: Date;
    /* eslint-enable @typescript-eslint/naming-convention */
    status: BatchStatus;
    uploadedFileName?: string;
} & (
    | {
          id: BatchId;
          type: "BATCH";
          original: Batch;
      }
    | {
          id: CorrelationId;
          type: "RECEIPT";
          original: Receipt;
      }
);

export const PaymentWithDate = PaymentInstructionMessage.and(
    z.object({
        createdDate: z.date({ coerce: true }),
        currentStatus: BatchStatus,
        method: z.union([z.literal("ACH"), z.literal("Card")]),
        events: z.array(BatchEvent),
    }),
);

export type PaymentWithDate = z.infer<typeof PaymentWithDate>;

type Identity<A> = A;
export interface ReceiptListing extends Identity<Listing & { type: "RECEIPT" }> {}
export interface BatchListing extends Identity<Listing & { type: "BATCH" }> {}

export type SortAttribute = // deprecated
    "date" | "originationDate" | "statusDate" | "amount" | "status" | "payee" | "payer" | "uploadedFileName";
export type SortDirection = "asc" | "desc";

export type ListingsSortOption = [SortAttribute, SortDirection];
export type ListingsSortOptions = Array<ListingsSortOption>;
const defaultSortOption: ListingsSortOption = ["originationDate", "desc"];

export type ListingsTypeFilter = {
    payments: boolean;
    receipts: boolean;
};

export type ListingsStatusFilter = {
    created: boolean;
    approved: boolean;
    scheduled: boolean;
    pending: boolean;
    delivered: boolean;
    accepted: boolean;
    settled: boolean;
    cancelled: boolean;
    deposited: boolean;
    flagged: boolean;
    error: boolean;
};

type ListingsStatusFilterKey = keyof ListingsStatusFilter;

export type ListingsState = {
    isDuplicate: boolean;
    typeFilter: ListingsTypeFilter;
    statusFilter: ListingsStatusFilter;
    sort: ListingsSortOptions;
    selection: (CorrelationId | BatchId)[];
};

export const initialListingsState: ListingsState = {
    isDuplicate: false,
    typeFilter: { payments: true, receipts: true },
    statusFilter: {
        created: true,
        approved: true,
        scheduled: true,
        pending: true,
        delivered: true,
        accepted: true,
        settled: true,
        cancelled: true,
        deposited: true,
        flagged: true,
        error: true,
    },
    selection: [],
    sort: [defaultSortOption],
};

export const toggleSort = createAction("LISTINGS:TOGGLE_SORT", (key: SortAttribute) => key);
export const toggleSortDir = createAction("LISTINGS:TOGGLE_SORT_DIR", (key: SortDirection) => key);
export const clearSort = createAction("LISTINGS:CLEAR_SORT");

export const sortReducer: Reducer<ListingsSortOptions> = (state = [defaultSortOption], action) => {
    if (clearSort.match(action)) {
        return [defaultSortOption];
    }
    if (toggleSort.match(action)) {
        console.log("toggleSort (key) happened from the store" + action.payload);
        if (
            action.payload === "payee" ||
            action.payload === "payer" ||
            action.payload === "uploadedFileName"
        ) {
            return [[action.payload, "asc"]];
        } else return [[action.payload, state[0][1]]];
    }
    if (toggleSortDir.match(action)) {
        return [[state[0][0], action.payload]];
    } else {
        return state;
    }
};

export const togglePaymentsFilter = createAction("LISTINGS:TOGGLE_PAYMENTS_FILTER");
export const toggleReceiptsFilter = createAction("LISTINGS:TOGGLE_RECEIPTS_FILTER");
export const selectAllFilters = createAction("LISTINGS:SELECT_ALL_FILTERS");
export const toggleStatusFilter = createAction(
    "LISTINGS:TOGGLE_STATUS_FILTER",
    (key: ListingsStatusFilterKey) => key,
);
export const toggleSelection = createAction(
    "LISTINGS:TOGGLE_SELECTION",
    (selection: CorrelationId | BatchId) => selection,
);

export const switchDuplicate = createAction("LISTINGS:IS_DUPLICATE", (isDuplicate: boolean) => isDuplicate);

export const listingsReducer: Reducer<ListingsState> = (state = initialListingsState, action) => {
    if (switchDuplicate.match(action)) {
        return { ...state, isDuplicate: action.payload };
    }
    if (togglePaymentsFilter.match(action)) {
        return { ...state, typeFilter: { ...state.typeFilter, payments: !state.typeFilter.payments } };
    }
    if (toggleReceiptsFilter.match(action)) {
        return { ...state, typeFilter: { ...state.typeFilter, receipts: !state.typeFilter.receipts } };
    }
    if (selectAllFilters.match(action)) {
        return { ...state, typeFilter: { payments: true, receipts: true } };
    }
    if (toggleStatusFilter.match(action)) {
        return {
            ...state,
            statusFilter: {
                ...state.statusFilter,
                [action.payload]: !state.statusFilter[action.payload],
            },
        };
    }
    if (toggleSelection.match(action)) {
        const exists = state.selection.includes(action.payload);
        const selection = exists
            ? state.selection.filter((i) => i !== action.payload)
            : state.selection.concat(action.payload);
        return {
            ...state,
            selection,
        };
    }
    if (clearSort.match(action) || toggleSort.match(action) || toggleSortDir.match(action)) {
        return { ...state, sort: sortReducer(state.sort as ListingsSortOptions, action) };
    }
    return state;
};

export const selectTypeFilters: Selector<Pick<RootState, "listings">, ListingsTypeFilter> = (state) => {
    return state.listings.typeFilter;
};

export const selectStatusFilters: Selector<Pick<RootState, "listings">, ListingsStatusFilter> = (state) => {
    return state.listings.statusFilter;
};

export const selectSortOptions: Selector<Pick<RootState, "listings">, ListingsSortOptions> = (state) => {
    return state.listings.sort;
};
//Does not really select all, but looks at filters
export const selectAllListings = createSelector(
    [selectTypeFilters, selectAllBatches, selectAllReceipts],
    (typeFilters, batches, receipts) => {
        let listings: Listing[] = [];
        if (typeFilters.payments) {
            listings = [...listings, ...batches];
        }
        if (typeFilters.receipts) {
            listings = [...listings, ...receipts];
        }
        return listings;
    },
);

export const selectAllPayments = createSelector([selectAllBatches], (batches) => {
    const payments: PaymentWithDate[] = batches.flatMap((batch) =>
        batch.original.payments.map(
            (payment) =>
                ({
                    ...payment,
                    createdDate: batch.created_date,
                    currentStatus: batch.original.events[batch.original.events.length - 1].status,
                    events: batch.original.events,
                }) as any,
        ),
    );
    return payments; //.slice(0, 99); // TODO: A real pagination solution. limiting this to 100 since this is kinda still demoware
});

const makeIndividualPaymentsOrderFunc = (
    sortOption: ListingsSortOption = defaultSortOption,
): Ord<PaymentWithDate> => {
    const [attribute, direction] = sortOption;
    const eq = 0;
    const lt = direction === "asc" ? 1 : -1;
    const gt = direction === "desc" ? 1 : -1;

    return fromCompare((a, b) => {
        const aLastUpdate = a.events[a.events.length - 1].timestamp;
        const bLastUpdate = b.events[b.events.length - 1].timestamp;
        const aCreated = a.events[0].timestamp;
        const bCreated = b.events[0].timestamp;
        if (attribute === "amount") {
            const aAmount = Number(a.amount);
            const bAmount = Number(b.amount);
            return aAmount === bAmount ? eq : aAmount > bAmount ? lt : gt;
        }
        if (attribute === "statusDate") {
            return aLastUpdate.valueOf() === bLastUpdate.valueOf()
                ? eq
                : aLastUpdate.valueOf() > bLastUpdate.valueOf()
                  ? lt
                  : gt;
        }
        if (attribute === "originationDate") {
            return aCreated.valueOf() === bCreated.valueOf()
                ? eq
                : aCreated.valueOf() > bCreated.valueOf()
                  ? lt
                  : gt;
        }
        if (attribute === "status") {
            const aStatus = getStatusOrder(a.currentStatus);
            const bStatus = getStatusOrder(b.currentStatus);
            if (aStatus > bStatus) {
                return lt;
            } else if (aStatus < bStatus) {
                return gt;
            } else if (aStatus === bStatus) {
                return aLastUpdate.valueOf() === bLastUpdate.valueOf()
                    ? eq
                    : aLastUpdate.valueOf() > bLastUpdate.valueOf()
                      ? -1
                      : 1; // note, the date subsort is ALWAYS descending, even when the status sort is ascending
                // really? to the above
            }
        }
        if (attribute === "payee") {
            // note this does not read from the ACH Payee field! Recipient name!
            const aName = a.recipientName.toLowerCase();
            const bName = b.recipientName.toLowerCase();
            if (aName > bName) {
                return lt;
            } else if (aName < bName) {
                return gt;
            } else if (aName === bName) {
                const aAmount = Number(a.amount);
                const bAmount = Number(b.amount);
                return aAmount === bAmount ? eq : aAmount > bAmount ? lt : gt;
            }
        }
        return 0;
    });
};

export const selectSortedPayments = createSelector(
    [selectAllPayments, selectSortOptions],
    (payments, sortOpts) => {
        const order = makeIndividualPaymentsOrderFunc(sortOpts[0]);
        return pipe(payments, A.sort(order));
    },
);

export function isBatch(listing: unknown): listing is Batch {
    return Batch.safeParse(listing).success;
}

export function isReceipt(listing: unknown): listing is Receipt {
    return Receipt.safeParse(listing).success;
}

//Selection based on payment status filters
export const selectFilteredListings = createSelector(
    [selectAllListings, selectStatusFilters],
    (listings: Listing[], statusFilters) =>
        listings.flatMap((listing) => {
            let key: ListingsStatusFilterKey;
            if (listing.type === "RECEIPT") {
                return listing;
            }
            if (listing.type === "BATCH") {
                key = listing.status.toLowerCase() as any;
                if (statusFilters[key]) {
                    return listing;
                }
            }
            return [];
        }),
);

function includesAll<A extends string[]>(arr: A, search: A[number][]): boolean {
    if (!search.length) {
        return true;
    }

    const search_ = A.uniq(string.Eq)(search);

    return A.intersection(string.Eq)(arr, search).length === search_.length;
}

export const selectPermissionedTypeFilters = createSelector(
    [selectUserRoles, selectTypeFilters],
    (roles, filters) => {
        if (includesAllRoles(roles)) {
            return filters;
        } else {
            if (!roles.includes(Role.BUYER)) {
                return { ...filters, payments: false };
            }
            if (!roles.includes(Role.SUPPLIER)) {
                return { ...filters, receipts: false };
            }
        }

        return filters;
    },
);

const makeOrderFunc = (sortOption: Readonly<ListingsSortOption> = defaultSortOption): Ord<Listing> => {
    const [attribute, direction] = sortOption;
    const eq = 0;
    const lt = direction === "asc" ? 1 : -1;
    const gt = direction === "desc" ? 1 : -1;

    return fromCompare((a, b) => {
        if (attribute === "amount") {
            const aAmount = Number(a.amount);
            const bAmount = Number(b.amount);
            return aAmount === bAmount ? eq : aAmount > bAmount ? lt : gt;
        }
        if (attribute === "date" || attribute === "originationDate") {
            return a.created_date.valueOf() === b.created_date.valueOf()
                ? eq
                : a.created_date.valueOf() > b.created_date.valueOf()
                  ? lt
                  : gt;
        }
        if (attribute === "status") {
            // TODO: update our status handling so status from listings and status from batches can live in the same universe
            const aStatus = getStatusOrder(BatchStatusName[a.status.toUpperCase() as BatchStatusName]);
            const bStatus = getStatusOrder(BatchStatusName[b.status.toUpperCase() as BatchStatusName]);
            if (aStatus > bStatus) {
                return lt;
            } else if (aStatus < bStatus) {
                return gt;
            } else if (aStatus === bStatus) {
                return a.created_date.valueOf() === b.created_date.valueOf()
                    ? eq
                    : a.created_date.valueOf() > b.created_date.valueOf()
                      ? -1
                      : 1; // note, the date subsort is ALWAYS descending, even when the status sort is ascending
            }
        }
        if (attribute === "uploadedFileName") {
            const aUploadedFileName = a.uploadedFileName?.toLowerCase();
            const bUploadedFileName = b.uploadedFileName?.toLowerCase();
            if (aUploadedFileName && bUploadedFileName) {
                if (aUploadedFileName > bUploadedFileName) {
                    return lt;
                } else if (aUploadedFileName < bUploadedFileName) {
                    return gt;
                } else if (aUploadedFileName === bUploadedFileName) {
                    const aAmount = Number(a.amount);
                    const bAmount = Number(b.amount);
                    return aAmount === bAmount ? eq : aAmount > bAmount ? lt : gt;
                }
            }
        }

        return 0;
    });
};

export function getStatusOrder(status: BatchStatusName) {
    // the type cast below is the lesser (least?) of two (multiple?) evils... the "correct" way
    // to do this would involve converting the union of (typeof BatchStatusSequence)[keyof typeof BatchStatusSequence]
    // into a tuple, which is possible, but reportedly all kinds of bad
    return BatchStatusSequence.indexOf(status as (typeof BatchStatusSequence)[number]);
}

export const selectSortedListings = createSelector(
    [selectFilteredListings, selectSortOptions],
    (listings, sortOpts) => {
        const order = makeOrderFunc(sortOpts[0]);
        return pipe(listings, A.sort(order));
    },
);

export const selectRecentListings = createSelector([selectAllListings], (listings) => {
    const order = makeOrderFunc(defaultSortOption);
    const slice = (arr: readonly Listing[]) => arr.slice(0, 3); // TODO: parameterize this for the desired # of listings
    return pipe(listings, A.sort(order), slice);
});

export const isLoadingBatches: Selector<RootState, boolean> = (state) => {
    return state.batches.status === "FETCHING";
};

export const isLoadingReceipts: Selector<RootState, boolean> = (state) => {
    return state.receipts.status === "FETCHING";
};

export const hasFetchError: Selector<RootState, boolean> = (state) => {
    return state.batches.status === "FAILED" || state.receipts.status === "FAILED";
};

export const isLoading = createSelector(
    [selectUserRoles, isLoadingBatches, isLoadingReceipts],
    (roles, loadingBatches, loadingReceipts) => {
        if (roles.includes(Role.BUYER) && !roles.includes(Role.SUPPLIER)) {
            return loadingBatches;
        }
        if (roles.includes(Role.SUPPLIER) && !roles.includes(Role.BUYER)) {
            return loadingReceipts;
        } else {
            return loadingBatches || loadingReceipts;
        }
    },
);

export const isDuplicate: Selector<RootState, boolean> = (state) => {
    return state.listings.isDuplicate;
};

const makeReceivablesOrderFunc = (
    sortOption: Readonly<ListingsSortOption> = defaultSortOption,
): Ord<PaymentToVendorHistory> => {
    const [attribute, direction] = sortOption;
    const eq = 0;
    const lt = direction === "asc" ? 1 : -1;
    const gt = direction === "desc" ? 1 : -1;

    return fromCompare((a, b) => {
        if (attribute === "amount") {
            const aAmount = Number(a.amount);
            const bAmount = Number(b.amount);
            return aAmount === bAmount ? eq : aAmount > bAmount ? lt : gt;
        }
        if (attribute === "statusDate") {
            return a.date.valueOf() === b.date.valueOf() ? eq : a.date.valueOf() > b.date.valueOf() ? lt : gt;
        }
        if (attribute === "status") {
            const aStatus = getStatusOrder(BatchStatusName[a.status.toUpperCase() as BatchStatusName]);
            const bStatus = getStatusOrder(BatchStatusName[b.status.toUpperCase() as BatchStatusName]);
            if (aStatus > bStatus) {
                return lt;
            } else if (aStatus < bStatus) {
                return gt;
            } else if (aStatus === bStatus) {
                return a.date.valueOf() === b.date.valueOf()
                    ? eq
                    : a.date.valueOf() > b.date.valueOf()
                      ? -1
                      : 1; // note, the date subsort is ALWAYS descending, even when the status sort is ascending
            }
        }
        if (attribute === "payer") {
            // note this does not read from the ACH Payee field! Recipient name!
            const aName = a.payer.toLowerCase();
            const bName = b.payer.toLowerCase();
            if (aName > bName) {
                return lt;
            } else if (aName < bName) {
                return gt;
            } else if (aName === bName) {
                const aAmount = Number(a.amount);
                const bAmount = Number(b.amount);
                return aAmount === bAmount ? eq : aAmount > bAmount ? lt : gt;
            }
        }
        return 0;
    });
};

export const selectSortedReceivables = createSelector(
    [selectAllBatches, selectSortOptions],
    (batchListings, sortOpts) => {
        // TODO: SelectSortedReceivables is a demo feature using a hard-coded vendor name
        const paymentsByVendorData: TempVendorFields[] = getPaymentsByVendorData(batchListings);
        const selectedVendorData = paymentsByVendorData;
        // .filter((vendor) => vendor.companyId === match?.params?.["companyId"] ?? null)
        // .filter((vendor) => vendor.companyId === "Cormier and Sons" ?? null);
        const order = makeReceivablesOrderFunc(sortOpts[0]);
        // .filter((vendor) => vendor.companyId === match?.params?.["companyId"] ?? null)

        let paymentsToVendorHistory: PaymentToVendorHistory[] = [];

        if (selectedVendorData) {
            paymentsToVendorHistory = selectedVendorData.map((vendorData) => {
                return {
                    amount: vendorData.amountPaid,
                    date: vendorData.datePaid,
                    method: vendorData.method,
                    status: vendorData.paymentStatus,
                    vendorPaymentId: vendorData.vendorPaymentId,
                    payer: vendorData.payer,
                };
            });
        }

        return pipe(paymentsToVendorHistory, A.sort(order));
    },
);

export const selectRecentReceivables = createSelector([selectSortedReceivables], (listings) => {
    const order = makeReceivablesOrderFunc(defaultSortOption);
    const slice = (arr: readonly PaymentToVendorHistory[]) => arr.slice(0, 3); // TODO: parameterize this for the desired # of listings
    return pipe(listings, A.sort(order), slice);
});
