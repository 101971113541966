import { BatchStatusName, Batch } from "@corechain-technologies/types";

import Feature from "./Feature";
import { useAppSelector } from "../hooks";
import { selectSortedListings, isLoading, isDuplicate } from "../store/listings";
import LoadingListing from "./LoadingListing";
import { useI18n } from "@hi18n/react";
import { book } from "../locale";
import PaginationControls from "./PaginationControls";
import { Outlet, useNavigate } from "react-router-dom";
import { classNames } from "../utils/classNames";
import ColumnHeader from "./ColumnHeader";
import { showDetailPane } from "./DetailPane";
import { PrintHeader } from "./PrintHeader";
import DummyListingForDuplicateError from "./DummyListingForDuplicateError";
import DummyListingForUploadError from "./DummyListingForUploadError";
import {
    isFileUploadError,
    isLoadingBatchListings,
    isUpdatingBatches,
    selectBatchListings,
    selectFilteredBatchListings,
} from "../store/batches";
import { UpdatingPulse } from "./UpdatingPulse";
import BatchListing from "./BatchListingDesktop";
import BatchListingMobile from "./BatchListingMobile";
import FilterControls from "./FilterControls";

const BatchesMobile = () => {
    const batchListings = useAppSelector(selectSortedListings).filter((listing) => listing.type === "BATCH");

    return (
        <div className="shadow sm:hidden print:hidden">
            <ul className="mt-3 divide-y divide-gray-200 overflow-hidden shadow sm:hidden">
                {batchListings.map((batchListing) => {
                    if (batchListing.type === "BATCH") {
                        return <BatchListingMobile key={batchListing.id} batchListing={batchListing} />;
                    }
                })}
            </ul>
            <Feature flag="DEPLOY_TARGET" value="DEVELOPMENT">
                <PaginationControls />
                {/* put this in payments component */}
            </Feature>
        </div>
    );
};

const BatchesDesktop: React.FC = () => {
    const { t } = useI18n(book);
    const isLoadingListings = useAppSelector(isLoadingBatchListings);
    const isDuplicateListing = useAppSelector(isDuplicate);
    const isFileUploadErrorListing = useAppSelector(isFileUploadError);
    const updating = useAppSelector(isUpdatingBatches);
    const batchListings = useAppSelector(selectSortedListings).filter((listing) => listing.type === "BATCH");
    const filteredBatchListings = useAppSelector(selectBatchListings);
    console.log("filteredB", filteredBatchListings);
    return (
        <>
            <div className="hidden sm:block print:block">
                <div className="max-w-10xl mx-auto px-4 sm:px-6 lg:px-8">
                    <PrintHeader title={t("tabs/paymentSets")} />
                    <div className="mt-2 flex flex-col">
                        <div className="inline-block align-middle min-w-full pb-2">
                            <div className="shadow-sm print:shadow-none ring-1 ring-black print:ring-transparent ring-opacity-5 relative">
                                {updating && <UpdatingPulse />}
                                <table className="min-w-full divide-y divide-gray-200 border-separate border-spacing-0">
                                    <thead className="bg-gray-50 card">
                                        <tr>
                                            <ColumnHeader
                                                key="originationDate"
                                                sortValue="originationDate"
                                                textAlign="left"
                                                className="pl-4 sm:pl-6 lg:pl-8"
                                            />
                                            <ColumnHeader
                                                key="uploadedFileName"
                                                sortValue="uploadedFileName"
                                                textAlign="left"
                                                className="pl-2"
                                            />
                                            <ColumnHeader
                                                key="amount"
                                                sortValue="amount"
                                                textAlign="right"
                                                className="pl-0"
                                            />
                                            <ColumnHeader
                                                key="status"
                                                sortValue="status"
                                                textAlign="center"
                                                className="hidden sm:table-cell print:table-cell"
                                            />
                                        </tr>
                                    </thead>

                                    <tbody className="bg-white divide-y divide-gray-200">
                                        <Feature flag="DEPLOY_TARGET" value="DEVELOPMENT">
                                            <FilterControls view="BATCHES" />
                                        </Feature>
                                        {isLoadingListings && <LoadingListing batches={true} />}
                                        {isFileUploadErrorListing && (
                                            <DummyListingForUploadError batches={true} />
                                        )}
                                        {isDuplicateListing && (
                                            <DummyListingForDuplicateError batches={true} />
                                        )}
                                        {filteredBatchListings.map((filteredBatchListing) => {
                                            if (filteredBatchListing.type === "BATCH") {
                                                return (
                                                    <BatchListing
                                                        key={filteredBatchListing.id}
                                                        batchListing={filteredBatchListing}
                                                    />
                                                );
                                            }
                                        })}
                                    </tbody>
                                </table>
                                {/* Pagination */}
                                <Feature flag="DEPLOY_TARGET" value="DEVELOPMENT">
                                    <nav
                                        className="bg-white px-4 py-3 flex items-center justify-between sm:px-6 lg:px-8 print:hidden"
                                        aria-label="Pagination"
                                    >
                                        <div className="hidden sm:block print:table-cell">
                                            <p className="text-sm text-gray-700">
                                                Showing<span className="font-medium"> 1</span> to
                                                <span className="font-medium">
                                                    {" "}
                                                    {batchListings.length}
                                                </span>{" "}
                                                of
                                                <span className="font-medium">
                                                    {" "}
                                                    {batchListings.length}
                                                </span>{" "}
                                                results
                                            </p>
                                        </div>
                                        <div className="flex-1 flex justify-between sm:justify-end">
                                            <div className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50">
                                                Previous
                                            </div>
                                            <div className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50">
                                                Next
                                            </div>
                                        </div>
                                    </nav>
                                </Feature>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

const BatchesTable: React.FC = () => {
    return (
        <>
            <Outlet />

            {/* Activity list (smallest breakpoint only)// move this comment  */}
            <BatchesMobile />
            {/* Activity table (small breakpoint and up) */}
            <BatchesDesktop />
        </>
    );
};

export default BatchesTable;
