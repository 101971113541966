import { Mutable } from "type-fest";
import { AppThunk, RootState } from ".";
import { BatchStatus, CorrelationId, Receipt } from "@corechain-technologies/types";
import { parseJsonWithBigInt } from "../utils/helpers.js";
import { Reducer } from "redux";
import { Selector, createSelector } from "reselect";
import { ReadonlyRecord } from "fp-ts/lib/ReadonlyRecord.js";
import { ReceiptListing } from "./listings.js";
import { createAction } from "../utils/createAction.js";
import { z } from "zod";
import { trpc } from "../utils/trpc";

type ReceiptsById = ReadonlyRecord<CorrelationId, ReceiptListing>;

export type ReceiptsState = Readonly<{
    readonly entities: Readonly<{
        allIds: ReadonlyArray<CorrelationId>;
        byId: ReceiptsById;
    }>;
    status: "UNINITIALIZED" | "INITIALIZED" | "FETCHING" | "FAILED";
}>;

export const initialReceiptsState: ReceiptsState = {
    entities: { allIds: [], byId: {} },
    status: "UNINITIALIZED",
};

export const getReceipts = createAction("RECEIPTS:FETCHED", (receipts: Receipt[]): ReceiptListing[] =>
    receipts.map(receiptToListing),
);
export const initFetchingReceipts = createAction("RECEIPTS:FETCHING");
export const receiptsFetchError = createAction("RECEIPTS:ERROR");

export const receiptsAllIdsReducer: Reducer<ReadonlyArray<CorrelationId>> = (state = [], action) => {
    if (getReceipts.match(action)) {
        return action.payload.map((receiptListing) => receiptListing.id);
    }
    return state;
};

export const receiptsByIdReducer: Reducer<ReceiptsById> = (state = {}, action) => {
    if (getReceipts.match(action)) {
        return action.payload.reduce((acc, cur) => {
            acc[cur.id] = cur;
            return acc;
        }, {} as Mutable<ReceiptsById>);
    }
    return state;
};

export const receiptsReducer: Reducer<ReceiptsState> = (state = initialReceiptsState, action) => {
    if (getReceipts.match(action)) {
        return {
            status: "INITIALIZED",
            entities: {
                allIds: receiptsAllIdsReducer([], action),
                byId: receiptsByIdReducer({}, action),
            },
        };
    }
    if (initFetchingReceipts.match(action)) {
        return {
            ...state,
            status: "FETCHING",
        };
    }
    if (receiptsFetchError.match(action)) {
        return {
            ...state,
            status: "FAILED",
        };
    }
    return state;
};

export const selectAllReceiptIds: Selector<Pick<RootState, "receipts">, ReadonlyArray<CorrelationId>> = (
    state,
) => {
    return state.receipts.entities.allIds;
};

export const selectAllReceiptsById: Selector<Pick<RootState, "receipts">, ReceiptsById> = (state) => {
    return state.receipts.entities.byId;
};

export const selectAllReceipts = createSelector(
    [selectAllReceiptIds, selectAllReceiptsById],
    (ids, receipts) => {
        return ids.map((correlationId: CorrelationId): ReceiptListing => {
            const holder = receipts[correlationId];
            if (holder) {
                return holder;
            } else {
                throw new Error("no receipts in selector");
            }
        });
    },
);

export function fetchReceipts(): AppThunk {
    return async (dispatch, getState) => {
        const { services, receipts, user } = getState();
        if (receipts.status !== "UNINITIALIZED") {
            return;
        }
        const result = await trpc.vendors.receipts.list.query();

        if (result.err) {
            dispatch(receiptsFetchError());
        }

        if (result.ok) {
            const receiptsData = z.array(Receipt).safeParse(result.val);

            if (!receiptsData.success) {
                throw new Error("invalid receipts data", { cause: receiptsData.error });
            }

            dispatch(getReceipts(receiptsData.data));
        }
    };
}

export function getDateForReceipt(receipt: Receipt): Date {
    return receipt.events[0].timestamp;
}

export function receiptToListing(receipt: Receipt): ReceiptListing {
    return {
        id: receipt.correlationId,
        type: "RECEIPT",
        original: receipt,
        amount: BigInt(receipt.amount),
        /* eslint-disable @typescript-eslint/naming-convention */
        created_date: receipt.events[0].timestamp,
        updated_date: receipt.events[receipt.events.length - 1].timestamp,
        /* eslint-enable @typescript-eslint/naming-convention */
        status: receipt.events[receipt.events.length - 1].from as BatchStatus,
        uploadedFileName: `from ${receipt.payerName} on ${receipt.events[0].timestamp}`,
    };
}
