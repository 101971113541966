import { useI18n } from "@hi18n/react";
import { useAppDispatch, useAppSelector } from "../hooks";
import { book } from "../locale";
import {
    selectSortOptions,
    toggleSort,
    toggleSortDir,
    ListingsSortOptions,
    SortAttribute,
    SortDirection,
} from "../store/listings";
import { classNames } from "../utils/classNames";
import { BarsArrowDownIcon, BarsArrowUpIcon } from "./BarsArrowIcons";
import {
    PaymentsSortAttribute,
    selectPaymentsSortOptions,
    togglePaymentsSort,
    togglePaymentsSortDir,
} from "../store/payments";
import { useMatch } from "react-router-dom";
import {
    BatchesSortAttribute,
    selectBatchListingsSortOptions,
    toggleBatchesSort,
    toggleBatchesSortDir,
} from "../store/batches";

interface ColumnHeaderProps {
    sortValue: SortAttribute | PaymentsSortAttribute; // SortAttribute should be renamed "BatchesSortAttribute" eventually
    textAlign?: "left" | "center" | "right";
    className?: string;
}

interface CurrentSortIconProps {
    dir: SortDirection;
}

export const CurrentSortIcon: React.FC<CurrentSortIconProps> = ({ dir }) => {
    if (dir === "desc") {
        return BarsArrowDownIcon;
    }
    return BarsArrowUpIcon;
};

const ColumnHeader: React.FC<ColumnHeaderProps> = ({ sortValue, textAlign = "left", className }) => {
    const isBatchesTable = useMatch("/:payer/app/payments/payment-sets");
    const isIndividualPaymentsTable = useMatch("/:payer/app/payments/payments-individual");
    const { t } = useI18n(book);
    const dispatch = useAppDispatch();
    const currentBatchesSort = useAppSelector(selectBatchListingsSortOptions);
    const currentPaymentsSort = useAppSelector(selectPaymentsSortOptions);
    const batchesHoverIcon = currentBatchesSort[0][1] === "asc" ? BarsArrowUpIcon : BarsArrowDownIcon;
    const paymentsHoverIcon = currentPaymentsSort[0][1] === "asc" ? BarsArrowUpIcon : BarsArrowDownIcon;

    const whichDispatchSortDir = isIndividualPaymentsTable
        ? () => togglePaymentsSortDir(currentPaymentsSort[0][1] === "asc" ? "desc" : "asc")
        : () => toggleBatchesSortDir(currentBatchesSort[0][1] === "asc" ? "desc" : "asc");
    const whichDispatchSort = isIndividualPaymentsTable
        ? () => togglePaymentsSort(sortValue as PaymentsSortAttribute)
        : () => toggleBatchesSort(sortValue as BatchesSortAttribute);

    const sortEquality = isIndividualPaymentsTable
        ? currentPaymentsSort[0][0] === sortValue
        : currentBatchesSort[0][0] === sortValue;

    return (
        <th
            className={classNames(
                `sticky print:static whitespace-nowrap top-0 border-b border-gray-300 bg-gray-50 bg-opacity-75 backdrop-blur backdrop-filter py-3 text-${textAlign} text-sm font-semibold text-gray-800`,
                className,
            )}
            scope="col"
        >
            <div
                onClick={
                    sortEquality
                        ? () => dispatch(whichDispatchSortDir())
                        : () => dispatch(whichDispatchSort()) // TODO - fix this
                }
                className="group cursor-pointer inline-flex"
            >
                {t(`activityTable/columnHeaders/${sortValue}`)}
                <span
                    className={
                        sortEquality
                            ? "ml-1 flex-none rounded text-gray-900 group-hover:bg-gray-300"
                            : "invisible ml-1 flex-none rounded text-gray-400 group-hover:visible group-focus:visible"
                    }
                >
                    <div className="h-5 w-5" aria-hidden="true">
                        {sortEquality ? (
                            <CurrentSortIcon
                                dir={
                                    isIndividualPaymentsTable
                                        ? currentPaymentsSort[0][1]
                                        : currentBatchesSort[0][1]
                                }
                            />
                        ) : (
                            <>{isIndividualPaymentsTable ? paymentsHoverIcon : batchesHoverIcon}</>
                        )}
                    </div>
                </span>
            </div>
        </th>
    );
};

export default ColumnHeader;
