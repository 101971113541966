import { ReadonlyRecord } from "fp-ts/ReadonlyRecord";
import { Reducer } from "redux";
// import { createAction } from "../utils/createAction";
export interface SupplierCompanyRecord {
    id: string;
    isActive: boolean;
    bankAccount: {
        nameOnAccount: string;
        nameOfBank: string;
        accountNumber: string;
        routingNumber: string;
    };
    address: {
        companyName: string;
        country: string;
        streetAddress: string;
        city: string;
        state: string;
        zip: string;
    };
    contactPerson: {
        firstName: string;
        lastName: string;
        emailAddress: string;
    };
}

type SupplierCompanyDataById = ReadonlyRecord<string, SupplierCompanyRecord>;

export type SupplierCompanyData = SupplierCompanyRecord[] | [];

export interface SupplierCompanyDataState {
    entities: { allIds: string[]; byId: SupplierCompanyDataById };
    status: "UNINITIALIZED" | "INITIALIZED" | "FETCHED_API" | "FORM_SUBMIT"; // maybe this slice never gets submitted to by the frontend directly, instead always fetching its content?
}

export const initialSupplierCompanyState: SupplierCompanyDataState = {
    entities: { byId: {}, allIds: [] },
    status: "UNINITIALIZED",
};

export const supplierCompanyDataReducer: Reducer<SupplierCompanyDataState> = (
    state = initialSupplierCompanyState,
    _action,
) => {
    return state;
};
