import { ClockIcon, CloudArrowDownIcon, ChartBarSquareIcon } from "@heroicons/react/24/outline";
import { useAppSelector } from "../hooks";
import { selectAllBatches } from "../store/batches";
import { selectStatistics } from "../store/statistics";
import { formatFromCents } from "../utils";
import {
    downloadLifetimeReceivedReport,
    downloadLifetimeReport,
    tempDownloadPaymentsPending,
    tempDownloadReceivablesPending,
} from "../utils/reporting";
import { useI18n } from "@hi18n/react";
import { book } from "../locale";
import { BatchStatus, Role as UserRole } from "@corechain-technologies/types";
import { TempVendorFields, getPaymentsByVendorData } from "../utils/formatChartData";

interface InsightCardsProps {
    userRole: UserRole;
}

const InsightCards: React.FC<InsightCardsProps> = ({ userRole }) => {
    const { t } = useI18n(book);
    const stats = useAppSelector(selectStatistics);
    const batchListings = useAppSelector(selectAllBatches);
    let totalPaymentsCount = 0;
    if (batchListings.length > 0) {
        totalPaymentsCount = batchListings
            .map((batchListing) => {
                return batchListing.original.payments.length;
            })
            .reduce((acc, cur) => acc + cur);
    }
    const pendingPayments = useAppSelector(selectAllBatches)
        .filter((b) => ![BatchStatus.SETTLED, BatchStatus.REJECTED, BatchStatus.ERROR].includes(b.status))
        .map((b) => b.original.payments.length)
        .reduce((acc, cur) => acc + cur, 0);

    // console.log("pendingPayments", pendingPayments);
    // mock specific supplier selection - todo: change this to environment override of the user's own company
    // let payeeZeroPendingPayments = 0;
    // let payeeZeroPayments: TempVendorFields[] = [];
    // let payeeZeroVolumeReceived = { amountPaid: 0 };
    // if (batchListings.length > 0) {
    //     payeeZeroPayments = getPaymentsByVendorData(batchListings).filter(
    //         (payment) => payment.companyId === "ALMA",
    //     );
    //     payeeZeroVolumeReceived = payeeZeroPayments.reduce(
    //         (acc, cur) => {
    //             acc.amountPaid += cur.amountPaid;
    //             return acc;
    //         },
    //         { amountPaid: 0 },
    //     );
    //     payeeZeroPendingPayments = payeeZeroPayments
    //         .map((b) => b.status)
    //         .filter((b) => !["settled", "rejected"].includes(b.toLowerCase())).length;
    // }
    const insightCards = [
        {
            role: UserRole.BUYER,
            name: t("insightCards/lifetimeVolumePaid"),
            href: "#",
            icon: ChartBarSquareIcon,
            amount: batchListings.length > 0 ? formatFromCents(stats.totalCentsAmount) : 0,
            report: downloadLifetimeReport,
        },
        {
            role: UserRole.BUYER,
            name: t("insightCards/lifetimeTotalPaid"),
            href: "#",
            icon: ChartBarSquareIcon,
            amount: totalPaymentsCount.toLocaleString(),
            report: downloadLifetimeReport,
        },
        {
            role: UserRole.BUYER,
            name: t("insightCards/notSettled"),
            href: "#",
            icon: ClockIcon,
            amount: pendingPayments.toLocaleString(),
            report: tempDownloadPaymentsPending,
        },
        // {
        //     role: UserRole.SUPPLIER,
        //     name: t("insightCards/lifetimeVolumeReceived"),
        //     href: "#",
        //     icon: ChartBarSquareIcon,
        //     amount: batchListings.length > 0 ? formatFromCents(payeeZeroVolumeReceived.amountPaid) : 0,
        //     report: downloadLifetimeReceivedReport,
        // },
        // {
        //     role: UserRole.SUPPLIER,
        //     name: t("insightCards/lifetimeTotalReceived"),
        //     href: "#",
        //     icon: ChartBarSquareIcon,
        //     amount: payeeZeroPayments.length.toLocaleString(),
        //     report: downloadLifetimeReceivedReport,
        // },
        // {
        //     role: UserRole.SUPPLIER,
        //     name: t("insightCards/notReceived"),
        //     href: "#",
        //     icon: ClockIcon,
        //     amount: payeeZeroPendingPayments.toLocaleString(),
        //     report: tempDownloadReceivablesPending,
        // },
        // More items...
    ].filter((item) => item.role === userRole);

    return (
        <div>
            <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
                <div className="mt-5 grid grid-cols-1 gap-5 divide-y divide-gray-200 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-2 xl:grid-cols-3 sm:divide-y-0 sm:divide-x">
                    {/* Card */}
                    {insightCards.map((card, i) => (
                        <div key={card.name + i} className="bg-white overflow-hidden rounded-lg shadow card">
                            <div className=" px-4 sm:px-6 lg:px-8 pt-5 pb-4">
                                <div className="flex items-center">
                                    <div className="flex flex-shrink-0 justify-left">
                                        <card.icon className="h-6 text-gray-500" aria-hidden="true" />
                                    </div>
                                    <div className="ml-5 w-0 flex-1">
                                        <dl>
                                            <dt className="text-sm font-medium text-gray-500 truncate">
                                                {card.name}
                                            </dt>
                                            <dd>
                                                <div className="text-lg font-medium text-gray-800">
                                                    {card.amount}
                                                </div>
                                            </dd>
                                        </dl>
                                    </div>
                                </div>
                            </div>
                            <div className="mx-auto px-4 sm:px-6 lg:px-8 py-3 bg-gray-50">
                                <div className="text-sm">
                                    <a
                                        href={card.href}
                                        className="font-medium text-gray-600 hover:text-gray-800 px-2 py-2 rounded-md"
                                        onClick={() => card.report(batchListings, stats)}
                                    >
                                        <CloudArrowDownIcon
                                            className="inline-block mr-3 h-5 w-5"
                                            aria-hidden="true"
                                        />
                                        <span>{t("reports/downloadReport")}</span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default InsightCards;
