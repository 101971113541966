import { FileWithPath } from "react-dropzone";
import { AnyAction } from "redux";
import { AppThunk, RootState } from ".";
import { setNotificationWithoutTimeout, setNotificationWithTimeout } from "./notifications";
import {
    fetchFilteredBatches,
    makeFetchBatches,
    makeFetchFilteredBatches,
    switchFileUploadError,
} from "./batches";
import { getTranslator } from "@hi18n/core";
import { book } from "../locale";
import * as Sentry from "@sentry/react";
import { trpc } from "../utils/trpc";
import { switchDuplicate } from "./listings";
import { getVocabularyName } from "../utils";

const locale = getVocabularyName() || "error";

export type InBrowserUploadedFileDescriptorWithPath = {
    key: string; // specific to our use-case. see getFileKey() for implementation
    name: string;
    size: number;
    type: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    objectURL: string;
};

/**
 * Attempts to create a unique key for each file. In large-scale use (hundreds/thousands of uploads)
 * there is potential for collisions. However in practice for the forseeable use-cases of CXPay, this
 * should be sufficient. Keys are for use client-side only, so feel free to update the logic here as
 * needed. Note that objectURLs are *not* sufficient for uniqueness.
 */
const getFileKey = (file: any) => `${file.name}_${file.size}_${file.lastModified}`;

export interface FileUploaderState {
    [namespace: string]: NamespacedFileUploaderState;
}
export interface NamespacedFileUploaderState {
    status: "PENDING" | "UPLOADING" | "SUCCESS" | "FAILURE";
    file: InBrowserUploadedFileDescriptorWithPath | null;
    message: string | null;
}

export const initialFileUploadState: FileUploaderState = {};

/**
 * REDUCERS
 */

export const fileUploadReducer = (state = initialFileUploadState, action: AnyAction): FileUploaderState => {
    switch (action.type) {
        case "FILE_UPLOADER:STAGE_FILE":
            return {
                ...state,
                [action.payload.namespace]: { file: action.payload.file, status: "PENDING" },
            };
        case "FILE_UPLOADER:REMOVE_NAMESPACE":
        case "FILE_UPLOADER:UNSTAGE_FILE":
            const newState = { ...state };
            delete newState[action.payload];
            return newState;

        case "FILE_UPLOADER:UPLOAD_STARTED":
            return {
                ...state,
                [action.payload]: { ...state[action.payload], status: "UPLOADING" },
            };
        case "FILE_UPLOADER:UPLOAD_SUCCESS":
            return {
                ...state,
                [action.payload]: { ...state[action.payload], status: "SUCCESS" },
            };
        case "FILE_UPLOADER:UPLOAD_FAILURE":
            return {
                ...state,
                [action.payload.namespace]: {
                    ...state[action.payload],
                    message: action.payload.message,
                    status: "FAILURE",
                },
            };
        case "FILE_UPLOADER:UPLOAD_FAILURE_WITH_RESET":
            return {
                ...state,
                [action.payload.namespace]: {
                    file: null,
                    message: action.payload.message,
                    status: "FAILURE",
                },
            };
        default:
            return state;
    }
};

/**
 * EVENTS
 */
export const stageFile =
    (files: FileWithPath[], namespace: string): AppThunk =>
    (dispatch, getState) => {
        const namespaceExists = getState().files.hasOwnProperty(namespace);
        if (namespaceExists && getState().files[namespace].file) {
            return;
        }
        const file = files[0];
        const fileRepresentation: InBrowserUploadedFileDescriptorWithPath = {
            key: getFileKey(file),
            // eslint-disable-next-line @typescript-eslint/naming-convention
            objectURL: URL.createObjectURL(file),
            name: file.name,
            size: file.size,
            type: file.type,
        };

        return dispatch({
            type: "FILE_UPLOADER:STAGE_FILE",
            payload: {
                file: fileRepresentation,
                namespace: namespace,
            },
        });
    };

export const unstageFile = (namespace: string) => ({
    type: "FILE_UPLOADER:UNSTAGE_FILE",
    payload: namespace,
});

const startUpload = (namespace: string) => ({ type: "FILE_UPLOADER:UPLOAD_STARTED", payload: namespace });
const uploadSuccess = (namespace: string) => ({
    type: "FILE_UPLOADER:UPLOAD_SUCCESS",
    payload: namespace,
});

export const removeNamespace = (namespace: string) => ({
    type: "FILE_UPLOADER:REMOVE_NAMESPACE",
    payload: namespace,
});

export const uploadFailure = (namespace: string, message: string) => ({
    type: "FILE_UPLOADER:UPLOAD_FAILURE",
    payload: {
        namespace,
        message,
    },
});

export const uploadFailureWithReset = (namespace: string, message: string) => ({
    type: "FILE_UPLOADER:UPLOAD_FAILURE_WITH_RESET",
    payload: {
        namespace,
        message,
    },
});

const prepareFileForUpload = async (
    metadata: InBrowserUploadedFileDescriptorWithPath,
    fileBlob: Blob,
): Promise<{
    data: string | ArrayBuffer;
    name: string;
    size: number;
    type: string;
    key: string;
}> => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.addEventListener("error", function () {
            reject("Failed to read file contents");
        });
        reader.addEventListener("load", function () {
            if (!this.result) {
                reject("Failed to read file contents");
                return;
            } else {
                const filePayload = {
                    data: this.result,
                    name: metadata.name,
                    size: metadata.size,
                    type: metadata.type,
                    key: metadata.key,
                };
                resolve(filePayload);
            }
        });
        reader.readAsDataURL(fileBlob);
    });
};

export const uploadFile =
    (endpoint: string, namespace: string, n: number = 0): AppThunk =>
    async (dispatch, getState) => {
        const { t } = getTranslator(book, locale);
        const {
            files: { [namespace]: state },
            payer,
            user,
        } = getState();

        if (!state || !payer || state.status === "UPLOADING" || !state.file) {
            return;
        }
        dispatch(startUpload(namespace));

        const r = await fetch(state.file.objectURL);
        const b = await r.blob();
        const filePayload = await prepareFileForUpload(state.file, b);

        let result: Awaited<ReturnType<typeof trpc.batches.create.mutate>> | null = null;
        try {
            result = await trpc.batches.create.mutate({ ...filePayload, payerId: payer.id });
        } catch (err) {
            console.error(err);
            dispatch(uploadFailure(namespace, t("app/notifications/uploadError", { namespace })));
            dispatch(switchFileUploadError(true));
            dispatch(
                setNotificationWithTimeout({
                    message: t("app/notifications/errorDefault"),
                    notificationType: "ERROR",
                }),
            );
            Sentry.captureException(err);
        }

        if (result) {
            const timeout = 1000 * 1.3 ** n; // Thanks, Wolfram Alpha! 🤓
            if (result.ok) {
                dispatch(uploadSuccess(namespace));
                dispatch(makeFetchBatches(payer.id, result.val.batchId));
                dispatch(makeFetchFilteredBatches(payer.id, result.val.batchId));
            } else {
                if (result.val.code === "UNAUTHORIZED") {
                    if (n > 10) {
                        dispatch(
                            setNotificationWithoutTimeout({
                                message: t("app/notifications/uploadError", { namespace }),
                                notificationType: "ERROR",
                            }),
                        );
                        Sentry.captureMessage(`Error uploading file`);
                        return;
                    } else {
                        setTimeout(() => {
                            dispatch(uploadFile(endpoint, namespace, n + 1));
                        }, timeout);
                    }
                } else if (result.val.code === "CONFLICT") {
                    dispatch(
                        uploadFailureWithReset(namespace, t("app/notifications/uploadError", { namespace })),
                    );
                    dispatch(switchDuplicate(true));
                    dispatch(
                        setNotificationWithTimeout({
                            message: t("app/notifications/duplicateFile"),
                            notificationType: "ERROR",
                        }),
                    );
                    Sentry.captureMessage(`Duplicate file upload attempted`);
                } else {
                    dispatch(uploadFailureWithReset(namespace, result.val.message));
                    dispatch(
                        setNotificationWithTimeout({
                            message: result.val.message,
                            notificationType: "ERROR",
                        }),
                    );
                    Sentry.captureMessage(`File upload error`);
                }
            }
        }
    };

export const selectFileByNamespace = (namespace: string) => {
    return (state: Pick<RootState, "files">): InBrowserUploadedFileDescriptorWithPath | null => {
        if (state.files[namespace]) {
            return state.files[namespace].file;
        }
        return null;
    };
};

export const selectStatusByNamespace = (namespace: string) => {
    return (state: Pick<RootState, "files">): NamespacedFileUploaderState["status"] | null => {
        if (state.files[namespace]) {
            return state.files[namespace].status;
        }
        return null;
    };
};

export const selectErrorMessageByNamespace = (namespace: string) => {
    return (state: Pick<RootState, "files">): NamespacedFileUploaderState["message"] | null => {
        if (state.files[namespace]) {
            return state.files[namespace].message;
        }
        return null;
    };
};
