import { CreditCardIcon, CurrencyDollarIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { selectSortedListings } from "../store/listings";
import { formatFromCents, tempFormatUuidString } from "../utils";
import { classNames } from "../utils/classNames";
import { useI18n } from "@hi18n/react";
import { book } from "../locale";
import { useAppSelector } from "../hooks";
import { useLocation, useNavigate } from "react-router-dom";
import { getStatusCssClassnames } from "../utils/statusStyles";
import { LifeCycleFeed } from "./LifeCycleFeed";
import { dismissDetailPane, showDetailPane } from "./DetailPane";
import { NachaPaymentInstructionMessage } from "@corechain-technologies/types";
import { BatchListing } from "../store/listings";

const BatchDetail: React.FC = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { t } = useI18n(book);

    const batchListings = useAppSelector(selectSortedListings).filter(
        (listing): listing is BatchListing => listing.type === "BATCH",
    );
    console.log(
        location.hash,
        batchListings.map((listing) => encodeURI(listing.id)),
    );

    const selectedBatchListing = batchListings.filter((listing) => {
        if (!location.hash.includes(encodeURI(listing.id))) {
            console.log(location.hash, listing, encodeURI(listing.id));
        }
        return location.hash.includes(encodeURI(listing.id));
    })[0];

    if (!selectedBatchListing) {
        return <></>;
    }
    const batchId = selectedBatchListing.uploadedFileName;

    const batch = selectedBatchListing.original;
    const currentStatus = selectedBatchListing?.status;
    const paymentsArray = batch.payments.filter(
        (payment): payment is NachaPaymentInstructionMessage => "originName" in payment,
    );
    const originName = paymentsArray[0].originName;

    return (
        <>
            <div className="px-4 sm:px-6">
                <div className="flex items-start justify-between">
                    <div className="text-lg font-medium text-gray-900 truncate">
                        {batchId}
                        <div className="flex items-center justify-left gap-2 pt-2">
                            <div
                                className={classNames(
                                    getStatusCssClassnames(currentStatus),
                                    "inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium capitalize",
                                )}
                            >
                                <span className="capitalize">{currentStatus.toLowerCase()}</span>
                            </div>
                        </div>
                    </div>

                    <div className="ml-3 flex h-7 items-center">
                        <button
                            className="rounded-md bg-white text-gray-400 hover:text-gray-500"
                            onClick={() => {
                                navigate(dismissDetailPane());
                            }}
                        >
                            <span className="sr-only">Close panel</span>
                            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                        </button>
                    </div>
                </div>
            </div>
            <div className="relative bg-gray-200 mt-4 flex-1 px-4 sm:px-6 overflow-scroll">
                <div className="absolute inset-0 pt-5 pb-6 px-4 sm:px-6">
                    <div
                        className="h-full border overflow-y-scroll bg-white shadow border-gray-200 sm:rounded-lg"
                        aria-hidden="true"
                    >
                        <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
                            <dl className="sm:divide-y sm:divide-gray-200">
                                <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                                    <dt className="text-sm font-medium text-gray-500">
                                        {t("batchDetail/originatingAccount")}{" "}
                                    </dt>
                                    <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                        {originName}
                                    </dd>
                                </div>
                                <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                                    <dt className="text-sm font-medium text-gray-500">
                                        {t("batchDetail/totalAmount")}
                                    </dt>
                                    <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                        {formatFromCents(selectedBatchListing?.amount.toString() ?? "")}
                                    </dd>
                                </div>
                                <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                                    <dt className="text-sm font-medium text-gray-500">
                                        {t("batchDetail/numberPayments")}{" "}
                                    </dt>
                                    <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                        {batch.numberOfPayments.toString()}
                                    </dd>
                                </div>
                                <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                                    <dt className="text-sm font-medium text-gray-500">
                                        {t("paymentDetail/originalBatchId")}{" "}
                                    </dt>
                                    <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                        {tempFormatUuidString(batch.batchId)}
                                    </dd>
                                </div>
                                <LifeCycleFeed events={batch ? batch.events : []} />
                                <div className="py-4 sm:py-5 sm:px-6">
                                    <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                        <ul className="divide-y divide-gray-200 rounded-md border border-gray-200">
                                            {paymentsArray.map((payment, i) => {
                                                return (
                                                    <li
                                                        key={
                                                            i +
                                                            payment.amount +
                                                            payment.sourceLineNumber +
                                                            payment.recipientName
                                                        }
                                                        className="group flex items-center justify-between py-3 pl-3 pr-4 text-sm bg-white hover:bg-gray-50 cursor-pointer"
                                                        onClick={() => {
                                                            navigate(
                                                                showDetailPane(
                                                                    "#detail/payment/",
                                                                    payment.id,
                                                                ),
                                                            );
                                                        }}
                                                    >
                                                        <div className="flex w-0 flex-1 items-center">
                                                            <span className="w-0 flex-1 capitalize truncate">
                                                                {payment.recipientName.toLowerCase()}
                                                            </span>
                                                        </div>
                                                        <div className="flex w-0 flex-1 items-end">
                                                            <span className="ml-2 w-0 flex-1 truncate">
                                                                {formatFromCents(payment.amount)}
                                                            </span>
                                                        </div>
                                                        <div className="flex justify-left gap-1">
                                                            <div className="flex justify-center">
                                                                {payment.metadata.paymentMethod === "ACH" && (
                                                                    <CurrencyDollarIcon
                                                                        className="flex-shrink-0 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                                                                        aria-hidden="true"
                                                                    />
                                                                )}
                                                                {payment.metadata.paymentMethod ===
                                                                    "Card" && (
                                                                    <CreditCardIcon
                                                                        className="flex-shrink-0 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                                                                        aria-hidden="true"
                                                                    />
                                                                )}
                                                            </div>
                                                            <div className="ml-1 flex-shrink-0">
                                                                {payment.metadata.paymentMethod}
                                                            </div>
                                                        </div>
                                                    </li>
                                                );
                                            })}
                                        </ul>
                                    </dd>
                                </div>
                            </dl>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default BatchDetail;
