import { PaymentWithDate, selectAllPayments } from "../store/listings";
import { BatchStatusName, calculatePaymentId } from "@corechain-technologies/types";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppSelector } from "../hooks";
import { PaymentDetail, PaymentDetailHeader } from "./IndividualPaymentDetail";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { dismissDetailPane, showDetailPane } from "./DetailPane";

const PaymentDetailOverlay: React.FC = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const payments: PaymentWithDate[] = useAppSelector(selectAllPayments);

    const payment = payments.filter((payment) =>
        location.hash.includes(encodeURI(calculatePaymentId(payment))),
    )[0];

    if (!payment) {
        return <></>;
    }

    const currentStatus = payment ? payment.currentStatus : BatchStatusName.ERROR;

    return (
        <>
            <div className="px-4 sm:px-6">
                <div className="flex items-start justify-between">
                    <div onClick={() => navigate(showDetailPane("#detail/vendor/", payment.recipientName))}>
                        <PaymentDetailHeader payment={payment} currentStatus={currentStatus} />
                    </div>
                    <div className="ml-3 flex h-7 items-center">
                        <button
                            className="rounded-md bg-white text-gray-400 hover:text-gray-500"
                            onClick={() => {
                                navigate(dismissDetailPane());
                            }}
                        >
                            <span className="sr-only">Close panel</span>
                            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                        </button>
                    </div>
                </div>
            </div>
            <div className="relative bg-gray-200 mt-4 flex-1 px-4 sm:px-6 overflow-scroll">
                <div className="absolute inset-0 pt-5 pb-6 px-4 sm:px-6">
                    <div
                        className="h-full border overflow-y-scroll bg-white shadow border-gray-200 sm:rounded-lg"
                        aria-hidden="true"
                    >
                        <PaymentDetail payment={payment} />
                    </div>
                </div>
            </div>
        </>
    );
};

export default PaymentDetailOverlay;
