import { CloudArrowUpIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useDropzone } from "react-dropzone";
import { useAppDispatch, useAppSelector } from "../hooks";
import {
    removeNamespace,
    selectFileByNamespace,
    selectStatusByNamespace,
    stageFile,
    unstageFile,
    uploadFile,
} from "../store/uploads";
import { useI18n } from "@hi18n/react";
import { book } from "../locale";

const FileUploaderText = ({ path }: { path: string }) => {
    const { t } = useI18n(book);

    const file = useSelector(selectFileByNamespace(path));
    const status = useAppSelector(selectStatusByNamespace(path));
    let text;
    if (path.includes("batches")) {
        text = t("uploadBox/payment");
    }
    if (path.includes("vendor")) {
        text = t("uploadBox/vendor");
    }
    if (status === "UPLOADING") {
        text = "Processing...";
    }
    if (file) {
        text = "Review staged file";
    }

    return <div className="text-gray-600 text-center">{text}</div>;
};

const UploadBox = ({ path }: { path: string }) => {
    const file = useSelector(selectFileByNamespace(path));
    const status = useAppSelector(selectStatusByNamespace(path));
    const dispatch = useAppDispatch();
    const onDrop = (acceptedFiles: any) => {
        if (file) {
            return;
        }
        dispatch(stageFile(acceptedFiles, path));
    };
    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        maxFiles: 1,
        multiple: false,
        disabled: !!file,
    });

    useEffect(() => {
        if (status === "SUCCESS") {
            dispatch(removeNamespace(path));
        }
        if (status === "FAILURE") {
            dispatch(removeNamespace(path));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [status]);

    function handleUpload() {
        dispatch(uploadFile(path, path));
    }

    return (
        <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8 pt-5 print:hidden">
            <div className="overflow-hidden rounded-lg bg-white px-4 py-4 shadow sm:px-6 sm:py-6">
                <input {...getInputProps()} data-testid="cxFileInput" />
                <div
                    {...getRootProps()}
                    className={status === "UPLOADING" ? "animate-pulse p-1 rounded-md" : "p-1 rounded-md"}
                >
                    <div className="w-full m- h-40 rounded-md border-2 border-gray-300 border-dashed">
                        <CloudArrowUpIcon className="w-16 mx-auto mt-12 text-gray-500 h-12" />
                        <FileUploaderText path={path} />
                    </div>
                    {!!file && (
                        <>
                            <ul className="py-5">
                                <li className="flex justify-between items-center" key={file.key}>
                                    <div className="p-2 rounded-lg text-gray-600 hover:text-gray-800 hover:bg-gray-50">
                                        {file.name}
                                    </div>
                                    <div className="text-gray-500">{file.size} bytes</div>
                                    <button
                                        type="button"
                                        className="inline-flex gap-1 items-center justify-center rounded-md border border-transparent bg-red-100 px-3 py-1 font-medium text-red-700 hover:bg-red-200 sm:text-sm"
                                        onClick={() => dispatch(unstageFile(path))}
                                    >
                                        <XMarkIcon className="h-4" />
                                        remove
                                    </button>
                                </li>
                            </ul>
                            <div
                                className="flex flex-shrink items-center justify-left"
                                onClick={() => handleUpload()}
                            >
                                <button
                                    type="button"
                                    className="inline-flex gap-2 items-center justify-center rounded-md border border-transparent bg-green-600 px-4 py-2 font-medium text-white hover:bg-green-700 sm:text-sm"
                                >
                                    <CloudArrowUpIcon className="h-6" />
                                    Upload File
                                </button>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

export default UploadBox;
