import { Outlet, useMatch } from "react-router-dom";
import ReceivablesDesktop, { ReceivablesMobile } from "../components/ReceivablesTable";
import { useAppDispatch } from "../hooks";
import { useEffect } from "react";
import { toggleSort, toggleSortDir } from "../store/listings";

const Receivables: React.FC = () => {
    const dispatch = useAppDispatch();
    const isReceivablesTable = useMatch(":payer/app/receivables");
    useEffect(() => {
        if (isReceivablesTable) {
            dispatch(toggleSort("statusDate"));
            dispatch(toggleSortDir("desc"));
        }
    }, [isReceivablesTable]);
    return (
        <>
            <Outlet />
            <ReceivablesMobile />
            <ReceivablesDesktop />
        </>
    );
};

export default Receivables;
