import SearchResultsTable from "../components/SearchResultsTable";

const SearchResults: React.FC = () => {
    return (
        <>
            <SearchResultsTable />
        </>
    );
};

export default SearchResults;
