import { CloudArrowDownIcon } from "@heroicons/react/24/outline";
import { useAppSelector } from "../hooks";
import { selectAllBatches } from "../store/batches";
import { selectStatistics } from "../store/statistics";
import { formatFromCents } from "../utils";
import {
    // formatPayeeVolumeByMethod,
    // formatTopPayeePayersByQuantity,
    // formatTopPayeePayersByVolume,
    formatTotalPaymentsToVendors,
    formatVendorPaymentVolumeData,
    formatVolumeByMethod,
} from "../utils/formatChartData";
import {
    downloadLifetimeReport,
    // downloadPayeeLifetimeReport,
    // downloadPayeePaymentCount,
    // downloadPayeePaymentVolume,
    tempDownloadVendorPaymentCount,
    tempDownloadVendorPaymentVolume,
} from "../utils/reporting";
import { useI18n } from "@hi18n/react";
import { book } from "../locale";
import { Role as UserRole } from "@corechain-technologies/types";
import { selectPayments } from "../store/payments";

interface ComparisonCardsProps {
    userRole: UserRole;
}

const ComparisonCards: React.FC<ComparisonCardsProps> = ({ userRole }) => {
    const { t } = useI18n(book);
    const stats = useAppSelector(selectStatistics);
    const batchListings = useAppSelector(selectAllBatches);
    const showPaymentMethod = false; //TODO: shim!!!
    const paymentVolumeByMethod = formatVolumeByMethod(useAppSelector(selectPayments), showPaymentMethod);
    const totalPaymentsToVendors = formatTotalPaymentsToVendors(
        useAppSelector(selectPayments),
        showPaymentMethod,
    );
    const vendorPaymentVolumeData = formatVendorPaymentVolumeData(
        useAppSelector(selectPayments),
        showPaymentMethod,
    );
    // mock specific supplier selection - todo: change this to environment override of the user's own company
    // const payeeZeroPayments: PaymentWithDate[] = useAppSelector(selectAllPayments).filter(
    //     (payment) => payment.recipientName === "ALMA",
    // );
    // const payeeVolumeByMethod = formatPayeeVolumeByMethod(payeeZeroPayments);
    // const topPayeePayersByVolume = formatTopPayeePayersByVolume(payeeZeroPayments);
    // const topPayeePayersByQuantity = formatTopPayeePayersByQuantity(payeeZeroPayments);

    const comparisonCards = [
        {
            role: UserRole.BUYER,
            href: "#",
            name: t("comparisonCards/vendorsByVolume"),
            data: vendorPaymentVolumeData
                .slice(0, 3)
                .map((d) => ({ label: d.name, value: formatFromCents(d.total) })),
            report: tempDownloadVendorPaymentVolume,
        },
        {
            role: UserRole.BUYER,
            href: "#",
            name: t("comparisonCards/vendorsByPayments"),
            data: totalPaymentsToVendors
                .slice(0, 3)
                .map((d) => ({ label: d.name, value: d.count.toLocaleString() })),
            report: tempDownloadVendorPaymentCount,
        },
        {
            role: UserRole.BUYER,
            href: "#",
            name: t("comparisonCards/paymentByMethod"),
            data: paymentVolumeByMethod.map((d) => ({ label: d.method, value: formatFromCents(d.amount) })),
            report: downloadLifetimeReport,
        },
        // {
        //     role: UserRole.SUPPLIER,
        //     href: "#",
        //     name: t("comparisonCards/payersByVolume"),
        //     data: topPayeePayersByVolume
        //         .slice(0, 3)
        //         .map((d) => ({ label: d.payer, value: formatFromCents(d.amountPaid) })),
        //     report: downloadPayeePaymentVolume,
        // },
        // {
        //     role: UserRole.SUPPLIER,
        //     href: "#",
        //     name: t("comparisonCards/payersByPayments"),
        //     data: topPayeePayersByVolume.slice(0, 3).map((d) => ({ label: d.payer, value: d.qty })),
        //     report: downloadPayeePaymentCount,
        // },
        // {
        //     role: UserRole.SUPPLIER,
        //     href: "#",
        //     name: t("comparisonCards/volumeReceivedByMethod"),
        //     data: payeeVolumeByMethod.map((d) => ({ label: d.method, value: formatFromCents(d.amount) })),
        //     report: downloadPayeeLifetimeReport,
        // },
        // More items...
    ].filter((item) => item.role === userRole);

    return (
        <div className="mt-4">
            <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
                <div className="mt-5 grid grid-cols-1 gap-5 divide-y divide-gray-200 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-2 xl:grid-cols-3 sm:divide-y-0 sm:divide-x">
                    {/* Card */}
                    {comparisonCards.map((card, i) =>
                        card.data.length > 0 ? (
                            <div
                                key={card.name + i}
                                className="bg-white flex flex-col overflow-hidden rounded-lg shadow card"
                            >
                                <div className="px-4 sm:px-6 lg:px-8 pt-5 pb-4 flex-1">
                                    <div className="h-full items-top">
                                        <dl>
                                            <dt className="pb-3 text-sm font-medium text-gray-500 truncate">
                                                {card.name}
                                            </dt>
                                            <dd>
                                                <div>
                                                    {card.data.map((d, i) => (
                                                        <div className="pb-3" key={card.name + i}>
                                                            <div className="text-xs font-medium text-gray-500 pb-1">
                                                                {d.label}
                                                            </div>
                                                            <div className="text-sm font-medium text-gray-800">
                                                                {d.value}
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </dd>
                                        </dl>
                                    </div>
                                </div>
                                <div className=" px-4 sm:px-6 lg:px-8 py-3 bg-gray-50">
                                    <div className="text-sm">
                                        <a
                                            href={card.href}
                                            className="font-medium text-gray-600 hover:text-gray-800 px-2 py-2 rounded-md"
                                            onClick={() => card.report(batchListings, stats)}
                                        >
                                            <CloudArrowDownIcon
                                                className="inline-block mr-3 h-5 w-5"
                                                aria-hidden="true"
                                            />
                                            <span>{t("reports/downloadReport")}</span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            ""
                        ),
                    )}
                </div>
            </div>
        </div>
    );
};

export default ComparisonCards;
