import { CreditCardIcon, CurrencyDollarIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { formatFromCents } from "../utils";
import { classNames } from "../utils/classNames";
import { getStatusCssClassnames } from "../utils/statusStyles";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppSelector } from "../hooks";
import { selectSortedPayments } from "../store/listings";
import { payerStatusStyles, TempPayerData, TempPayerFields } from "./PayerList";
import { format } from "date-fns";
import { useI18n } from "@hi18n/react";
import { book } from "../locale";
import { dismissDetailPane, showDetailPane } from "./DetailPane";
import { calculatePaymentId } from "@corechain-technologies/types";

const PayerPaymentHistory: React.FC = () => {
    const { t } = useI18n(book);
    const navigate = useNavigate();
    const location = useLocation();
    const selectedVendorData = useAppSelector(selectSortedPayments);
    const selectedPayerData = selectedVendorData
        .filter((data) => location.hash.includes(encodeURI(data.originName)) && data.recipientName === "ALMA")
        .sort((a, b) => Number(b.createdDate) - Number(a.createdDate));
    if (!(selectedPayerData.length > 0)) {
        navigate("/404", { replace: true });
        return;
    }

    const payerHistoryData: TempPayerData = selectedPayerData.reduce((acc, cur) => {
        if (cur.payerId === "") {
            return acc;
        }
        if (acc[cur.payerId]) {
            acc[cur.payerId].payments.push(cur);
            acc[cur.payerId].totalPaymentsValue += Number(cur.amount);
            acc[cur.payerId].previousPaymentDate =
                Number(acc[cur.payerId].previousPaymentDate) > Number(cur.createdDate)
                    ? acc[cur.payerId].previousPaymentDate
                    : cur.createdDate;
            acc[cur.payerId].previousPaymentValue =
                Number(acc[cur.payerId].previousPaymentDate) > Number(cur.createdDate)
                    ? acc[cur.payerId].previousPaymentValue
                    : Number(cur.amount);
            acc[cur.payerId].initialPaymentDate =
                Number(acc[cur.payerId].initialPaymentDate) < Number(cur.createdDate)
                    ? acc[cur.payerId].initialPaymentDate
                    : cur.createdDate;
            acc[cur.payerId].payerStatus = "active";
            return acc;
        }
        acc[cur.payerId] = {
            payments: [cur],
            payerName: cur.originName,
            payerId: cur.payerId,
            totalPaymentsValue: Number(cur.amount),
            previousPaymentDate: cur.createdDate,
            previousPaymentValue: Number(cur.amount),
            initialPaymentDate: cur.createdDate,
            payerStatus: "active",
        };
        return acc;
    }, {} as TempPayerData);
    const payerHistory: TempPayerFields = Object.keys(payerHistoryData).map(
        (d: string) => payerHistoryData[d],
    )[0];

    return (
        <>
            <div className="px-4 sm:px-6">
                <div className="flex items-start justify-between">
                    <div className="text-lg font-medium text-gray-900 ">
                        {payerHistory.payerName}
                        <div className="flex items-center justify-left gap-2 pt-2">
                            <div
                                className={classNames(
                                    payerStatusStyles[
                                        payerHistory.payerStatus as keyof typeof payerStatusStyles
                                    ],
                                    "inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium capitalize",
                                )}
                            >
                                <span className="capitalize">{payerHistory.payerStatus}</span>
                            </div>
                        </div>
                    </div>

                    <div className="ml-3 flex h-7 items-center">
                        <button
                            type="button"
                            className="rounded-md bg-white text-gray-400 hover:text-gray-500"
                            onClick={() => navigate(dismissDetailPane())}
                        >
                            <span className="sr-only">Close panel</span>
                            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                        </button>
                    </div>
                </div>
            </div>
            <div className="relative bg-gray-200 mt-4 flex-1 px-4 sm:px-6 overflow-scroll">
                <div className="absolute inset-0 pt-5 pb-6 px-4 sm:px-6">
                    <div
                        className="h-full border overflow-y-scroll bg-white shadow border-gray-200 sm:rounded-lg"
                        aria-hidden="true"
                    >
                        <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
                            <dl className="sm:divide-y sm:divide-gray-200">
                                <div className="py-4 px-4 flex gap-1 sm:py-5 sm:px-6">
                                    <span className="text-sm text-gray-900">
                                        {payerHistory.payments.length.toLocaleString()}
                                    </span>
                                    <span className="text-sm font-medium text-gray-500">
                                        {payerHistory.payments.length === 1
                                            ? t("payerPaymentHistory/paymentWithTotalValue")
                                            : t("payerPaymentHistory/paymentsWithTotalValue")}
                                    </span>
                                    <span className="text-sm text-gray-900">
                                        {formatFromCents(payerHistory.totalPaymentsValue ?? "")}
                                    </span>
                                </div>
                                <div className="py-4 sm:py-5 sm:px-6">
                                    <dd className="mt-1 text-sm text-gray-700 sm:col-span-2 sm:mt-0">
                                        <ul className="divide-y divide-gray-200 rounded-md border border-gray-200">
                                            {payerHistory.payments.map((payment, i) => {
                                                const currentStatus = payment.currentStatus;
                                                return (
                                                    <li
                                                        key={i + payment.toString()}
                                                        className="group flex items-center justify-between py-3 pl-3 pr-4 text-sm bg-white hover:bg-gray-50 cursor-pointer"
                                                        onClick={() =>
                                                            navigate(
                                                                showDetailPane(
                                                                    "#detail/receivable/",
                                                                    calculatePaymentId(payment),
                                                                ),
                                                            )
                                                        }
                                                    >
                                                        <div className="flex w-0 flex-1">
                                                            <span className="w-0 flex-1 capitalize truncate group-hover:text-gray-900">
                                                                {format(payment.createdDate, "MM/dd/yyyy")}
                                                            </span>
                                                        </div>
                                                        <div className="flex w-0 flex-1 justify-end group-hover:text-gray-900">
                                                            <span className="w-0 flex-1 truncate text-right">
                                                                {formatFromCents(payment.amount)}
                                                            </span>
                                                        </div>
                                                        <div className="flex w-0 flex-1 justify-end group-hover:text-gray-900">
                                                            <div className="flex justify-left gap-1">
                                                                <div className="flex justify-center">
                                                                    {payment.metadata.paymentMethod ===
                                                                        "ACH" && (
                                                                        <CurrencyDollarIcon
                                                                            className="flex-shrink-0 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                                                                            aria-hidden="true"
                                                                        />
                                                                    )}
                                                                    {payment.metadata.paymentMethod ===
                                                                        "Card" && (
                                                                        <CreditCardIcon
                                                                            className="flex-shrink-0 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                                                                            aria-hidden="true"
                                                                        />
                                                                    )}
                                                                </div>
                                                                <div className="ml-1 flex-shrink-0">
                                                                    {payment.metadata.paymentMethod}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="flex w-0 flex-1 justify-end">
                                                            <span
                                                                className={classNames(
                                                                    getStatusCssClassnames(currentStatus),
                                                                    "inline-flex justify-center px-2.5 py-0.5 rounded-full text-xs font-medium capitalize",
                                                                )}
                                                            >
                                                                <span className="capitalize">
                                                                    {payment.currentStatus.toLowerCase()}
                                                                </span>
                                                            </span>
                                                        </div>
                                                    </li>
                                                );
                                            })}
                                        </ul>
                                    </dd>
                                </div>
                            </dl>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default PayerPaymentHistory;
