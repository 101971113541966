import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../hooks";
import {
    enterPaymentInfo,
    jumpToPaymentMethod,
    selectCurrentFormData,
    SupplierOnboardingRecord,
} from "../store/supplierOnboarding";
import { makeValidationMessageSentence } from "../utils";
import ValidationMessage from "./ValidationMessage";
import { motion } from "framer-motion";
import { z } from "zod";

const accountNumberExpression = /^[0-9]{1,17}$/;
const routingNumberExpression = /^\d+$/;

const accountNumberRegex = new RegExp(accountNumberExpression);
const routingNumberRegex = new RegExp(routingNumberExpression);

const PaymentInfoFormSchema = z.object({
    nameOnAccount: z.string().nonempty(),
    nameOfBank: z.string().nonempty(),
    accountType: z.enum(["personalChecking", "personalSavings", "businessChecking", "businessSavings"]),
    accountNumber: z.string().regex(accountNumberExpression),
    routingNumber: z.string().regex(routingNumberExpression),
});

type PaymentInfoFormFields = keyof SupplierOnboardingRecord["bankAccount"];

const PaymentInfoForm = () => {
    const dispatch = useAppDispatch();
    const currentState = useAppSelector(selectCurrentFormData).bankAccount;
    const [bankAccount, setBankAccount] = useState<SupplierOnboardingRecord["bankAccount"]>(currentState);
    const [validationMessage, setValidationMessage] = useState<PaymentInfoFormFields[]>([]);
    const [formMessage, setFormMessage] = useState<boolean>(false);

    useEffect(() => {
        if (validationMessage.length === 0) {
            setFormMessage(false);
        }
    }, [validationMessage]);

    const handleBankAccountChange = (
        event: React.FormEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>,
    ) => {
        if (event.currentTarget.name === "accountNumber") {
            if (!accountNumberRegex.test(event.currentTarget.value) && event.currentTarget.value !== "") {
                return;
            } else {
                setBankAccount({ ...bankAccount, [event.currentTarget.name]: event.currentTarget.value });
            }
        } else if (event.currentTarget.name === "routingNumber") {
            if (!routingNumberRegex.test(event.currentTarget.value) && event.currentTarget.value !== "") {
                return;
            } else {
                setBankAccount({ ...bankAccount, [event.currentTarget.name]: event.currentTarget.value });
            }
        } else {
            setBankAccount({ ...bankAccount, [event.currentTarget.name]: event.currentTarget.value });
        }
    };

    const handleSubmit = (e: any) => {
        e.preventDefault();

        if (validationMessage.length > 0) {
            setFormMessage(true);
        } else {
            dispatch(enterPaymentInfo(bankAccount));
        }
    };

    const handleBlur = (e: React.FocusEvent<HTMLInputElement> | React.FocusEvent<HTMLSelectElement>) => {
        e.preventDefault();
        const name = e.currentTarget.name as PaymentInfoFormFields;
        const value = e.currentTarget.value;

        if (PaymentInfoFormSchema.shape[name].safeParse(value).success) {
            setValidationMessage(validationMessage.filter((item) => item !== name));
        } else {
            setValidationMessage([...validationMessage, name]);
        }
    };

    const isThereMessage = (
        key: PaymentInfoFormFields, // do not export! bespoke to each form
    ) => (validationMessage.includes(key) ? makeValidationMessageSentence(key) : null);

    return (
        <motion.form initial={{ opacity: 0 }} animate={{ opacity: 1 }} className="space-y-6 lg:mt-10">
            {" "}
            <div className="bg-white px-4 py-5 shadow sm:rounded-lg sm:p-6">
                <div className="md:grid md:grid-cols-3 md:gap-6">
                    <div className="md:col-span-1">
                        <h3 className="text-lg font-medium leading-6 text-gray-900">Payment Info</h3>
                        {formMessage && (
                            <ValidationMessage
                                message="You must fill out all fields with appropriate values"
                                noPadding
                            />
                        )}
                    </div>
                    <div className="mt-5 md:col-span-2 md:mt-0">
                        <div className="grid grid-cols-6 gap-6">
                            <div className="col-span-6">
                                <label
                                    htmlFor="nameOnAccount"
                                    className="block text-sm font-medium text-gray-700"
                                >
                                    Name on account
                                </label>
                                <input
                                    type="text"
                                    name="nameOnAccount"
                                    id="nameOnAccount"
                                    value={bankAccount.nameOnAccount}
                                    onChange={handleBankAccountChange}
                                    onBlur={handleBlur}
                                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                />
                                <ValidationMessage message={isThereMessage("nameOnAccount")} />
                            </div>

                            <div className="col-span-6">
                                <label
                                    htmlFor="nameOfBank"
                                    className="block text-sm font-medium text-gray-700"
                                >
                                    Name of bank
                                </label>
                                <input
                                    type="text"
                                    name="nameOfBank"
                                    id="nameOfBank"
                                    onChange={handleBankAccountChange}
                                    onBlur={handleBlur}
                                    value={bankAccount.nameOfBank}
                                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                />
                                <ValidationMessage message={isThereMessage("nameOfBank")} />
                            </div>
                            <div className="col-span-6 lg:col-span-2">
                                <label
                                    htmlFor="accountType"
                                    className="block text-sm font-medium text-gray-700"
                                >
                                    Account Type
                                </label>
                                <select
                                    id="accountType"
                                    name="accountType"
                                    onChange={handleBankAccountChange}
                                    onBlur={handleBlur}
                                    value={bankAccount.accountType}
                                    className="mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                >
                                    <option value="noChoiceMade">Choose one...</option>
                                    <option value="businessChecking">Business checking</option>
                                    <option value="businessSavings">Business savings</option>
                                    <option value="personalChecking">Personal checking</option>
                                    <option value="personalBusiness">Personal business</option>
                                </select>
                                <ValidationMessage message={isThereMessage("accountType")} />
                            </div>
                            <div className="col-span-6 lg:col-span-2">
                                <label
                                    htmlFor="accountNumber"
                                    className="block text-sm font-medium text-gray-700"
                                >
                                    Account number
                                </label>
                                <input
                                    type="text"
                                    name="accountNumber"
                                    id="accountNumber"
                                    value={bankAccount.accountNumber}
                                    onChange={handleBankAccountChange}
                                    onBlur={handleBlur}
                                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                />
                                <ValidationMessage message={isThereMessage("accountNumber")} />
                            </div>

                            <div className="col-span-6 lg:col-span-2">
                                <label
                                    htmlFor="routingNumber"
                                    className="block text-sm font-medium text-gray-700"
                                >
                                    Routing number
                                </label>
                                <input
                                    type="text"
                                    name="routingNumber"
                                    id="routingNumber"
                                    value={bankAccount.routingNumber}
                                    onChange={handleBankAccountChange}
                                    onBlur={handleBlur}
                                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                />{" "}
                                <ValidationMessage message={isThereMessage("routingNumber")} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex justify-end">
                <button
                    type="button"
                    onClick={() => dispatch(jumpToPaymentMethod())}
                    className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                >
                    Go Back
                </button>
                <button
                    type="submit"
                    onClick={handleSubmit}
                    className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                >
                    Save
                </button>
            </div>
        </motion.form>
    );
};

export default PaymentInfoForm;
